// @ts-nocheck
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"
import {
    processAppSettingsLanguages,
    processStaticSettingsObject,
} from 'utils/settings'

const SHOW_COLUMNS_KEY = "admin-users-show-columns"

const getShowColumnsFromLocalStorage = () => {
    try {
        const json = localStorage.getItem(SHOW_COLUMNS_KEY)
        return JSON.parse(json) || [
            'is_company',
            'company_type',
            'first_name',
            'last_name',
            'company_name',
            'email',
            'phones',
            'addresses',
            'type',
            'languages',
            'customer_number'
        ]
    } catch (e) {
        return []
    }
}

const setShowColumnsFromLocalStorage = (columns) => {
    try {
        localStorage.setItem(SHOW_COLUMNS_KEY, JSON.stringify(columns))
    } catch (e) {}
}

const fetchUsers = async (setUsers, { sortBy, limit, page, q, userType, lang1, lang2, is_translator, is_interpreter, is_teacher, is_certified }, setIsLoading, authContext) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    setUsers(undefined)
    const user = await authGetRequest(`/admin/users?sortBy=${sortBy}&limit=${limit}&page=${page}&q=${q}&userType=${userType}&lang1=${lang1}&lang2=${lang2}&is_translator=${is_translator}&is_interpreter=${is_interpreter}&is_teacher=${is_teacher}&is_certified=${is_certified}`)
    setUsers(user.data)
    setIsLoading(false)
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function hasParentClass(child, classname){
    if(child.className.split(' ').indexOf(classname) >= 0) return true;
    try {
        return child.parentNode && hasParentClass(child.parentNode, classname);
    } catch(TypeError){
        return false;
    }
}

const getDefaultSearchCriteria = (query) => {
    return {
        q: query.get('q'),
    }
}

const availableColumns = {
    is_company: 'User Typ',
    company_type: 'Organisation-Typ',
    first_name: 'Vorname',
    last_name: 'Nachname',
    company_name: 'Name der Organisation',
    email: 'E-Mail',
    phones: 'Telefone',
    addresses: 'Addressen',
    type: 'Profil-Typ',
    languages: 'Sprachen',
    customer_number: 'Kundennummer',
}

const CustomerTranslatorsController = (props) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()

    const [users, setUsers] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [selectedDeleteDropdown, setSelectedDeleteDropdown] = useState(undefined)
    const [showColumnDropdown, setShowColumnDropdown] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState([])

    const languages = processAppSettingsLanguages(appSettings.appSettings.languages)
    const roles = processStaticSettingsObject(appSettings.staticSettings.roles)
    const types = processStaticSettingsObject(appSettings.staticSettings.types)

    const history = useHistory()
    const query = useQuery()

    const [searchCriteria, setSearchCriteria] = useState(getDefaultSearchCriteria(query))

    const sortBy = query.get('sortBy') || 'entry_date'
    const limit = query.get('limit') || 100
    const page = query.get('page') || 1
    const q = query.get('q') || ''
    const userType = query.has('userType') ? query.get('userType') : 'customer';
    const lang1 = query.get('lang1') || 'all';
    const lang2 = query.get('lang2') || 'all';
    const is_translator = query.get('is_translator') === '1' ? 1 : 0
    const is_interpreter = query.get('is_interpreter') === '1' ? 1 : 0
    const is_teacher = query.get('is_teacher') === '1' ? 1 : 0
    const is_certified = query.get('is_certified') === '1' ? 1 : 0

    const closeShowColumnDropdown = (e) => {
        if(!hasParentClass(e.target, 'show-column-dropdown') && !hasParentClass(e.target, 'show-column-dropdown-trigger')) {
            setShowColumnDropdown(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', closeShowColumnDropdown)
        setSelectedColumns(getShowColumnsFromLocalStorage())

        return () => {
            window.removeEventListener('click', closeShowColumnDropdown)
        }
    }, [])

    const handleUpdateSearch = (e) => {
        e.preventDefault()
        const {
            q,
        } = searchCriteria

        history.push(buildLink({
            q,
            userType: userType || 'customer',
            lang1,
            lang2,
            is_translator,
            is_interpreter,
            is_teacher,
            is_certified,
        }))
    }

    useEffect(() => {
        fetchUsers(setUsers, { sortBy, limit, page, q, userType, lang1, lang2, is_translator, is_interpreter, is_teacher, is_certified }, setIsLoading, authContext)
    }, [sortBy, limit, page, q, userType, lang1, lang2, is_translator, is_interpreter, is_teacher, is_certified])

    const buildLink = ({ 
        page: newPage, 
        limit: newLimit, 
        sortBy: newSortBy,
        q: newQ,
        userType: newUserType,
        lang1: newLang1,
        lang2: newLang2,
        is_translator: newIsTranslator,
        is_interpreter: newIsInterpreter,
        is_teacher: newIsTeacher,
        is_certified: newIsCertified
    }) => {
        return `/admin/users?page=${newPage || page}&limit=${newLimit || limit}&sortBy=${newSortBy || sortBy}` + 
                `&q=${newQ !== undefined ? newQ : q}&userType=${newUserType !== undefined ? newUserType : userType}&lang1=${newLang1 !== undefined ? newLang1 : lang1}&lang2=${newLang2 !== undefined ?  newLang2 : lang2}` +
                `&is_translator=${newIsTranslator !== undefined ? newIsTranslator : is_translator}` +
                `&is_interpreter=${newIsInterpreter !== undefined ? newIsInterpreter : is_interpreter}` +
                `&is_teacher=${newIsTeacher !== undefined ? newIsTeacher : is_teacher}` +
                `&is_certified=${newIsCertified !== undefined ? newIsCertified : is_certified}`;
    }

    const deleteUser = async (user) => {
        try {
            const res = await authContext.authDeleteRequest(`/admin/users/${user.id}`)
            switch(res.status) {
                case 204:
                    toast.success("Der Kontakt wurde erfolgreich gelöscht")
                    setUsers({
                        ...users,
                        results: users.results.filter(t => t.id !== user.id)
                    })
                    break
                default:
                    throw new Error(res.data)
            }
        }
        catch (e) {
            toast.error(e.message)
        }
    }

    const updateSelectedColumns = (newSelectedColumns) => {
        setSelectedColumns(newSelectedColumns)
        setShowColumnsFromLocalStorage(newSelectedColumns)
    }

    const impersonateUser = async (user) => {
        try {
            const res = await authContext.authPostRequest(`/admin/users/${user.id}/impersonate`)

            if(!res.data.code) {
                authContext.impersonate(res.data)
                history.push(`/`)
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                        break
                    case 401:
                    case 500:
                    default:
                        throw new Error("Beim Laden der Daten ist ein Fehler aufgetreten")
                        break
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }

    return {
        users,
        setUsers,
        isLoading,
        error,
        languages,
        buildLink,
        searchCriteria,
        setSearchCriteria,
        handleUpdateSearch,
        userType,
        lang1,
        lang2,
        selectedDeleteDropdown,
        setSelectedDeleteDropdown,
        deleteUser,
        setShowColumnDropdown,
        showColumnDropdown,
        selectedColumns,
        updateSelectedColumns,
        availableColumns,
        roles,
        types,
        is_translator,
        is_interpreter,
        is_teacher,
        is_certified,
        currentUser: authContext.currentUser,
        impersonateUser,
    }
}

export default CustomerTranslatorsController
