// @ts-nocheck
import { Link, useHistory } from "react-router-dom"
import TasksController from 'controllers/admin/Tasks.controller'
import TasksSkeleton from 'screens/skeletons/admin/Tasks.skeleton'

import { apiToUiDate, isWithinDays, isPast, isToday } from 'utils/datetime'

const Tasks = (props) => {
    
    const {
        tasks,
        setTasks,
        isLoading,
        error,
        selectedDeleteDropdown,
        setSelectedDeleteDropdown,
        deleteTask,
        buildLink,
        setShowColumnDropdown,
        showColumnDropdown,
        selectedColumns,
        updateSelectedColumns,
        availableColumns,
        languages,
        statuses,
        taskTypes,

        taskType,
        status,
        lang1,
        lang2,
        dateFrom,
        dateTo,
        searchCriteria,
        setSearchCriteria,
        handleUpdateSearch,
    } = TasksController(props)

    const history = useHistory()

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="float-end mt-3">
                            <Link to="/admin/tasks/new" className="btn btn-dark">
                                <i className="uil-plus"></i> Auftrag / Anfrage erstellen
                            </Link>
                        </div>
                        <h4 className="page-title"><strong className="text-black">Verwaltung</strong> / Aufträge</h4>
                    </div>
                </div>
            </div>
            {/* end page title */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-2">
                                <div className="col-xl-12">
                                    <form className="row mb-2 gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between" onSubmit={ handleUpdateSearch }>
                                        <div className="col-auto">
                                            <input
                                                type="search"
                                                className="form-control"
                                                placeholder="Suchen..."
                                                value={ searchCriteria.q }
                                                onChange={ (e) => { 
                                                    setSearchCriteria((prev) => ({
                                                        ...prev,
                                                        q: e.target.value
                                                    }))
                                                } }
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex align-items-center">
                                                <select
                                                    className="form-select"
                                                    id="task_status"
                                                    value={ status }
                                                    onChange={ (e) => { 
                                                        history.push(buildLink({ status: e.target.value }))
                                                    } }
                                                >
                                                    <option value="all">Alle Status</option>
                                                    { statuses.map(status => (
                                                        <option value={ status.key } key={ status.key }>{ status.value }</option>
                                                    )) }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex align-items-center">
                                                <label for="lang1" className="me-2">Sprachen</label>
                                                <select
                                                    className="form-select"
                                                    id="lang1"
                                                    value={ lang1 }
                                                    onChange={ (e) => { 
                                                        history.push(buildLink({ lang1: e.target.value }))
                                                    } }
                                                >
                                                    <option value="all">Alle</option>
                                                    { languages.map(language => (
                                                        <option value={ language.key } key={ language.key }>{ language.value }</option>
                                                    )) }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex align-items-center">
                                                <select
                                                    className="form-select"
                                                    id="lang2"
                                                    value={ lang2 }
                                                    onChange={ (e) => { 
                                                        history.push(buildLink({ lang2: e.target.value }))
                                                    } }
                                                >
                                                    <option value="all">Alle</option>
                                                    { languages.map(language => (
                                                        <option value={ language.key } key={ language.key }>{ language.value }</option>
                                                    )) }
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row gy-2 gx-2 mb-3 align-items-center justify-content-xl-start justify-content-between">
                                        <div className="col-auto">
                                            <div className="d-flex align-items-center">
                                                <label for="date_from" className="me-2">Zwischen</label>
                                                <input
                                                    type="date"
                                                    id="date_from"
                                                    className="form-control"
                                                    value={ dateFrom }
                                                    onChange={ (e) => { 
                                                        history.push(buildLink({ dateFrom: e.target.value }))
                                                    } }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex align-items-center">
                                                <label for="date_to" className="me-2">und</label>
                                                <input
                                                    type="date"
                                                    id="date_to"
                                                    className="form-control"
                                                    value={ dateTo }
                                                    onChange={ (e) => { 
                                                        history.push(buildLink({ dateTo: e.target.value }))

                                                    } }
                                                />
                                            </div>
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-xl-12">
                                    <div className="float-end mt-n2">
                                        <button
                                            type="button"
                                            className="btn btn-light dropdown-toggle show-column-dropdown-trigger show"
                                            onClick={ () => setShowColumnDropdown(true) }
                                        >
                                            Spalten anzeigen...
                                        </button>
                                        { showColumnDropdown && (
                                            <div
                                                className="dropdown-menu dropdown-menu-end show-column-dropdown show"
                                            >
                                                <div className="pt-2 ps-3">Spalten auswählen</div>
                                                <div className="dropdown-divider" />
                                                { Object.entries(availableColumns).map(([key, value]) => (
                                                    <div className="dropdown-item">
                                                        <div className="form-check form-checkbox-info mb-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={ `column_selector_${key}` }
                                                                checked={ selectedColumns.includes(key) }
                                                                onChange={ (e) => {
                                                                    if(e.target.checked) {
                                                                        updateSelectedColumns([
                                                                            ...selectedColumns,
                                                                            key
                                                                        ])
                                                                    }
                                                                    else {
                                                                        updateSelectedColumns(selectedColumns.filter(c => c !== key))
                                                                    }
                                                                } }
                                                            />
                                                            <label className="form-check-label" htmlFor={ `column_selector_${key}` }>
                                                                { value }
                                                            </label>
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>    
                                        ) }
                                    </div>
                                    { tasks && (
                                        <p>{tasks.totalResults} Ergebnisse</p>
                                    ) }
                                </div>
                            </div>
                            { isLoading && <TasksSkeleton /> }
                            { error && (
                                <div>{error}</div>
                            ) }
                            
                            { tasks && tasks.results.length === 0 && (
                                <div>Es wurden keine Aufträage gefunden</div>
                            ) }
    
                            { tasks && tasks.results.length > 0 && (
                                <>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-centered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Aktionen</th>
                                                    { selectedColumns.includes('task_id') && (
                                                        <th>Auftragsnummer</th>
                                                    ) }
                                                    { selectedColumns.includes('customer') && (
                                                        <th>Auftraggeber</th>
                                                    ) }
                                                    { selectedColumns.includes('entry_date') && (
                                                        <th>Eingangsdatum</th>
                                                    ) } 
                                                    { selectedColumns.includes('task_date') && (
                                                        <th>Auftragsdatum</th>
                                                    ) }
                                                    { selectedColumns.includes('task_type') && (
                                                        <th>Typ</th>
                                                    ) }
                                                    { selectedColumns.includes('title') && (
                                                        <th>Betreff</th>
                                                    ) }
                                                    { selectedColumns.includes('source_language') && (
                                                        <th>Ausgangssprachen</th>
                                                    ) }
                                                    { selectedColumns.includes('target_languages') && (
                                                        <th>Zielsprachen</th>
                                                    ) }
                                                    { selectedColumns.includes('status') && (
                                                        <th>Status</th>
                                                    ) }
                                                    { selectedColumns.includes('translators') && (
                                                        <th>Auftragnehmer</th>
                                                    ) }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { tasks.results.map((task) => (
                                                    <tr key={ task.id }>
                                                        <td>
                                                            <Link to={ `/admin/tasks/${task.id}`} className="btn btn-default p-0">
                                                                <i className="uil-edit"></i>
                                                            </Link>
                                                            <button
                                                                type="button"
                                                                className="btn btn-default p-0 px-2"
                                                                onClick={ () => setSelectedDeleteDropdown(task.id) }
                                                            >
                                                                <i className="uil-trash"></i>
                                                            </button>
                                                            { task.id === selectedDeleteDropdown && (
                                                                <div className="dropdown-menu show">
                                                                    <h6 className="dropdown-header">Löschen</h6>
                                                                    <button
                                                                        type="button"
                                                                        className="dropdown-item"
                                                                        onClick={ () => {
                                                                            setSelectedDeleteDropdown(undefined)
                                                                            deleteTask(task)
                                                                        } }
                                                                    >
                                                                        <i className="uil-trash"></i>
                                                                        <span> Löschen</span>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="dropdown-item"
                                                                        onClick={ () => setSelectedDeleteDropdown(undefined) }
                                                                    >
                                                                        <i className="uil-times"></i>
                                                                        <span> Abbrechen</span>
                                                                    </button>
                                                                </div>
                                                            ) }
                                                        </td>
                                                        { selectedColumns.includes('task_id') && (
                                                            <td>
                                                                <Link to={ `/admin/tasks/${task.id}`} className="btn btn-default p-0 text-start">
                                                                    { task.task_id }
                                                                </Link>
                                                            </td>
                                                        ) }
                                                        { selectedColumns.includes('customer') && (
                                                            <td>
                                                                { task.customer && (
                                                                    <Link to={ `/admin/users/${task.customer.id}` } className="text-secondary">
                                                                        <strong>{ task.customer?.name } { task.customer?.role && `(${task.customer.role})` }</strong>
                                                                    </Link>
                                                                ) }
                                                            </td>
                                                        ) }
                                                        { selectedColumns.includes('entry_date') && (
                                                            <td>{ apiToUiDate(task.entry_date) }</td>
                                                        ) }
                                                        { selectedColumns.includes('task_date') && [''].map(() => {
                                                            let textClass = ''

                                                            if(isPast(task.task_date)) {
                                                                textClass = 'text-muted'
                                                            }

                                                            if(isToday(task.task_date) || isWithinDays(task.task_date, 3)) {
                                                                textClass = 'text-danger'
                                                            }

                                                            return (
                                                                <td className={ textClass }>
                                                                    { apiToUiDate(task.task_date) }
                                                                </td>
                                                            ) }
                                                        ) }
                                                        { selectedColumns.includes('task_type') && (
                                                            <td>
                                                                { task.task_type === 'interpretation' && 'Dolmetschen'}
                                                                { task.task_type === 'translation' && 'Übersetzung' }
                                                                { task.task_type === 'lesson' && 'Sprachunterricht'}
                                                            </td>
                                                        ) }
                                                        { selectedColumns.includes('title') && (
                                                            <td>{ task.title }</td>
                                                        ) }
                                                        { selectedColumns.includes('source_language') && (
                                                            <td>
                                                                { 
                                                                    ["interpretation", "lesson"].includes(task.task_type) ? 
                                                                    [...new Set(task.interpretations.map(f => f.source_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') :
                                                                    [...new Set(task.files.map(f => f.source_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') 
                                                                }    
                                                            </td>
                                                        ) }
                                                        { selectedColumns.includes('target_languages') && (
                                                            <td>
                                                                { 
                                                                    ["interpretation", "lesson"].includes(task.task_type) ? 
                                                                    [...new Set(task.interpretations.map(f => f.target_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') :
                                                                    [...new Set(task.files.map(f => f.target_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') 
                                                                }
                                                            </td>
                                                        ) }
                                                        { selectedColumns.includes('status') && [''].map(() => {
                                                            let badgeClass = ''

                                                            switch(task.status) {
                                                                case 'draft':
                                                                    badgeClass = 'bg-light text-dark'
                                                                    break
                                                                case 'open':
                                                                    badgeClass = 'badge-info-lighten'
                                                                    break
                                                                case 'in_progress':
                                                                    badgeClass = 'bg-info'
                                                                    break
                                                                case 'cancelled':
                                                                    badgeClass = 'badge-danger-lighten'
                                                                    break
                                                                case 'completed':
                                                                    badgeClass = 'bg-success'
                                                                    break
                                                            }

                                                            return (
                                                                <td>
                                                                    <span className={ `badge ${badgeClass}` }>
                                                                        { statuses.find(s => s.key === task.status)?.value }
                                                                    </span>
                                                                </td>
                                                            )
                                                        }) }
                                                        { selectedColumns.includes('translators') && (
                                                            <td>
                                                                { 
                                                                    ["interpretation", "lesson"].includes(task.task_type) ? 
                                                                    task.interpretations.filter(f => f.translator).map(f => (
                                                                        <div>
                                                                            <Link to={ `/admin/users/${f.translator.id}`} className="btn btn-default p-0 text-start">
                                                                                { f.translator.name }
                                                                            </Link>
                                                                        </div>
                                                                    )) : 
                                                                    task.files.filter(f => f.translator).map(f => (
                                                                        <div>
                                                                            <Link to={ `/admin/users/${f.translator.id}`} className="btn btn-default p-0 text-start">
                                                                                { f.translator.name }
                                                                            </Link>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </td>
                                                        ) }
                                                    </tr>
                                                )) }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <nav>
                                            <ul className="pagination mb-0 mt-3">
                                                { tasks.page > 1 && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={ buildLink({ page: tasks.page - 1 }) }
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">«</span>
                                                        </Link>
                                                    </li>
                                                ) }
                                                { Array.from(Array(tasks.totalPages).keys()).map(i => i + 1).map((targetPage) => (
                                                    <li className={ `page-item ${targetPage !== tasks.page ? "" : "active text-dark"}` }>
                                                        <Link to={ buildLink({ page: targetPage }) } className="page-link">
                                                            { targetPage }
                                                        </Link>
                                                    </li>
                                                )) }
                                                { tasks.page < tasks.totalPages && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={ buildLink({ page: tasks.page + 1 }) }
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">»</span>
                                                        </Link>
                                                    </li>
                                                ) }
                                            </ul>
                                        </nav>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tasks