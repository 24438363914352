// @ts-nocheck
import {
    Link
  } from "react-router-dom"

const Register = () => {
    return (
        <>
            <h4 className="mt-0">Abmelden</h4>
            <p className="text-muted mb-4">Sie wurden erfolgreich abgemeldet.</p>
            <p className="text-muted mb-4">Klicken Sie <Link to="/auth/login">hier</Link>, um sich erneut einzuloggen.</p>
        </>
    )
}

export default Register