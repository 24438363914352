// @ts-nocheck
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import StripeForm from "components/Payment/StripeForm"

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

export default function StripeContainer({ task, onCancel, onSuccess }) {
	return (
		<Elements stripe={stripeTestPromise}>
			<StripeForm task={task} onCancel={onCancel} onSuccess={onSuccess} />
		</Elements>
	)
}