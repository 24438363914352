// @ts-nocheck
import { Link } from "react-router-dom"

import CustomerTranslatorsController from 'controllers/CustomerTranslators.controller'
import CustomerTranslatorsSkeleton from 'screens/skeletons/CustomerTranslators.skeleton'

import { apiToUiDate } from 'utils/datetime'

const CustomerTranslators = (props) => {
    
    const {
        users,
        setUsers,
        isLoading,
        error,
        languages,
        buildLink,
        searchCriteria,
        setSearchCriteria,
        handleUpdateSearch,
    } = CustomerTranslatorsController(props)

    return (
        <div>
            { isLoading && <CustomerTranslatorsSkeleton /> }
            { error && (
                <div>{error}</div>
            ) }
            { users && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title">Übersetzer:in / Dolmetscher:in / Sprachschulen</h4>
                            </div>
                        </div>
                    </div>
                    {/* end page title */}
                    <div className="row">
                        <div className="col-3">
                            <div className="card">
                                <div className="card-body">
                                    <form action="#" onSubmit={ handleUpdateSearch }>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="task_id"
                                                        className="form-label"
                                                    >
                                                        Suchkriterien
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="task_id"
                                                        className="form-control"
                                                        value={ searchCriteria.q }
                                                        onChange={ (e) => { 
                                                            setSearchCriteria((prev) => ({
                                                                ...prev,
                                                                q: e.target.value
                                                            }))
                                                        } }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="show_translators"
                                                        className="form-label"
                                                    >
                                                        Übersetzer anzeigen
                                                    </label>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id="show_translators"
                                                            data-switch="info"
                                                            checked={ searchCriteria.showTranslators }
                                                            onChange={ (e) => {
                                                                setSearchCriteria((prev) => ({
                                                                    ...prev,
                                                                    showTranslators: e.target.checked
                                                                }))
                                                            } }
                                                        />
                                                        <label
                                                            htmlFor="show_translators"
                                                            data-on-label="Ja"
                                                            data-off-label="Nein"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="show_interpreters"
                                                        className="form-label"
                                                    >
                                                        Dolmetscher:in anzeigen
                                                    </label>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id="show_interpreters"
                                                            data-switch="info"
                                                            checked={ searchCriteria.showInterpreters }
                                                            onChange={ (e) => {
                                                                setSearchCriteria((prev) => ({
                                                                    ...prev,
                                                                    showInterpreters: e.target.checked
                                                                }))
                                                            } }
                                                        />
                                                        <label
                                                            htmlFor="show_interpreters"
                                                            data-on-label="Ja"
                                                            data-off-label="Nein"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="show_schools"
                                                        className="form-label"
                                                    >
                                                        Sprachschulen anzeigen
                                                    </label>
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id="show_schools"
                                                            data-switch="info"
                                                            checked={ searchCriteria.showSchools }
                                                            onChange={ (e) => {
                                                                setSearchCriteria((prev) => ({
                                                                    ...prev,
                                                                    showSchools: e.target.checked
                                                                }))
                                                            } }
                                                        />
                                                        <label
                                                            htmlFor="show_schools"
                                                            data-on-label="Ja"
                                                            data-off-label="Nein"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 className="mb-3">
                                            Sprachen
                                        </h5>
                                        { searchCriteria.langs.map((language, i) => (
                                            <div className="row mb-1">
                                                <div className="col-12">
                                                    <div className="mb-2">
                                                        <div className="input-group">
                                                            <select
                                                                className="form-select"
                                                                id={ `language_${i}`}
                                                                value={language}
                                                                onChange={(e) => { 
                                                                    setSearchCriteria((prev) => ({
                                                                        ...prev,
                                                                        langs: prev.langs.map((l, u) => {
                                                                            if(u === i) {
                                                                                return e.target.value
                                                                            }
                                                                            else {
                                                                                return l
                                                                            }
                                                                        })
                                                                    }))
                                                                }}
                                                            >
                                                                <option value="">- Bitte auswählen -</option>
                                                                { languages.map(({key, value}) => (
                                                                    <option key={key} value={key}>
                                                                        { value }
                                                                    </option>
                                                                )) }
                                                            </select>
                                                            <span className="input-group-text p-0">
                                                                <button 
                                                                    type="button"
                                                                    className="btn btn-sm btn-default"
                                                                    onClick={ () => {
                                                                        setSearchCriteria((prev) => ({
                                                                            ...prev,
                                                                            langs: prev.langs.filter((l, u) => u !== i)
                                                                        }))
                                                                    } }
                                                                >
                                                                    <i className="uil-times" />
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) }
                                        <div>
                                            <button 
                                                type="button"
                                                className="btn btn-light mb-3"
                                                onClick={ () => {
                                                    setSearchCriteria((prev) => ({
                                                        ...prev,
                                                        langs: [
                                                            ...prev.langs,
                                                            ""
                                                        ]
                                                    }))
                                                } }
                                            >   
                                                <i className="uil-plus" />{ " " }
                                                <span className="ps-2">Sprache hinzufügen</span>
                                            </button>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <button 
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >Suchen</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            { users.results.length > 0 ? (
                                <>
                                    { users.results.map((user) => {
                                        const profile = user.profiles.find(p => p.role === 'translator')
                                        return (
                                            <div className="card text-left" key={ user.id }>
                                                <div className="card-body">
                                                    <Link to={ `/customer/translators/${user.id}` }>
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                                <div className="avatar-md">
                                                                    <img
                                                                        src={profile.profile_picture ? profile.profile_picture : "/assets/images/users/avatar-0.png"}
                                                                        className="rounded-circle avatar-md img-thumbnail"
                                                                        alt="profile-image"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col text-dark">
                                                                <div>
                                                                    <h4 className="mb-0 mt-2">{ profile.type === 'school' ? profile.company_name : `${user.first_name} ${user.last_name}` }</h4>
                                                                    <div className="font-13">
                                                                        { profile.role === 'translator' && (
                                                                            profile.type === 'school' ? 'Sprachschule' : (
                                                                                [profile.translator_profile?.is_translator && 'Übersetzer:in', profile.translator_profile?.is_interpreter && 'Dolmetscher:in'].filter(e => !!e).join(' / ')
                                                                            )
                                                                        ) }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            { profile.translator_profile?.description }
                                                        </div>
                                                    </div>
                                                    { (profile.translator_profile?.keywords.length > 0 || profile.translator_profile?.languages.length > 0) && (
                                                        <hr />
                                                    ) }
                                                    { profile.translator_profile?.keywords.length > 0 && (
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                <strong>Fähigkeiten</strong> { profile.translator_profile?.keywords.map((keyword) => (
                                                                    <span className="badge badge-dark-lighten rounded-pill ms-1 p-1">{ keyword }</span>
                                                                )) }
                                                            </div>
                                                        </div>
                                                    ) }
                                                    { profile.translator_profile?.languages.length > 0 && (
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                <strong>Sprachen</strong> { profile.translator_profile?.languages.map((l) => {
                                                                    const languageName = languages.find(lg => lg.key === l.language)?.value
                                                                    if(!languageName) {
                                                                        return undefined
                                                                    }

                                                                    return (l.is_native) ? `${languageName} (Muttersprache)` : languageName
                                                                }).filter(l => !!l).join(' - ') }
                                                            </div>
                                                        </div>
                                                    ) }
                                                </div>
                                            </div>
                                        )
                                    }) }
                                    <div>
                                        <nav>
                                            <ul className="pagination mb-0 mt-3">
                                                { users.page > 1 && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={ buildLink({ page: users.page - 1 }) }
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">«</span>
                                                        </Link>
                                                    </li>
                                                ) }
                                                { Array.from(Array(users.totalPages).keys()).map(i => i + 1).map((targetPage) => (
                                                    <li className={ `page-item ${targetPage !== users.page ? "" : "active text-dark"}` }>
                                                        <Link to={ buildLink({ page: targetPage }) } className="page-link">
                                                            { targetPage }
                                                        </Link>
                                                    </li>
                                                )) }
                                                { users.page < users.totalPages && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={ buildLink({ page: users.page + 1 }) }
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">»</span>
                                                        </Link>
                                                    </li>
                                                ) }
                                            </ul>
                                        </nav>
                                    </div>
                                </>
                            ) : (
                                <div className="card text-center">
                                    <div className="card-body">
                                        <p>Es wurden keine Übersetzer:in / Dolmetscher:in gefunden</p>
                                    </div>
                                </div>
                            ) }
                        </div>
                    </div>
                </>
            ) }
        </div>
    )
}

export default CustomerTranslators