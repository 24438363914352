// @ts-nocheck
import React, { useContext, useState, useEffect } from "react"
import { postRequest, putRequest, patchRequest, getRequest, deleteRequest } from "clients/ApiClient"

import AppLoadingScreen from "components/AppLoadingScreen"

const AuthContext = React.createContext()

export const useAuth = () => {
    return useContext(AuthContext)
}

const REFRESH_TOKEN_KEY = "refresh-token"
const ACCESS_TOKEN_KEY = "access-token"

export const buildLoginUser = (user) => {
    return {
        ...user, 
        activeProfile: user.profiles.find(p => p.is_active_profile)
    }
}

const getAccessToken = () => {
    try {
        return localStorage.getItem(ACCESS_TOKEN_KEY)
    } catch (e) {
        return undefined
    }
}

const setAccessToken = (token) => {
    try {
        localStorage.setItem(ACCESS_TOKEN_KEY, token)
    } catch (e) {}
}

const removeAccessToken = () => {
    try {
        localStorage.removeItem(ACCESS_TOKEN_KEY)
    } catch (e) {}
}

const getRefreshToken = () => {
    try {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    } catch (e) {
        return undefined
    }
}

const setRefreshToken = (token) => {
    try {
        localStorage.setItem(REFRESH_TOKEN_KEY, token)
    } catch (e) {}
}

const removeRefreshToken = () => {
    try {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    } catch (e) {}
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState()
    const [isAppReady, setIsAppReady] = useState(false)

    useEffect(() => {
        initializeApp()
    }, [])

    const initializeApp = async () => {
        const accessToken = getAccessToken()
        if(accessToken) {
            const res = await authGetRequest("/my/profile-information")
            
            if(res.status === 200 && res.data) {
                setCurrentUser(buildLoginUser(res.data))
                // setCurrentUser(res.data)
            }
        }
        
        setIsAppReady(true)
    }

    const authPostRequest = async (url, data, headers = {}) => {
        const accessToken = getAccessToken()
        const refreshToken = getRefreshToken()
        const res = await postRequest(url, data, {
            ...headers, 'Authorization': `Bearer ${accessToken}`
        })
        if(res.status === 403 && accessToken && refreshToken) {
            const refreshRes = await postRequest("/auth/token", { token: refreshToken })
            if(refreshRes.status === 200) {
                const newAccessToken = refreshRes.data.accessToken
                setAccessToken(newAccessToken)
                return await postRequest(url, data, { 
                    ...headers, 
                    'Authorization': `Bearer ${newAccessToken}`
                })
            }
        }
        
        return res
    }

    const authPutRequest = async (url, data) => {
        const accessToken = getAccessToken()
        const refreshToken = getRefreshToken()
        const res = await putRequest(url, data, { 'Authorization': `Bearer ${accessToken}`})
        if(res.status === 403 && accessToken && refreshToken) {
            const refreshRes = await postRequest("/auth/token", { token: refreshToken })
            if(refreshRes.status === 200) {
                const newAccessToken = refreshRes.data.accessToken
                setAccessToken(newAccessToken)
                return await putRequest(url, data, { 'Authorization': `Bearer ${newAccessToken}`})
            }
        }
        
        return res
    }

    const authPatchRequest = async (url, data) => {
        const accessToken = getAccessToken()
        const refreshToken = getRefreshToken()
        const res = await patchRequest(url, data, { 'Authorization': `Bearer ${accessToken}`})
        if(res.status === 403 && accessToken && refreshToken) {
            const refreshRes = await postRequest("/auth/token", { token: refreshToken })
            if(refreshRes.status === 200) {
                const newAccessToken = refreshRes.data.accessToken
                setAccessToken(newAccessToken)
                return await patchRequest(url, data, { 'Authorization': `Bearer ${newAccessToken}`})
            }
        }
        
        return res
    }

    const authGetRequest = async (url) => {
        const accessToken = getAccessToken()
        const refreshToken = getRefreshToken()
        const res = await getRequest(url, { 'Authorization': `Bearer ${accessToken}`})
        if(res.status === 403 && accessToken && refreshToken) {
            const refreshRes = await postRequest("/auth/token", { token: refreshToken })
            if(refreshRes.status === 200) {
                const newAccessToken = refreshRes.data.accessToken
                setAccessToken(newAccessToken)
                return await getRequest(url, { 'Authorization': `Bearer ${newAccessToken}`})
            }
        }
        
        return res
    }

    const authDeleteRequest = async (url) => {
        const accessToken = getAccessToken()
        const refreshToken = getRefreshToken()
        const res = await deleteRequest(url, { 'Authorization': `Bearer ${accessToken}`})
        if(res.status === 403 && accessToken && refreshToken) {
            const refreshRes = await postRequest("/auth/token", { token: refreshToken })
            if(refreshRes.status === 200) {
                const newAccessToken = refreshRes.data.accessToken
                setAccessToken(newAccessToken)
                return await deleteRequest(url, { 'Authorization': `Bearer ${newAccessToken}`})
            }
        }
        
        return res
    }

    const signup = async (member) => {
        return await postRequest("/auth/register", member)
    }

    const setActiveProfile = async (profile) => {
        const res = await authPatchRequest('/my/active-profile', profile)
        try {
            switch(res.status) {
                case 422:
                    return res.data
                case 404:
                    return "INVALID_LOGIN_ATTEMPT"
                case 500:
                    return "SERVER_ERROR"
                case 200:
                default:
                    setCurrentUser({
                        ...res.data,
                        activeProfile: res.data.profiles.find(p => p.is_active_profile)
                    })
            }
        }
        catch(e) { console.log(e) }
    }

    const verifyAccount = async (code) => {
        const res = await postRequest(`/auth/verify-email?token=${code}`)
        if(res.status === 204) {
            return "SUCCESS"
        }
        return "FAIL"
    }

    const login = async (email, password) => {
        const loginResult = await postRequest("/auth/login", { email, password })

        try {
            switch(loginResult.status) {
                case 422:
                    return loginResult.data
                case 401:
                    return "INVALID_LOGIN_ATTEMPT"
                case 500:
                    return "SERVER_ERROR"
                case 200:
                default:
                    console.log("BEFORE")
                    if(!loginResult.data.user.isEmailVerified) {
                        return "MEMBER_NOT_VERIFIED"
                    }
                    if(loginResult.data.user.is_blocked) {
                        return "MEMBER_BLOCKED"
                    }
                    setAccessToken(loginResult.data.tokens.access.token)
                    setRefreshToken(loginResult.data.tokens.refresh.token)
                    setCurrentUser(buildLoginUser(loginResult.data.user))
                    return "SUCCESS"
            }
        }
        catch(e) { console.log(e) }
        
    }

    const impersonate = async ({user, tokens}) => {
        setAccessToken(tokens.access.token)
        setRefreshToken(tokens.refresh.token)
        setCurrentUser(buildLoginUser(user))
    }

    const updateUserInformation = (data) => {
        setCurrentUser(buildLoginUser(data))
    }

    const logout = async () => {
        const refreshToken = getRefreshToken()
        await postRequest("/auth/logout", { refreshToken })
        removeAccessToken()
        removeRefreshToken()
        setCurrentUser(undefined)
    }

    const value = {
        currentUser,
        setCurrentUser,
        signup,
        verifyAccount,
        login,
        logout,
        updateUserInformation,
        setActiveProfile,
        authPostRequest,
        authPutRequest,
        authPatchRequest,
        authGetRequest,
        authDeleteRequest,
        impersonate,
    }

    if(!isAppReady) {
        return <AppLoadingScreen />
    }

    return (
        <AuthContext.Provider value={value}>
            { children }
        </AuthContext.Provider>
    )
}