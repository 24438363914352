// @ts-nocheck
import { useState } from "react"

import { useAuth } from "contexts/AuthContext"

const IncomingCalls = ({ incomingCalls }) => {
    const authContext = useAuth()
    const [answeredCalls, setAnsweredCalls] = useState([])

    const acceptCall = async (callId) => {
        setAnsweredCalls((answeredCalls) => [
            ...answeredCalls,
            callId
        ])
        await authContext.authPatchRequest(`/my/video/by-id/${callId}/change-status`, {
            status: 'ACCEPTED',
        })
        window.location.replace(`/video/${callId}#r=${window.location.href}`)
    }
    const declineCall = async (callId) => {
        setAnsweredCalls((answeredCalls) => [
            ...answeredCalls,
            callId
        ])
        await authContext.authPatchRequest(`/my/video/by-id/${callId}/change-status`, {
            status: 'DECLINED'
        })
    }

    return (
        <div id="incoming-calls">
            { incomingCalls.filter((incomingCall) => !answeredCalls.includes(incomingCall.id)).map((incomingCall) => {
                return (
                    <div className="card mb-2">
                        <div className="card-body p-0 bg-dark text-light">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex align-items-center mt-1 p-2">
                                        <img src="/assets/images/users/avatar-0.png" className="me-2 rounded-circle" height="48" alt="Brandon Smith" />
                                        <div className="w-100 overflow-hidden">
                                            <div className="float-end">
                                                <button
                                                    onClick={ () => {
                                                        acceptCall(incomingCall.id)
                                                    }}
                                                    className="btn btn-icon rounded-pill btn-large btn-success" 
                                                >
                                                    <i className="dripicons-phone" />
                                                </button>
                                                <button
                                                    className="btn btn-icon rounded-pill btn-large btn-danger"
                                                    onClick={ () => {
                                                        declineCall(incomingCall.id)
                                                    }}
                                                >
                                                    <i className="dripicons-cross" />
                                                </button>
                                            </div>
                                            <h5 className="mt-0 mb-0 font-14">
                                                { incomingCall.user_1_name }
                                            </h5>
                                            <p className="text-muted m-0">
                                                ruft Sie an
                                            </p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div> 
                )
            }) }
        </div>
    )
}

export default IncomingCalls