// @ts-nocheck
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import { AuthProvider } from "contexts/AuthContext"
import { AppSettingsProvider } from "contexts/AppSettingsContext"
import { BackgroundJobsProvider } from "contexts/BackgroundJobsContext"

import Layout from "components/Layout/Layout"
import GuestLayout from "components/Layout/GuestLayout"

// Route Types
import AdminRoute from "routing/AdminRoute"
import TranslatorRoute from "routing/TranslatorRoute"
import CustomerRoute from "routing/CustomerRoute"
import AnonymousRoute from "routing/AnonymousRoute"
import Redirector from "routing/Redirector"

// Admin Routes
import AdminDashboard from "screens/admin/AdminDashboard/AdminDashboard"
import AdminCalendar from "screens/admin/AdminCalendar" //
import AdminTasks from "screens/admin/Tasks"
import AdminTask from "screens/admin/Task"
import AdminUsers from "screens/admin/Users"
import AdminUser from "screens/admin/User"
import AdminAppSettings from "screens/admin/AdminAppSettings"
import AdminMessenger from "screens/admin/AdminMessenger"

// DELETE BOF
import AdminTestRequestResopnse from "screens/admin/AdminTestRequestResopnse"
// DELETE EOF

// Customer Routes
import CustomerDashboard from "screens/customer/CustomerDashboard/CustomerDashboard"
import CustomerCalendar from "screens/customer/CustomerCalendar" //
import CustomerMessenger from "screens/customer/CustomerMessenger" //
import CustomerMyTasks from "screens/customer/CustomerMyTasks" //
import CustomerTaskView from "screens/customer/CustomerTaskView"
import CustomerNewTranslationTask from "screens/customer/CustomerNewTranslationTask"
import CustomerCustomer from "screens/customer/CustomerCustomer"
import CustomerTranslators from "screens/customer/CustomerTranslators"
import CustomerTranslator from "screens/customer/CustomerTranslator"
import CustomerMyProfile from "screens/customer/CustomerMyProfile"
import CustomerInvoiceList from "screens/customer/CustomerInvoiceList"
import CustomerInvoiceView from "screens/customer/CustomerInvoiceView"
import CustomerBilling from "screens/customer/CustomerBilling"
import CustomerLogout from "screens/customer/CustomerLogout" //

// Translator Routes
import TranslatorDashboard from "screens/translator/TranslatorDashboard/TranslatorDashboard"
import TranslatorCalendar from "screens/translator/TranslatorCalendar" //
import TranslatorMyProfile from "screens/translator/TranslatorMyProfile"
import TranslatorCustomer from "screens/translator/TranslatorCustomer"
import TranslatorMyTasks from "screens/translator/TranslatorMyTasks/TranslatorMyTasks" //
import TranslatorTasks from "screens/translator/TranslatorTasks" //
import TranslatorTaskView from "screens/translator/TranslatorTaskView"
import TranslatorMessenger from "screens/translator/TranslatorMessenger"

// Shared Routes
import Settings from "screens/common/Settings"

// Auth Routes
import Register from "screens/auth/Register"
import CustomerVerification from "screens/auth/CustomerVerification"
import Login from "screens/auth/Login"
import Password from "screens/auth/Password"
import PasswordReset from "screens/auth/PasswordReset"
import Logout from "screens/auth/Logout"

// Video Call
import VideoCall from "screens/video/VideoCall"

// Make the large Routes, such as <Route path="/admin" into AuthRoute
// https://www.pluralsight.com/guides/how-to-router-redirect-after-login

function App() {
  return (
    <Router>
      <AppSettingsProvider>
        <AuthProvider>
          <BackgroundJobsProvider>
            <Switch>
              <AdminRoute path="/admin" render={() => (
                <Layout userType="admin">
                  <Route path="/admin/test-request-response" exact component={ AdminTestRequestResopnse } />


                  <Route path="/admin/dashboard" exact component={ AdminDashboard } />
                  <Route path="/admin/calendar" exact component={ AdminCalendar } />
                  <Route path="/admin/tasks" exact component={ AdminTasks } />
                  <Route path="/admin/tasks/:taskId" exact component={ AdminTask } />
                  <Route path="/admin/users" exact component={ AdminUsers } />
                  <Route path="/admin/users/:userId" exact component={ AdminUser } />
                  <Route path="/admin/app-settings" exact component={ AdminAppSettings } />
                  <Route path="/admin/settings" exact component={ Settings } />
                  <Route path="/admin/messenger" exact component={ AdminMessenger } />
                  <Route path="/admin/messenger/:userId" exact component={ AdminMessenger } />
                </Layout>
              )} />
              <CustomerRoute path="/customer" render={() => (
                <Layout userType="customer">
                  <Route path="/customer/dashboard" exact component={ CustomerDashboard } />
                  <Route path="/customer/calendar" exact component={ CustomerCalendar } />
                  <Route path="/customer/customers/:id" exact component={ CustomerCustomer } />
                  <Route path="/customer/messenger" exact component={ CustomerMessenger } />
                  <Route path="/customer/messenger/:userId" exact component={ CustomerMessenger } />
                  <Route path="/customer/my-tasks" exact component={ CustomerMyTasks } />
                  <Route path="/customer/my-tasks/new/translation" exact component={ CustomerNewTranslationTask } />
                  <Route path="/customer/my-tasks/new/interpretation" exact component={ CustomerNewTranslationTask } />
                  <Route path="/customer/my-tasks/:id" exact component={ CustomerTaskView } />
                  <Route path="/customer/translators" exact component={ CustomerTranslators } />
                  <Route path="/customer/translators/:id" exact component={ CustomerTranslator } />
                  <Route path="/customer/my-profile" exact component={ CustomerMyProfile } />
                  <Route path="/customer/settings" exact component={ Settings } />
                  <Route path="/customer/invoices" exact component={ CustomerInvoiceList } />
                  <Route path="/customer/invoices/:id" exact component={ CustomerInvoiceView } />
                  <Route path="/customer/billing" exact component={ CustomerBilling } />
                  <Route path="/customer/logout" exact component={ CustomerLogout } />
                </Layout>
              )} />
              <TranslatorRoute path="/translator" render={() => (
                <Layout userType="translator">
                  <Route path="/translator/dashboard" exact component={ TranslatorDashboard } />
                  <Route path="/translator/calendar" exact component={ TranslatorCalendar } />
                  <Route path="/translator/my-profile" exact component={ TranslatorMyProfile } />
                  <Route path="/translator/customers/:id" exact component={ TranslatorCustomer } />
                  <Route path="/translator/my-tasks" exact component={ TranslatorMyTasks } />
                  <Route path="/translator/tasks" exact component={ TranslatorTasks } />
                  <Route path="/translator/tasks/:id" exact component={ TranslatorTaskView } />
                  <Route path="/translator/messenger" exact component={ TranslatorMessenger } />
                  <Route path="/translator/messenger/:userId" exact component={ TranslatorMessenger } />

                  <Route path="/translator/settings" exact component={ Settings } />
                </Layout>  
              )} />
              <AnonymousRoute path="/auth" render={() => (
                <GuestLayout>
                  <Route path="/auth/register" exact component={ Register } />
                  <Route path="/auth/login" exact component={ Login } />
                  <Route path="/auth/password" exact component={ Password } />
                  <Route path="/auth/password-reset/:token" exact component={ PasswordReset } />
                  <Route path="/auth/logout" exact component={ Logout } />
                </GuestLayout>
              )} />
              <Route path="/verification/:code" exact render={(props) => (
                <GuestLayout>
                  <CustomerVerification {...props} />
                </GuestLayout>
              )}/>
              <Route path="/video/:roomId" exact component={ VideoCall } />
              <Route path="/" exact component={ Redirector } />
            </Switch>
          </BackgroundJobsProvider>
        </AuthProvider>
      </AppSettingsProvider>
    </Router>
  );
}

export default App;
