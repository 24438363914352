// @ts-nocheck
import { Link, useLocation } from "react-router-dom"

import moment from 'moment'
import ProfilePicture from 'components/ProfilePicture/ProfilePicture'

const ProfileCardContent = ({ 
    user,
    profile, 
    isMyProfile, 
    isFirstTimeProfile, 
    handleSubmitForm, 
    handleCancelForm,
    showTab, 
    setShowTab,
    view,
 }) => {
    const location = useLocation()

    const viewer = location.pathname.substr(0, '/customer'.length) === '/customer' ? 'customer' : 'translator'
    
    return (
        <>
            <ProfilePicture
                profile={profile}
                isMyProfile={isMyProfile}
            />
            <h4 className="mb-0 mt-2">
                { user.is_company ? user.company_name : `${user.first_name} ${user.last_name}` }
            </h4>
            <p className="text-muted font-14">
                { user.is_company && profile.type === 'company' && 'Firma' }
                { user.is_company && profile.type === 'government' && 'Staatliche Institution' }
            </p>
            { !isMyProfile ? (
                <>
                    <Link
                        className="btn btn-danger btn-sm mb-2"
                        to={`/${viewer}/messenger/${user.id}`}
                    >
                        Nachricht senden
                    </Link>
                </>
            ) : isFirstTimeProfile ? (
                <button
                    type="button"
                    className="btn btn-primary btn-sm mb-2"
                    onClick={ handleSubmitForm }
                >
                    Profil erstellen
                </button>
            ) : showTab === 'info' ? (
                <button
                    type="button"
                    className="btn btn-light btn-sm mb-2"
                    onClick={ () => setShowTab('update') }
                >
                    Profil bearbeiten
                </button>
            ) : showTab === 'update' && (
                <>
                    <button
                        type="button"
                        className="btn btn-dark btn-sm mb-2"
                        onClick={ handleSubmitForm }
                    >
                        Profil aktualisieren
                    </button>
                    <button
                        type="button"
                        className="btn btn-light btn-sm mb-2"
                        onClick={ handleCancelForm }
                    >
                        Abbrechen
                    </button>
                </>
            ) }
            <div className="text-start mt-3">
                { profile.customer_profile?.description && (
                    <>
                        <h4 className="font-13 text-uppercase">
                            Beschreibung:
                        </h4>
                        <p className="text-muted font-13 mb-3">
                            { profile.customer_profile.description?.split("\n").map(line => (
                                <>
                                    {line}
                                    <br/>
                                </>
                            )) }
                        </p>
                    </>
                ) }
                <p className="text-muted font-13">
                    <strong>Mitglied seit: </strong> { moment(user.created).format('DD.MM.YYYY') }
                </p>
            </div>
        </>
    )
}

export default ProfileCardContent