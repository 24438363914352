// @ts-nocheck
import { useState, useEffect } from 'react'
import {
    useParams,
    useHistory
  } from "react-router-dom";
import { toast } from 'react-toastify';

import { getRequest } from "clients/ApiClient"
import { useAuth } from "contexts/AuthContext"

import { formatDateForApi } from 'utils/datetime'

const fetchMetrics = async (setMetrics, setIsLoading, setError, authContext) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        // TODO
        const metrics = await authGetRequest(`/my/customer/dashboard-metrics`)

        if(!metrics.data.code) {
            setMetrics(metrics.data)
        }
        else {
            switch(metrics.data.code) {
                case 404:
                case 400:
                    console.log(metrics)
                    throw new Error('Die Daten konnten nicht geladen werden.')
                default:
                case 500:
                    throw new Error('Beim Laden der Daten ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setMetrics(undefined)
        setError(e.message)
    }
    setIsLoading(false)
}

const CustomerDashboardController = () => {
    const authContext = useAuth()

    const [metrics, setMetrics] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState()
    
    useEffect(() => {
        fetchMetrics(setMetrics, setIsLoading, setError, authContext)
    }, [authContext])

    return {
        metrics,
        isLoading,
        error,
    }
}

export default CustomerDashboardController