// @ts-nocheck
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "contexts/AuthContext"

const TranslatorRoute = ({ render, ...rest }) => {
    const { currentUser } = useAuth()

    const currentUrl = rest.location.pathname

    const urlNeedsProfile = !["/translator/my-profile"].includes(currentUrl)

    return (
        <Route
            {...rest}
            render={ () => {
                if(!currentUser) {
                    return <Redirect to="/auth/login" />
                }
                if(currentUser.activeProfile.role !== "translator") {
                    return <Redirect to="/" />
                }
                if(!currentUser.activeProfile.is_completed && urlNeedsProfile) {
                    return <Redirect to="/translator/my-profile" />
                }

                return render()
            } }
        />
    )
}

export default TranslatorRoute