// @ts-nocheck
import React, { useRef, useEffect } from "react";
import { toast } from 'react-toastify';
import { useAuth } from "contexts/AuthContext"

const Paypal = ({ task, onSuccess }) => {
  const paypal = useRef();
  const authContext = useAuth()

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: `AtId: ${task.task_id} - Betreff: ${task.title}`,
                amount: {
                  currency_code: "EUR",
                  value: task.payment_total_price,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log({order});

          try {
            const res = await authContext.authPostRequest(`/my/payments/paypal/${task.id}`, {
                orderId: order.id
            })

            if(res.data.success) {
                toast.success("Die Zahlung wurde erfolgreich verarbeitet.")
                console.error({"res.data": res.data})
                onSuccess()
            } else {
                throw new Error()
            }
          } catch(e) {
            toast.error("Die Zahlung wurde verarbeitet, aber unser System wurde nicht informiert. Bitte kontaktieren Sie uns.")
          }
        },
        onError: (err) => {
          console.log(err);
          toast.error("Die Zahlung konnte nicht verarbeitet werden.")
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}

export default Paypal