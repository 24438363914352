// @ts-nocheck
import { useState, useRef } from "react"
import React from 'react'
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'

import { buildLoginUser, useAuth } from "contexts/AuthContext"

const ProfilePicture = ({ profile, isMyProfile }) => {
    const { setCurrentUser, authPostRequest } = useAuth()

    const [isEditingPicture, setIsEditingPicture] = useState(false)
    const [imageSrc, setImageSrc] = useState()

    const editorRef = useRef(null)
    const dropzoneRef = useRef(null)

    const handleDrop = (dropped) => {
        setImageSrc(dropped[0])
    }

    const saveFile = async (file) => {    
        const data = new FormData();
        
        data.append('file', file, file.name);
    
        const res = await authPostRequest(`/admin/files/profile-picture`, data, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            },
        })

        if(!res.data.code && res.data) {
            setCurrentUser(buildLoginUser(res.data))
        }
        setIsEditingPicture(false)
        setImageSrc(false)
    }

    const saveNewImage = () => {
        const imageContent = editorRef.current.getImageScaledToCanvas().toDataURL()

        fetch(imageContent)
            .then(res => res.blob())
            .then(blob => saveFile(blob))
    }

    const onCancel = () => {
        setIsEditingPicture(false)
        setImageSrc(undefined)
    }

    return (
        <>
            { isEditingPicture ? (
                <>
                    <div className="mb-4">
                        <Dropzone
                            onDrop={handleDrop}
                            noKeyboard
                            style={{ width: '250px', height: '250px' }}
                            ref={dropzoneRef}
                            noClick={true}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    {imageSrc ? (
                                        <AvatarEditor
                                            width={250}
                                            height={250}
                                            image={imageSrc}
                                            ref={editorRef}
                                        />
                                    ) : (
                                        <div className="empty-avatar">Ziehen Sie Ihr Bild hierher, um es hochzuladen, oder klicken Sie auf 'Durchsuchen'</div>
                                    )}
                                    <input {...getInputProps()} />
                                </div>
                            )}
                        </Dropzone>
                        <button type="button" onClick={() => dropzoneRef.current.open()} className="btn btn-dark me-2">Durchsuchen</button>
                        <button className="btn btn-primary me-2" disabled={!imageSrc} onClick={saveNewImage}>Speichern</button>
                        <button className="btn btn-light" onClick={onCancel}>Abbrechen</button>
                    </div>
                </>
            )  : (
                <>
                    <img
                        src={profile.profile_picture ? profile.profile_picture : "/assets/images/users/avatar-0.png"}
                        className="rounded-circle avatar-lg img-thumbnail"
                        alt="profile-image"
                    />
                    { isMyProfile && (
                        <>
                            <br />
                            <button className="btn btn-link text-info" onClick={() => setIsEditingPicture(true)}>
                                {profile.profile_picture ? "Bild ändern" : "Bild hinzufügen"}
                            </button>
                        </>
                    ) }
                </>
            ) }
        </>
    )
}

export default ProfilePicture