// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import Video from "twilio-video"

import { useAuth } from "contexts/AuthContext"

const VideoCallController = (props) => {
    const authContext = useAuth()

    const mutableVideoCall = useRef(null)
    const [videoCall, setVideoCall] = useState()
    const [room, setRoom] = useState()
    const [error, setError] = useState()
    const [isConnecting, setIsConnecting] = useState(false)


    const fetchVideoCall = async (roomId, callback) => {
        const { authGetRequest } = authContext
        
        try {
            const videoCall = await authGetRequest(`/my/video/by-id/${roomId}`)
    
            if(!videoCall.data.code) {
                const prevStatus = mutableVideoCall.current?.status
                const newStatus = videoCall.data?.status

                setVideoCall(videoCall.data)
                mutableVideoCall.current = videoCall.data

                if(callback) {
                    callback({
                        isStatusChange: prevStatus !== newStatus
                    })
                }
            }
        }
        catch(e) {
            setVideoCall(undefined)
            mutableVideoCall.current = undefined
        }
    }

    const goToPreviousPage = () => {
        const prevUrl = window.location.hash && window.location.hash.substr(0, 3) === '#r=' && window.location.hash.substr(3)
        window.location.replace(prevUrl ? prevUrl : '/')
    }

    const logout = () => {
        setRoom((prevRoom) => {
            if (prevRoom) {
              prevRoom.localParticipant.tracks.forEach((trackPub) => {
                trackPub.track.stop();
              });
              prevRoom.disconnect();
            }
            return null;
        });
    }

    const hangUp = async () => {
        if(mutableVideoCall.current) {
            await authContext.authPatchRequest(`/my/video/by-id/${mutableVideoCall.current.id}/change-status`, {
                status: 'DECLINED'
            })
        }

        logout()

        goToPreviousPage()
    }

    const setUpCall = async () => {
        setIsConnecting(true)

        try {
            const token = await authContext.authPostRequest(`/my/video/token`, {
                roomId: mutableVideoCall.current.id
            })
    
            if(!token.data.code) {
                try {
                    const room = await Video.connect(token.data.token, {
                        name: mutableVideoCall.current.id,
                    })
                    setRoom(room)
                } catch(e) {
                    setRoom(undefined)
                }

                setIsConnecting(false)
            }
        }
        catch(e) {
            setVideoCall(undefined)
            mutableVideoCall.current = undefined
        }
    }

    const processStatusUpdate = ({ isStatusChange }) => {
        if(isStatusChange) {
            switch(mutableVideoCall.current?.status) {
                case "ACCEPTED": 
                    setUpCall()
                    break
                case "DECLINED": 
                    goToPreviousPage()
                    break
                case "PENDING":
                default:
                    // do nothing
                    break;
            }
        }
    }

    useEffect(() => {
        fetchVideoCall(props.match.params.roomId, processStatusUpdate)
        let interval = setInterval(() => {
            fetchVideoCall(props.match.params.roomId, processStatusUpdate)
        }, 1000)

        return () => {
            clearInterval(interval)
            interval = null
        }
    }, [props.match.params.roomId])

    useEffect(() => {
        if (room) {
          const tidyUp = (event) => {
            if (event.persisted) {
              return;
            }
            if (room) {
              logout();
            }
          };
          window.addEventListener("pagehide", tidyUp);
          window.addEventListener("beforeunload", tidyUp);
          return () => {
            window.removeEventListener("pagehide", tidyUp);
            window.removeEventListener("beforeunload", tidyUp);
          };
        }
      }, [room, logout]);

    return {
        videoCall,
        hangUp,
        room,
        isConnecting,
        error,
    }
}

export default VideoCallController