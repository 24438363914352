// @ts-nocheck
import React, { useState } from "react"

import { Link } from "react-router-dom"

import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"

const Register = () => {
    const [customerType, setCustomerType] = useState()
    const [customerSubtype, setCustomerSubtype] = useState()
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [createdUser, setCreatedUser] = useState()
    const { signup } = useAuth()

    const customerTypeChangeHandle = (event) => {
        setCustomerType(event.target.value)
        setCustomerSubtype(undefined)
    }

    const customerSubtypeChangeHandle = (event) => {
        setCustomerSubtype(event.target.value)
    }

    const validateForm = (formElements) => {
        if(!formElements.emailaddress.value) {
            setError("Bitte geben Sie eine E-Mail Adresse ein")
            return false
        }
        if(!formElements.firstname.value) {
            setError("Bitte geben Sie Ihren Vornamen ein")
            return false
        }
        if(!formElements.lastname.value) {
            setError("Bitte geben Sie Ihren Nachnamen ein")
            return false
        }
        if(!formElements.password.value) {
            setError("Bitte wählen Sie ein Passwort")
            return false
        }

        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        if(isLoading) {
            return
        }

        if(!validateForm(e.target.elements)) {
            return
        }

        let role = ""
        let type = ""

        switch(e.target.elements["customer-type"].value) {
            case "customer":
                role = "customer"
                break
            case "translator":
                role = "translator"
                break
            case "school":
                role = "translator"
                type = "school"
                if(!e.target.elements.companyname?.value) {
                    setError("Bitte geben Sie den Namen der Schule ein")
                    return
                }
                break
            default:
                setError("Bitte wählen Sie eine gültige Option")
                return
        }

        if(e.target.elements["customer-subtype"]?.value) {
            switch(e.target.elements["customer-subtype"].value) {
                case "private":
                    type = "private"
                    break
                case "company":
                    type = "company"
                    if(!e.target.elements.companyname?.value) {
                        setError("Bitte geben Sie den Namen der Firma ein")
                        return
                    }
                    break
                case "government":
                    type = "government"
                    if(!e.target.elements.companyname?.value) {
                        setError("Bitte geben Sie den Namen der staatlichen Institution ein")
                        return
                    }
                    break
                default:
                    setError("Bitte wählen Sie eine gültige Option")
                    return
            }
        }

        const member = {
            first_name: e.target.elements.firstname.value,
            last_name: e.target.elements.lastname.value,
            email: e.target.elements.emailaddress.value,
            password: e.target.elements.password.value,
            role,
            type,
            company_name: e.target.elements.companyname?.value
        }

        try {
            setIsLoading(true)
            const res = await signup(member)
            
            switch(res.status) {
                case 201:
                    setCreatedUser(res.data)
                    break
                case 422:
                    setError(res.data)
                    break
                case 409:
                    setError("Die E-Mail-Adresse, mit der Sie sich anmelden möchten, wird bereits verwendet")
                    break
                case 500:
                default:
                    if(res.data.message) {
                        switch(res.data.message) {
                            case 'EMAIL_ALREADY_TAKEN':
                                setError("Die E-Mail-Adresse, mit der Sie sich anmelden möchten, wird bereits verwendet")
                                break
                            case 'PASSWORD_TOO_SHORT':
                                setError("Das Passwort muss mindestens 8 Zeichen lang sein.")
                                break
                            case 'PASSWORD_INVALID':
                                setError("Das Passwort muss mindestens 1 Buchstaben und 1 Zahl enthalten.")
                                break
                            default:
                                setError(res.data.message)
                                break
                        }
                    }
                    else {
                        throw new Error()
                    }
            }
        }
        catch (e) {
            setError("Beim Erstellen Ihres Kontos ist ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es später erneut.")
        }

        setIsLoading(false)
    }

    return (
        <>
            <h4 className="mt-0">Konto erstellen</h4>
            { error && (
                <div className="alert alert-danger">{ error }</div>
            ) }
            { createdUser ? (
                <div>
                    <h2 className="mb-4">Nur noch ein Schritt</h2>
                    <p>
                        Es wurde eine Bestätigungs-E-Mail an die von Ihnen angegebene E-Mail-Adresse gesendet
                    </p>
                    <p>
                        Wenn Sie diese nicht innerhalb von 5 Minuten erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner
                    </p>
                    <p>
                        Wenn Sie Ihr Konto bereits verifiziert haben, klicken Sie <Link to="/auth/login">hier</Link>, um sich anzumelden
                    </p>
                </div>
            ) : (
                <>
                    <form action="#" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="emailaddress" className="form-label">Email Addresse</label>
                            <input className="form-control" type="email" name="emailaddress" id="emailaddress" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="customer-type" className="form-label">Ich bin:</label>
                            <select
                                className="form-select"
                                id="customer-type"
                                name="customer-type"
                                value={customerType}
                                onChange={customerTypeChangeHandle}
                            >
                                <option>- Bitte auswählen -</option>
                                <option value="customer">Kunde: Ich brauche Übersetzungen</option>
                                <option value="translator">Dolmetscher:in / Übersetzer:in</option>
                                <option value="school">Sprachschule</option>
                            </select>
                        </div>
                        { customerType === "customer" && (
                            <div className="mb-3">
                                <select 
                                    className="form-select" 
                                    id="customer-subtype"
                                    name="customer-subtype" 
                                    value={customerSubtype} 
                                    onChange={customerSubtypeChangeHandle}
                                >
                                    <option>- Bitte auswählen -</option>
                                    <option value="private">Privatkunde</option>
                                    <option value="company">Firmenkunde</option>
                                    <option value="government">Staatliche Institution</option>
                                </select>
                            </div>
                        ) }
                        { (customerType === "school" || ['company', 'government'].includes(customerSubtype)) && (
                            <div className="mb-3">
                                <label htmlFor="companyname" className="form-label">
                                    { customerType === "school" && "Name der Schule" }
                                    { customerSubtype === "company" && "Name der Firma" }
                                    { customerSubtype === "government" && "Name der staatlichen Institution" }
                                </label>
                                <input className="form-control" id="companyname" name="companyname" required />
                            </div>
                        )}
                        <div className="mb-3 row">
                            <div className="col-sm-6">
                                <label htmlFor="firstname" className="form-label">Vorname</label>
                                <input className="form-control" type="name" id="firstname" name="firstname" required />
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="firstname" className="form-label">Nachname</label>
                                <input className="form-control" type="name" id="lastname" name="lastname" required />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Kennwort</label>
                            <input className="form-control" type="password" required id="password" name="password" />
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="accept-terms" required />
                                <label className="form-check-label" htmlFor="accept-terms">Ich habe die AGBs gelesen und bin einverstanden</label>
                            </div>
                        </div>
                        <div className="d-grid mb-0 text-center">
                            <button className="btn btn-primary" type="submit" disabled={isLoading}><i className="mdi mdi-login"></i> Konto erstellen </button>
                        </div>
                    </form>

                    <footer className="footer footer-alt">
                        <p className="text-muted">Haben Sie schon ein Konto? <Link to="/auth/login" className="text-muted ms-1"><b>Einloggen</b></Link></p>
                    </footer>
                </>
            ) }
        </>
    )
}

export default Register