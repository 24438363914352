// @ts-nocheck
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';

import { getRequest } from "clients/ApiClient"
import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"

const getNewLanguage = () => ({
    key: '',
    value: ''
})

const getNewTaskType = () => ({
    key: '',
    value: ''
})

const getNewLanguagePricePerWord = () => ({
    language_key: '',
    price: 0,
})

const getNewTaskTypePrice = () => ({
    task_type_key: '',
    price: 0,
})

const getNewAppSettings = () => ({
    id: '',
    version: 1,
    current_task_id: 1,
    languages: [],
    task_types: [],
    is_automatic_task_calculation_enabled: false,
})

const AdminAppSettingsController = (props) => {
    const authContext = useAuth()
    const { appSettings, setAppSettings } = useAppSettings()

    const [error, setError] = useState()
    const [validationErrors, setValidationErrors] = useState({})
    const [showTab, setShowTab] = useState('general')

    const [languageToConfirmDeletion, setLanguageToConfirmDeletion] = useState(undefined)
    
    const handleSubmitForm = async (e) => {
        e.preventDefault()
        try {
            
            const res = await authContext.authPatchRequest("/settings/app-settings", appSettings)

            if(!res.data.code) {
                toast.success("Die Systemeinstellungen wurden aktualisiert")
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                    default:
                    case 500:
                        throw new Error('Beim Laden der Systemeinstellungen ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }

    const updateAppSettings = (updates) => {
        setAppSettings((settings) => ({
            ...settings,
            ...updates,
        }))
    }
    

    const addLanguage = () => {
        setAppSettings((settings) => ({
            ...settings,
            languages: [
                ...settings.languages,
                getNewLanguage()
            ]
        }))
    }

    const updateLanguage = (i, updates) => {
        setAppSettings((settings) => ({
            ...settings,
            languages: settings.languages.map((language, u) => {
                if(u === i) { 
                    return {
                        ...language,
                        ...updates
                    }
                }
                else {
                    return language
                }
            })
        }))
    }

    const removeLanguage = (i) => {
        setAppSettings((settings) => ({
            ...settings,
            languages: settings.languages.filter((l, u) => u !== i)
        }))
    }
    
    const addTaskType = () => {
        setAppSettings((settings) => ({
            ...settings,
            task_types: [
                ...settings.task_types,
                getNewTaskType()
            ]
        }))
    }

    const updateTaskType = (i, updates) => {
        setAppSettings((settings) => ({
            ...settings,
            task_types: settings.task_types.map((task_type, u) => {
                if(u === i) { 
                    return {
                        ...task_type,
                        ...updates
                    }
                }
                else {
                    return task_type
                }
            })
        }))
    }

    const removeTaskType = (i) => {
        setAppSettings((settings) => ({
            ...settings,
            task_types: settings.task_types.filter((l, u) => u !== i)
        }))
    }

    const updateHomescreenContent = (updates) => {
        setAppSettings((settings) => {
            const homescreen_quote = settings.homescreen_quote || {
                title: "",
                quote: "",
                author: ""
            }

            return {
                ...settings,
                homescreen_quote: {
                    ...homescreen_quote,
                    ...updates
                }
            }
        })
    }

    // Language price
    const addLanguagePricePerWord = () => {
        setAppSettings((settings) => ({
            ...settings,
            language_prices_per_word: [
                ...settings.language_prices_per_word,
                getNewLanguagePricePerWord()
            ]
        }))
    } 

    const updateLanguagePricePerWord = (i, updates) => {
        setAppSettings((settings) => ({
            ...settings,
            language_prices_per_word: settings.language_prices_per_word.map((language_price_per_word, u) => {
                if(u === i) { 
                    return {
                        ...language_price_per_word,
                        ...updates
                    }
                }
                else {
                    return language_price_per_word
                }
            })
        }))
    }

    const removeLanguagePricePerWord = (i) => {
        setAppSettings((settings) => ({
            ...settings,
            language_prices_per_word: settings.language_prices_per_word.filter((l, u) => u !== i)
        }))
    }

    // Task type price
    const addTaskTypePrice = () => {
        setAppSettings((settings) => ({
            ...settings,
            task_types_price: [
                ...settings.task_types_price,
                getNewTaskTypePrice()
            ]
        }))
    }

    const updateTaskTypePrice = (i, updates) => {
        setAppSettings((settings) => ({
            ...settings,
            task_types_price: settings.task_types_price.map((task_type_price, u) => {
                if(u === i) { 
                    return {
                        ...task_type_price,
                        ...updates
                    }
                }
                else {
                    return task_type_price
                }
            })
        }))
    }

    const removeTaskTypePrice = (i) => {
        setAppSettings((settings) => ({
            ...settings,
            task_types_price: settings.task_types_price.filter((l, u) => u !== i)
        }))
    }

    const confirmRemoveLanguage = (i) => {
        setLanguageToConfirmDeletion(i)
    }

    const clearRemoveLanguage = () => {
        setLanguageToConfirmDeletion(undefined)
    }

    return {
        appSettings,
        error,
        handleSubmitForm,
        validationErrors,
        showTab, 
        setShowTab,
        updateAppSettings,
        addLanguage,
        updateLanguage,
        removeLanguage,
        addTaskType,
        updateTaskType,
        removeTaskType,
        updateHomescreenContent,
        addLanguagePricePerWord,
        updateLanguagePricePerWord,
        removeLanguagePricePerWord,
        addTaskTypePrice,
        updateTaskTypePrice,
        removeTaskTypePrice,
        confirmRemoveLanguage,
        languageToConfirmDeletion,
        clearRemoveLanguage,
    }
}

export default AdminAppSettingsController