// @ts-nocheck
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, withRouter, useHistory } from "react-router-dom"
import React, { useState, useEffect } from "react"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IncomingCalls from "components/Layout/IncomingCalls";

import { useAuth } from "contexts/AuthContext"

const NavItem = withRouter((props) => {
    const { to, children, location: { pathname: currentRoute } } = props
    const isActive = currentRoute.substr(0, to.length) === to

    return (
        <li className={ `side-nav-item ${isActive ? "menuitem-active" : ""}` }>
            <Link to={ to } className={ `side-nav-link ${isActive ? "active" : ""}` }>
                { children }
            </Link>
        </li>
    )
})

const Layout = ({ children }) => {
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
    const [isNotificationListOpen, setIsNotificationListOpen] = useState(false)
    const [unreadChats, setUnreadChats] = useState(0)
    const [incomingCalls, setIncomingCalls] = useState([])
    const [notifications, setNotifications] = useState([])
    const [isConfirmingLogout, setIsConfirmingLogout] = useState(false)
    const { currentUser, logout, setActiveProfile, authGetRequest, authPostRequest } = useAuth()
    const history = useHistory()

    const toggleCondensedSidebar = () => {
        const body = document.querySelector("body")

        if(window.innerWidth > 768) {
            if(body.getAttribute("data-leftbar-compact-mode") === "condensed") {
                body.setAttribute("data-leftbar-compact-mode", "")
            }
            else {
                body.setAttribute("data-leftbar-compact-mode", "condensed")
            }
        } else {
            body.setAttribute("data-leftbar-compact-mode", "")

            if(body.classList.contains("sidebar-enable")) {
                body.classList.remove("sidebar-enable")
            }
            else {
                body.classList.add("sidebar-enable")
            }
        }
    }

    const handleOpenProfileMenu = (e) => {
        e.preventDefault()
        setIsProfileMenuOpen(true)
    }

    const hideDropdownsEvent = (e) => {
        const profileMenu = document.querySelector("#profile-menu")
        if(profileMenu && !profileMenu.contains(e.target)) {
            setIsProfileMenuOpen(false)
        }
        const notificationList = document.querySelector("#notification-list")
        if(notificationList && !notificationList.contains(e.target)) {
            setIsNotificationListOpen(false)
        }

        const sidebar = document.querySelector(".leftside-menu")
        const sidebarToggleButton = document.querySelector(".button-menu-mobile")
        if(sidebar && !sidebar.contains(e.target) && sidebarToggleButton && !sidebarToggleButton.contains(e.target)) {
            document.querySelector("body")?.classList.remove("sidebar-enable")
        }
    }

    const handleLogoutConfirm = (e) => {
        e.preventDefault()
        setIsConfirmingLogout(true)
    }

    const doLogout = async () => {
        await logout()
        setIsConfirmingLogout(false)
        history.push("/auth/logout")
    }

    const handleSearchSubmit = async (e) => {
        e.preventDefault()
        const keywords = e.target.top_search.value
        history.push(`/customer/translators?q=${keywords}&page=1&limit=10&sortBy=entry_date&showTranslators=1&showInterpreters=1&showSchools=1&langs=`)
    }

    const refreshAccountUpdates = async () => {
        try {
            const updates = await authGetRequest("/my/account-updates")
            if(!updates.data.code) {
                setUnreadChats(updates.data.unreadChats)
                setIncomingCalls(updates.data.incomingCalls)
                setNotifications(updates.data.notifications)
            }
        } catch(e) {
            console.error("There was an error loading account updates", e)
        }
        // e.preventDefault()
        // await logout()
        // history.push("/auth/logout")
    }

    const markNotificationAsSeen = (notification) => {
        authPostRequest(`/my/notifications/${notification.id}/mark-as-seen`)
    }

    const getUserType = (profile) => {
        if(profile.role === "admin") {
            return "Administrator"
        }
        if(profile.role === "translator") {
            return "Dolmetscher:in / Übersetzer:in"
        }
        if(profile.type === "school") {
            return "Sprachschule"
        }
        if(profile.type === "company") {
            return "Firmenkunde"
        }
        if(profile.type === "government") {
            return "Staatliche Institution"
        }

        return "Kunde"
    }

    const notificationAge = (notification) => {
        const secsAgo = (num) => {
            return `Vor ${num} Sekunde${num !== 1 ? "n" : ""}`
        }
        const minsAgo = (num) => {
            return `Vor ${num} Minute${num !== 1 ? "n" : ""}`
        }
        const hoursAgo = (num) => {
            return `Vor ${num} Stunde${num !== 1 ? "n" : ""}`
        }
        const daysAgo = (num) => {
            return `Vor ${num} Tag${num !== 1 ? "en" : ""}`
        }
        const monthsAgo = (num) => {
            return `Vor ${num} Monat${num !== 1 ? "en" : ""}`
        }
        const yearsAgo = (num) => {
            return `Vor ${num} Jahr${num !== 1 ? "en" : ""}`
        }

        const date = new Date(notification.notification_date)
        const seconds = Math.floor((new Date() - date) / 1000);
          
        let interval = seconds / 31536000;
        
        if (interval > 1) {
            return yearsAgo(Math.floor(interval))
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return monthsAgo(Math.floor(interval))
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return daysAgo(Math.floor(interval))
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return hoursAgo(Math.floor(interval))
        }
        interval = seconds / 60;
        if (interval > 1) {
            return minsAgo(Math.floor(interval))
        }
        return secsAgo(Math.floor(seconds))
    }

    useEffect(() => {
        document.addEventListener("click", hideDropdownsEvent)
        let interval = setInterval(refreshAccountUpdates, 2000)

        return () => {
            document.removeEventListener("click", hideDropdownsEvent)
            clearInterval(interval)
        }
    }, [])

    return (
        <div className="wrapper">
            <div className="leftside-menu">

                <Link to="/" className="logo text-center logo-light" style={{ padding: "20px 0" }}>
                    <span className="logo-lg">
                        <img src="/assets/images/logo.png" alt="" height="60" />
                    </span>
                    <span className="logo-sm">
                        <img src="/assets/images/logo.png" alt="" height="30" />
                    </span>
                </Link>
    
                <div className="h-100" id="leftside-menu-container" data-simplebar style={{ marginTop: "30px" }}>
                    <ul className="side-nav">
                        { currentUser.activeProfile.role === "admin" && (
                            <>
                                <li className="side-nav-title side-nav-item">Navigation</li>
                                <NavItem to="/admin/dashboard">
                                    <i className="uil-home-alt"></i>
                                    <span>Dashboard </span>
                                </NavItem>
                                <NavItem to="/admin/calendar">
                                    <i className="uil-calendar-alt"></i>
                                    <span>Kalender </span>
                                </NavItem>
                                <li className="side-nav-title side-nav-item">Verwaltung</li>
                                <NavItem to="/admin/tasks">
                                    <i className="uil-clock-nine"></i>
                                    <span>Aufträge </span>
                                </NavItem>
                                <NavItem to="/admin/users">
                                    <i className="uil-user"></i>
                                    <span>Kontakte </span>
                                </NavItem>
                                <NavItem to="/admin/app-settings">
                                    <i className="uil-cog"></i>
                                    <span>Systemeinstellungen </span>
                                </NavItem>
                                <NavItem to="/admin/messenger">
                                    <i className="uil-comments-alt"></i>
                                    { unreadChats > 0 && (
                                        <span className="badge bg-primary float-end">{ unreadChats }</span>
                                    ) }
                                    <span>Nachrichten </span>
                                </NavItem>
                                <li className="side-nav-title side-nav-item">Mein Profil</li>
                                <li className="side-nav-item">
                                    <a href="#" className="side-nav-link" onClick={ handleLogoutConfirm }>
                                        <i className="uil-lock-alt"></i>
                                        <span>Abmelden </span>
                                    </a>
                                </li>
                            </>
                        ) }
                        { currentUser.activeProfile.role === "customer" && (
                            <>
                                <li className="side-nav-title side-nav-item">Navigation</li>
                                <NavItem to="/customer/dashboard">
                                    <i className="uil-home-alt"></i>
                                    <span>Dashboard </span>
                                </NavItem>
                                <NavItem to="/customer/calendar">
                                    <i className="uil-calendar-alt"></i>
                                    <span>Kalender </span>
                                </NavItem>
                                <li className="side-nav-title side-nav-item">Apps</li>
                                <NavItem to="/customer/my-tasks">
                                    <i className="uil-clock-nine"></i>
                                    <span>Meine Aufträge </span>
                                </NavItem>
                                <NavItem to="/customer/messenger">
                                    <i className="uil-comments-alt"></i>
                                    { unreadChats > 0 && (
                                        <span className="badge bg-primary float-end">{ unreadChats }</span>
                                    ) }
                                    <span>Nachrichten </span>
                                </NavItem>
                                <li className="side-nav-item">
                                    <a href="#" className="side-nav-link" onClick={ handleLogoutConfirm }>
                                        <i className="uil-lock-alt"></i>
                                        <span>Abmelden </span>
                                    </a>
                                </li>
                            </>
                        ) }
                        { currentUser.activeProfile.role === "translator" && (
                            <>
                                <li className="side-nav-title side-nav-item">Navigation</li>
                                <NavItem to="/translator/dashboard">
                                    <i className="uil-home-alt"></i>
                                    <span>Dashboard </span>
                                </NavItem>
                                <NavItem to="/translator/calendar">
                                    <i className="uil-calendar-alt"></i>
                                    <span>Kalender </span>
                                </NavItem>
                                <li className="side-nav-title side-nav-item">Apps</li>
                                <NavItem to="/translator/my-tasks">
                                    <i className="uil-clock-nine"></i>
                                    <span>Meine Aufträge </span>
                                </NavItem>
                                <NavItem to="/translator/messenger">
                                    <i className="uil-comments-alt"></i>
                                    { unreadChats > 0 && (
                                        <span className="badge bg-primary float-end">{ unreadChats }</span>
                                    ) }
                                    <span>Nachrichten </span>
                                </NavItem>
                                <NavItem to="/translator/tasks">
                                    <i className="uil-bill"></i>
                                    <span>Aufträge </span>
                                </NavItem>
                                <li className="side-nav-item">
                                    <a href="#" className="side-nav-link" onClick={ handleLogoutConfirm }>
                                        <i className="uil-lock-alt"></i>
                                        <span>Abmelden </span>
                                    </a>
                                </li>
                            </>
                        ) }

                    </ul>
                    <div className="clearfix"></div>
                </div>
            </div>

            
            <div className="content-page">
                <div className="content">
                    <div className="navbar-custom">
                        <ul className="list-unstyled topbar-menu float-end mb-0">
                            { currentUser.activeProfile.role === 'customer' && (
                                <li className="notification-list">
                                    <span className="nav-link">
                                        <Link to="/customer/my-tasks/new/translation" className="btn btn-dark btn-sm mt-2 d-flex align-items-center" style={ { height: '45px' } }>
                                            Auftrag / Anfrage erstellen
                                        </Link>
                                    </span>
                                </li>
                            ) }
                            <li className="dropdown notification-list d-lg-none">
                                <a className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="dripicons-search noti-icon"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
                                    <form className="p-3">
                                        <input type="text" className="form-control" placeholder="Suchen..." />
                                    </form>
                                </div>
                            </li>
                            <li className="dropdown notification-list" id="notification-list">
                                <a 
                                    className="nav-link dropdown-toggle arrow-none"
                                    data-bs-toggle="dropdown"
                                    href="#" role="button" aria-haspopup="false" aria-expanded="false"
                                    onClick={((e) => {
                                        e.preventDefault()
                                        setIsNotificationListOpen(true)
                                    })}
                                >
                                    <i className="dripicons-bell noti-icon"></i>
                                    { notifications.length > 0 && (
                                        <span className="noti-icon-badge"></span>
                                    ) }
                                </a>
                                { isNotificationListOpen && (
                                    <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg show">
                                        <div className="dropdown-item noti-title">
                                            <h5 className="m-0">
                                                Benachrichtigungen
                                            </h5>
                                            { notifications.length === 0 && (
                                                <div className="notify-details">
                                                    Keine Benachrichtigungen
                                                </div>
                                            ) }
                                        </div>

                                        <div style={{ maxHeight: "230px" }} data-simplebar>
                                            { notifications.length > 0 && notifications.map((notification) => (
                                                <Link
                                                    to={notification.link}
                                                    className="dropdown-item notify-item"
                                                    key={notification.id}
                                                    onClick={() => markNotificationAsSeen(notification)}
                                                >
                                                    <div className="notify-icon bg-primary">
                                                        <i className="uil-clock-nine"></i>
                                                    </div>
                                                    <p className="notify-details">
                                                        { notification.title }
                                                        <small className="text-muted">{ notificationAge(notification) }</small>
                                                    </p>
                                                </Link>
                                            )) }
                                        </div>
                                    </div>
                                ) }
                            </li>


                            <li className="dropdown notification-list" id="profile-menu">
                                <a 
                                    className="nav-link dropdown-toggle nav-user arrow-none me-0" 
                                    href="#"
                                    onClick={ handleOpenProfileMenu }
                                >
                                    <span className="account-user-avatar"> 
                                        <img
                                            src={currentUser.activeProfile.profile_picture ? currentUser.activeProfile.profile_picture : "/assets/images/users/avatar-0.png"}
                                            alt="user-image"
                                            className="rounded-circle"
                                        />
                                    </span>
                                    <span>
                                        <span className="account-user-name">{ `${currentUser.first_name} ${currentUser.last_name}` }</span>
                                        <span className="account-position">{ getUserType(currentUser.activeProfile) }</span>
                                    </span>
                                </a>
                                <div className={ `dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown ${isProfileMenuOpen ? "show" : ""}`}>
                                    <div className=" dropdown-header noti-title">
                                        <h6 className="text-overflow m-0">Profile</h6>
                                    </div>
                                    { currentUser.profiles.filter(p => p.is_active_profile).map((profile) => (
                                        <span className="dropdown-item" key={ profile._id }>
                                            Aktuell: <strong>{ getUserType(profile) }</strong>
                                        </span>
                                    )) }
                                    { currentUser.profiles.filter(p => !p.is_active_profile).map((profile) => (
                                        <a
                                            href="#"
                                            onClick={ (e) => {
                                                e.preventDefault()
                                                setActiveProfile(profile)
                                            } }
                                            className="dropdown-item notify-item"
                                            key={ profile._id }
                                        >
                                            Wechseln zu:  <strong>{ getUserType(profile) }</strong>
                                        </a> 
                                    )) }


                                    <div className=" dropdown-header noti-title">
                                        <h6 className="text-overflow m-0">Mein Konto</h6>
                                    </div>

                                    { ['customer', 'translator'].includes(currentUser.activeProfile.role) && (
                                        <Link to={ `/${currentUser.activeProfile.role}/my-profile` } className="dropdown-item notify-item">
                                            <i className="mdi mdi-account-circle me-1"></i>
                                            <span>Mein Profil</span>
                                        </Link>
                                    ) }

                                    { ['customer', 'translator', 'admin'].includes(currentUser.activeProfile.role) && (
                                        <Link to={ `/${currentUser.activeProfile.role}/settings` } className="dropdown-item notify-item">
                                            <i className="mdi mdi-account-edit me-1"></i>
                                            <span>Einstellungen</span>
                                        </Link>
                                    ) }

                                    <a
                                        href="#"
                                        className="dropdown-item notify-item"
                                        onClick={ handleLogoutConfirm }
                                    >
                                        <i className="mdi mdi-logout me-1"></i>
                                        <span>Abmelden</span>
                                    </a>
                                </div>
                            </li>

                        </ul>
                        <button
                            className="button-menu-mobile open-left"
                            onClick={ toggleCondensedSidebar }
                        >
                            <i className="mdi mdi-menu"></i>
                        </button>
                        { currentUser.activeProfile.role === 'customer' && (
                            <div className="app-search dropdown d-none d-lg-block">
                                <form onSubmit={handleSearchSubmit}>
                                    <div className="input-group">
                                        <input type="text" className="form-control dropdown-toggle"  placeholder="Suchen..." id="top-search" name="top_search" />
                                        <span className="mdi mdi-magnify search-icon"></span>
                                        <button className="input-group-text btn-primary" type="submit">suchen</button>
                                    </div>
                                </form>
                            </div>
                        ) }
                    </div>
                    <ToastContainer />
                    <IncomingCalls incomingCalls={incomingCalls} />
                    <div className="container-fluid">
                        { children }
                    </div>

                </div>
            </div>

            { isConfirmingLogout && (
                <div className="popup-backdrop">
                    <div className="popup">
                        <p>
                            Wollen Sie sich wirklich abmelden?
                        </p>
                        <div className="text-end">
                            <button
                                className="btn btn-primary me-1"
                                onClick={doLogout}
                            >Abmelden</button>
                            <button
                                className="btn btn-light"
                                onClick={() => setIsConfirmingLogout(false)}
                            >Abbrechen</button>
                        </div>
                    </div>
                </div>
            ) }

        </div>
    )
}

export default Layout