// @ts-nocheck
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "contexts/AuthContext"

const AdminRoute = ({ render, ...rest }) => {
    const { currentUser } = useAuth()

    return (
        <Route
            {...rest}
            render={ () => {
                if(!currentUser) {
                    return <Redirect to="/auth/login" />
                }
                if(currentUser.activeProfile.role !== "admin") {
                    return <Redirect to="/" />
                }

                return render()
            } }
        />
    )
}

export default AdminRoute