// @ts-nocheck
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "contexts/AuthContext"

const AnonymousRoute = ({ render, ...rest }) => {
    const { currentUser } = useAuth()

    return (
        <Route
            {...rest}
            render={ () => {
                if(currentUser) {
                    return <Redirect to="/" />
                }

                return render()
            } }
        />
    )
}

export default AnonymousRoute