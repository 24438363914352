// @ts-nocheck
import {
    useParams,
} from "react-router-dom";
  
import Task from 'components/Task'

const TranslatorTaskView = () => {
    const { id } = useParams()

    return (
        <Task
            id={id}
            view="translator"
        />
    )
}

export default TranslatorTaskView