import moment from 'moment'

export const apiToDisplayDate = (apiDate: string): string => {
    if(!apiDate) {
        return ""
    }
    return moment(new Date(apiDate)).format('YYYY-MM-DD')
}

export const apiToUiDate = (apiDate: string): string => {
    if(!apiDate) {
        return ""
    }
    return moment(new Date(apiDate)).format('DD.MM.YYYY')
}

export const displayToApiDate = (displayDate: string): string | null => {
    if(!displayDate) {
        return null
    }
    return moment(new Date(displayDate)).format('YYYY-MM-DD[T00:00:00.000Z]')
}

export const formatDateForApi = (date: string): string => {
    return moment(date).format('YYYY-MM-DD[T00:00:00.000Z]')
}

export const isToday = (apiDate: string): string | boolean => {
    if(!apiDate) {
        return false
    }

    return moment(new Date(apiDate)).isSame(moment(), 'day');
}

export const isPast = (apiDate: string): string | boolean => {
    if(!apiDate) {
        return false
    }

    return moment(new Date(apiDate)).isBefore(moment(), 'day');
}

export const isWithinDays = (apiDate: string, days: number): string | boolean => {
    if(!apiDate) {
        return false
    }

    const diff = moment(0, "HH").diff(new Date(apiDate), "days")

    return diff < 0 && (0 - diff) <= days
}