// @ts-nocheck
import AdminDashboardController from 'screens/admin/AdminDashboard/AdminDashboard.controller'
import AdminDashboardSkeleton from 'screens/admin/AdminDashboard/AdminDashboard.skeleton'

import TaskStatusShareChart from 'components/Dashboard/TaskStatusShareChart'
import RevenueChart from 'components/Dashboard/RevenueChart'

const AdminDashboard = () => {
    const {
        metrics,
        isLoading,
        error,
    } = AdminDashboardController()

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <h4 className="page-title"><strong className="text-black">Verwaltung</strong> / Dashboard</h4>
                    </div>
                </div>
            </div>

            {/* end page title */}
            { !metrics && (isLoading || error) && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {isLoading && <AdminDashboardSkeleton />}
                                {error && (
                                    <div>{error}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) }
            { metrics && (
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title mb-4">Umsatz (soll und ist)</h4>
                                    <RevenueChart
                                        data={metrics.revenue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title mb-4">Status der Aufträge</h4>
                                    <TaskStatusShareChart data={[
                                        { name: 'Eingegangen', value: metrics.draft },
                                        { name: 'Offen', value: metrics.open },
                                        { name: 'In Bearbeitung', value: metrics.in_progress },
                                        { name: 'Abgeschlossen', value: metrics.completed },
                                        { name: 'Storniert', value: metrics.cancelled },
                                    ]} view="admin" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row g-0">
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-bottom">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-checklist text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.total }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Aufträge (gesamt)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-start border-bottom">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-enter text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.draft }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Eingegangen
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-start border-bottom">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-briefcase text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.open }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Offen
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-0">
                                        <div className="col-4">
                                            <div className="card shadow-none m-0">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-document-edit text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.in_progress }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        In Bearbeitung
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-start">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-exit text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.completed }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Abgeschlossen
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-start">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-document-delete text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.cancelled }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Storniert
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <div className="row g-0">
                                            <div className="col-sm-4 col-xl-2">
                                                <div className="card shadow-none m-0">
                                                    <div className="card-body text-center">
                                                        <i
                                                            className="dripicons-user text-muted"
                                                            style={{ fontSize: "24px" }}
                                                        />
                                                        <h3>
                                                            <span>{ metrics.total_users }</span>
                                                        </h3>
                                                        <p className="text-muted font-15 mb-0">
                                                            Nutzer (gesamt)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-xl-2">
                                                <div className="card shadow-none m-0 border-start">
                                                    <div className="card-body text-center">
                                                        <i
                                                            className="dripicons-user text-muted"
                                                            style={{ fontSize: "24px" }}
                                                        />
                                                        <h3>
                                                            <span>{ metrics.new_users }</span>
                                                        </h3>
                                                        <p className="text-muted font-15 mb-0">
                                                            Neue Nutzer (diesen Monat)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-xl-2">
                                                <div className="card shadow-none m-0 border-start">
                                                    <div className="card-body text-center">
                                                        <i
                                                            className="dripicons-user text-muted"
                                                            style={{ fontSize: "24px" }}
                                                        />
                                                        <h3>
                                                            <span>{ metrics.new_users_last_month }</span>
                                                        </h3>
                                                        <p className="text-muted font-15 mb-0">
                                                            Neue Nutzer (letzten Monat)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-xl-2">
                                                <div className="card shadow-none m-0 border-start">
                                                    <div className="card-body text-center">
                                                        <i
                                                            className="dripicons-checklist text-muted"
                                                            style={{ fontSize: "24px" }}
                                                        />
                                                        <h3>
                                                            <span>{ metrics.new_tasks }</span>
                                                        </h3>
                                                        <p className="text-muted font-15 mb-0">
                                                            Erstellte Aufträge (diesen Monat)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-xl-2">
                                                <div className="card shadow-none m-0 border-start">
                                                    <div className="card-body text-center">
                                                        <i
                                                            className="dripicons-graph-line text-muted"
                                                            style={{ fontSize: "24px" }}
                                                        />
                                                        <h3>
                                                            <span>{ metrics.total_revenue_owed } €</span>
                                                        </h3>
                                                        <p className="text-muted font-15 mb-0">
                                                            Gesamten Umsatz (soll)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-xl-2">
                                                <div className="card shadow-none m-0 border-start">
                                                    <div className="card-body text-center">
                                                        <i
                                                            className="dripicons-graph-line text-muted"
                                                            style={{ fontSize: "24px" }}
                                                        />
                                                        <h3>
                                                            <span>{ metrics.total_revenue_paid } €</span>
                                                        </h3>
                                                        <p className="text-muted font-15 mb-0">
                                                            Gesamten Umsatz (ist)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) }
        </>
    )
}

export default AdminDashboard