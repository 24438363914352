// @ts-nocheck
import { Link } from "react-router-dom"

import TranslatorTasksController from 'controllers/TranslatorTasks.controller'
import TranslatorTasksSkeleton from 'screens/skeletons/TranslatorTasks.skeleton'

import { apiToUiDate } from 'utils/datetime'

const TranslatorTasks = (props) => {
    
    const {
        tasks,
        isLoading,
        error,
        languages,
        taskTypes,
        buildLink,
        searchCriteria,
        setSearchCriteria,
        handleUpdateSearch,
    } = TranslatorTasksController(props)

    return (
        <div>
            { isLoading && <TranslatorTasksSkeleton /> }
            { error && (
                <div>{error}</div>
            ) }
            { tasks && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title">Tasks</h4>
                            </div>
                        </div>
                    </div>
                    <div className="alert alert-info mb-3">
                        Es werden nur Aufträge angezeigt, mit den Sprachen, die Sie können
                    </div>
                    {/* end page title */}
                    <div className="row">
                        <div className="col-3">
                            <div className="card">
                                <div className="card-body">
                                    <form action="#" onSubmit={ handleUpdateSearch }>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label
                                                        htmlFor="task_id"
                                                        className="form-label"
                                                    >
                                                        Suchkriterien
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="task_id"
                                                        className="form-control"
                                                        value={ searchCriteria.q }
                                                        onChange={ (e) => { 
                                                            setSearchCriteria((prev) => ({
                                                                ...prev,
                                                                q: e.target.value || ""
                                                            }))
                                                        } }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                <label
                                                        htmlFor="task-type"
                                                        className="form-label"
                                                    >
                                                        Dokument-Typ
                                                    </label>
                                                    <select
                                                        className="form-select"
                                                        id="task-type"
                                                        value={searchCriteria.type}
                                                        onChange={(e) => { 
                                                            setSearchCriteria((prev) => ({
                                                                ...prev,
                                                                type: e.target.value
                                                            }))
                                                        }}
                                                    >
                                                        <option value="all">Alle</option>
                                                        { taskTypes.map(({key, value}) => (
                                                            <option key={key} value={key}>
                                                                { value }
                                                            </option>
                                                        )) }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <button 
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >Suchen</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            { tasks.results.length > 0 ? (
                                <>
                                    { tasks.results.map((task) => {
                                        return (
                                            <div className="card text-left" key={ task._id }>
                                                <div className="card-body">
                                                    <Link to={ `/translator/tasks/${task._id}` } className="text-secondary">
                                                        <div className="row align-items-center">
                                                            <div>
                                                                <h4 className="mb-0 mt-2">{ task.title }</h4>
                                                                <div className="font-13">
                                                                    Erstellt: { apiToUiDate(task.entry_date) }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            { task.description }
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-6 mb-3">
                                                            <strong>Ausgangssprachen: </strong><br />
                                                            {task.task_type === "translation" && (
                                                                <>
                                                                    { [...new Set(task.files.map(f => f.source_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') }
                                                                </>
                                                            )}
                                                            {["interpretation", "lesson"].includes(task.task_type) && (
                                                                <>
                                                                    { [...new Set(task.interpretations.map(f => f.source_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') }
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <strong>Zielsprachen: </strong><br />
                                                            {task.task_type === "translation" && (
                                                                <>
                                                                    { [...new Set(task.files.map(f => f.target_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') }
                                                                </>
                                                            )}
                                                            {["interpretation", "lesson"].includes(task.task_type) && (
                                                                <>
                                                                    { [...new Set(task.interpretations.map(f => f.target_language).filter(l => l))].map(f => languages.find(l => l.key === f)?.value).join(' - ') }
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6 mb-3">
                                                            <strong>Auftragsgeber:</strong><br />
                                                            <Link to={ `/translator/customers/${task.customer.id}` } className="text-secondary">
                                                                { task.customer.name }{" "}
                                                                { task.customer.role && (
                                                                    <span className="text-muted font-12">({ task.customer.role })</span>
                                                                ) }
                                                            </Link>
                                                        </div>
                                                        <div className="col-6 mb-3">
                                                            <strong>Dokument-Typ:</strong><br />
                                                            {task.task_type === "translation" && (
                                                                <>
                                                                    { [...new Set(task.files.map(f => f.doc_type).filter(l => l))].map(f => taskTypes.find(l => l.key === f)?.value).join(' - ') }
                                                                </>
                                                            )}
                                                            {task.task_type === "interpretation" && (
                                                                <>
                                                                    Dolmetschen
                                                                </>
                                                            )}
                                                            {task.task_type === "lesson" && (
                                                                <>
                                                                    Sprachunterricht
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) }
                                    <div>
                                        <nav>
                                            <ul className="pagination mb-0 mt-3">
                                                { tasks.page > 1 && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={ buildLink({ page: tasks.page - 1 }) }
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">«</span>
                                                        </Link>
                                                    </li>
                                                ) }
                                                { Array.from(Array(tasks.totalPages).keys()).map(i => i + 1).map((targetPage) => (
                                                    <li className={ `page-item ${targetPage !== tasks.page ? "" : "active text-dark"}` }>
                                                        <Link to={ buildLink({ page: targetPage }) } className="page-link">
                                                            { targetPage }
                                                        </Link>
                                                    </li>
                                                )) }
                                                { tasks.page < tasks.totalPages && (
                                                    <li className="page-item">
                                                        <Link
                                                            to={ buildLink({ page: tasks.page + 1 }) }
                                                            className="page-link"
                                                        >
                                                            <span aria-hidden="true">»</span>
                                                        </Link>
                                                    </li>
                                                ) }
                                            </ul>
                                        </nav>
                                    </div>
                                </>
                            ) : (
                                <div className="card text-center">
                                    <div className="card-body">
                                        <p>Es wurden keine Aufträge gefunden</p>
                                    </div>
                                </div>
                            ) }
                        </div>
                    </div>
                </>
            ) }
        </div>
    )
}

export default TranslatorTasks