// @ts-nocheck
const AppLoadingScreen = () => {
    return (
        <div style={{
            backgroundColor: '#f29400',
            width: "100%",
            height: "100vh",
            position: "absolute",
            left: 0,
            top: 0
        }} />
    )
}

export default AppLoadingScreen