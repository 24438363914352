// @ts-nocheck
import { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import { useAuth } from "contexts/AuthContext"

let timeout

const CustomerVerification = ({ match }) => {
    const [decision, setDecision] = useState()
    const { verifyAccount } = useAuth()
    const history = useHistory()

    const verificationCode = match.params.code

    const verifyAccountWithCode = async (code) => {
        const result = await verifyAccount(code)
        if(result === "SUCCESS") {
            setDecision("SUCCESS")
            timeout = setTimeout(() => {
                history.push("/")
            }, 5000)
        }
        else {
            setDecision("FAIL")
        }
    }

    const cancelTimeout = () => {
        if(timeout) {
            clearTimeout(timeout)
        }
    }

    useEffect(() => {
        verifyAccountWithCode(verificationCode)
    }, [verificationCode])

    if(!decision) {
        return "Es lädt..."
    }

    if(decision === "SUCCESS")  {
        return (
            <>
                <h4 className="mt-0">Ihr Konto wurde korrekt verifiziert.</h4>
                <p className="text-muted">
                    Sie werden in 5 Sekunden zu Ihrem Konto weitergeleitet. Sollte dies nicht der Fall sein, klicken Sie bitte <Link to="/" onClick={cancelTimeout}>hier</Link>
                </p>
            </>
        )
    }
    else {
        return (
            <>
                <h4 className="mt-0">Überprüfungsfehler</h4>
                <p className="text-muted mb-2">
                    Die von Ihnen angegebene Verifizierungs-URL ist ungültig. Wenn Sie gerade eine Bestätigungs-E-Mail erhalten haben, versuchen Sie es bitte erneut.
                </p>
                <p className="text-muted">
                    Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte
                </p>
            </>
        )
    }
}

export default CustomerVerification