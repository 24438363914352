// @ts-nocheck
import VideoCallController from 'controllers/VideoCall.controller'
import Room from "components/Video/Room";

const VideoCall = (props) => {

    const {
        videoCall,
        hangUp,
        room,
        isConnecting,
        error,
    } = VideoCallController(props)

    return (
        <div id="video-call-screen" className="bg-dark text-light">
            { error && (
                <div>{ error }</div>
            )}
            { !error && videoCall && videoCall.status === "PENDING" && (
                <div>
                    <h2 className="mb-5">{videoCall.user_2_name} wird angerufen</h2>
                </div>
            ) }
            { !error && videoCall && videoCall.status === "ACCEPTED" && (
                <div>
                    { isConnecting ? (
                        <div>Verbindung wird hergestellt...</div>
                    ) : room && (
                        <div>
                            <Room room={room} />
                        </div>
                    ) }
                </div>
            ) }
            <button
                className="call-hangup-button bg-danger text-white"
                onClick={ hangUp }
            >
                <i className="dripicons-cross" />
            </button>
        </div>
    )
}

export default VideoCall