// @ts-nocheck
import { useState } from "react"
import { Link } from "react-router-dom"
import { apiToUiDate } from 'utils/datetime'


const TaskBids = ({ task }) => {
    const [showBids, setShowBids] = useState(false)
    return (
        <>
            {task.bids.length === 0 ? "Noch keine Bewerbungen" : (
                <>
                    Bewerbungen: {task.bids.length}<br />
                    Höchste Bewerbung: {Math.max(...task.bids.map(b => b.price))} €<br /><br />
                    { showBids ? (
                        <>
                            <div className="card">
                                <div className="card-body">
                                    { task.bids.map((bid, i) => (
                                        <div key={i} className="mb-2 pb-2 border-bottom">
                                            <strong>Datum:</strong><br />
                                            { apiToUiDate(bid.bid_date) }<br /><br />
                                            <strong>Bewerber: </strong><br />
                                            <Link className="text-info" to={ `/customer/translators/${bid.translator.id}` }>
                                                {bid.translator.name} {bid.translator.email && `(${bid.translator.email})`}
                                            </Link><br /><br />
                                            <strong>Preis:</strong><br />
                                            {bid.price} €<br /><br />
                                            <strong>Bewerbungsschreiben</strong><br />
                                            {bid.description}
                                        </div>
                                    )) }
                                </div>
                            </div>
                            <button
                                onClick={ () => setShowBids(false) }
                                className="btn btn-light"
                            >Bewerbungen ausblenden</button>
                        </>
                    ) : (
                        <button
                            onClick={ () => setShowBids(true) }
                            className="btn btn-light"
                        >Bewerbungen anzeigen</button>
                    ) }
                </>
            )}
            
        </>
    )
}

export default TaskBids