// @ts-nocheck
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAppSettings } from "contexts/AppSettingsContext"

const GuestLayout = ({ children }) => {
    const appSettings = useAppSettings()
    const quote = appSettings.appSettings.homescreen_quote

    return (
        <div className="auth-fluid">
            <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">
                        <div className="auth-brand text-center text-lg-start">
                            <a href="/" className="logo-dark">
                                <span><img src="/assets/images/logo-dark.png" alt="" height="60" /></span>
                            </a>
                            <a href="index.html" className="logo-light">
                                <span><img src="/assets/images/logo-dark.png" alt="" height="18" /></span>
                            </a>
                        </div>
                        <ToastContainer />
                        { children }
                    </div> 
                </div> 
            </div>

            <div className="auth-fluid-right text-center">
                { quote && (
                    <div className="auth-user-testimonial">
                        { quote.title && (
                            <h2 className="mb-3">{ quote.title }</h2>
                        ) }
                        { quote.quote && (
                            <p className="lead">
                                <i className="mdi mdi-format-quote-open"></i> { quote.quote } <i className="mdi mdi-format-quote-close"></i>
                            </p>
                        ) }
                        { quote.author && (
                            <p>
                                - { quote.author }
                            </p>
                        ) }
                    </div>
                ) }
            </div>
        </div>
    )
}

export default GuestLayout