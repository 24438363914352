// @ts-nocheck
import { useState, useEffect } from 'react'
import {
    useParams,
    useHistory
  } from "react-router-dom";
import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"

import {
    processStaticSettingsObject,
    processAppSettingsLanguages,
} from 'utils/settings'

const getNewAddress = () => {
    return {
        address_street: '',
        address_zip: '',
        address_city: '',
        address_country: 'Deutschland',
    }
}

const getNewPersonalInformation = () => ({
    addresses: [],
    phones: [],
    faxes: [],
    emails: [],
    webs: [],
    iban: '',
    bic: '',
    tax_number: '',
    tax_id: '',
    is_zugferd: false,
    routing_number: '',
    paypal_address: '',
})

const getNewLanguage = () => {
    return {
        language: "",
        is_native: false,
    }
}

const getNewEducation = () => {
    return {
        title: "",
        description: ""
    }
}

const getNewExperience = () => {
    return {
        title: "",
        description: ""
    }
}

const getNewTranslatorProfile = () => ({
    description: '',
    is_translator: false,
    is_interpreter: false,
    keywords: [],
    languages: [],
    education: [],
    experience: [],
    fees_per_hour: undefined,
    travel_fees: undefined,
    travel_fees_per_trip: undefined,
    remuneration: undefined,
})

const getNewCustomerProfile = () => ({
    description: ''
})

const getNewProfile = (role) => {
    const object = {
        role,
        type: "",
        is_active_profile: false,
        customer_number: "",
        company_name: "",
        personal_information: getNewPersonalInformation(),
    };

    if(role === 'translator') {
        object.translator_profile = getNewTranslatorProfile()
    }

    if(role === 'customer') {
        object.customer_profile = getNewCustomerProfile()
    }

    return object;
}

const getNewUser = () => ({
    id: undefined,
    is_company: false,
    company_type: '',
    first_name: "",
    last_name: "",
    birth_name: "",
    company_name: "",
    email: undefined,
    date_of_birth: undefined,
    has_account: false,
    password: "",
    isEmailVerified: true,
    is_blocked: false,
    blocked_note: "",
    blocked_date: undefined,
    profiles: [
        {
            ...getNewProfile('customer'),
            is_active_profile: true,
        }
    ]
})

const fetchParentCompanies = async (user, setParentCompanies, authContext, setIsLoading, setError) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        const idMaps = user.profiles.map(p => ({
            role: p.role,
            id: p.company?.id
        })).filter(p => p.id)

        const parentCompanies = {}
        await Promise.all(idMaps.map(async (idMap) => {
            const company = await authGetRequest(`/admin/users/${idMap.id}`)

            if(!company.data.code) {
                parentCompanies[idMap.role] = {
                    user: company.data,
                    profile: company.data.profiles.find(p => p.role === idMap.role)
                }
            }
            else {
                switch(user.data.code) {
                    case 500:
                        throw new Error('Beim Laden des Kontakt ist ein Fehler aufgetreten')
                }
            }
        }))

        setParentCompanies(parentCompanies)
    }
    catch(e) {
        setError(e.message)
    }
    setIsLoading(false)
}

const fetchSubcontacts = async (userId, setSubcontacts, authContext, setIsLoading, setError) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        const user = await authGetRequest(`/admin/users?parent_id=${userId}`)

        if(!user.data.code) {
            setSubcontacts(user.data.results)
        }
        else {
            switch(user.data.code) {
                default:
                case 500:
                    throw new Error('Beim Laden des Kontakt ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setSubcontacts([])
        setError(e.message)
    }
    setIsLoading(false)
}

const fetchUser = async (userId, setUser, setSubcontacts, setParentCompanies, authContext, setIsLoading, setError) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        const user = await authGetRequest(`/admin/users/${userId}`)

        if(!user.data.code) {
            setUser(user.data)
            fetchSubcontacts(user.data.id, setSubcontacts, authContext, setIsLoading, setError)
            fetchParentCompanies(user.data, setParentCompanies, authContext, setIsLoading, setError)
        }
        else {
            switch(user.data.code) {
                case 404:
                case 400:
                    console.log(user)
                    throw new Error('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden des Kontakts ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setUser(undefined)
        setError(e.message)
    }
    setIsLoading(false)
}

const fetchCompanies = async (setCompanySuggestions, filter, authContext) => {
    
    const { authGetRequest } = authContext
    try {
        const user = await authGetRequest(`/admin/users?show_companies=1&sortBy=${filter.sortBy}&limit=${filter.limit}&page=${filter.page}&q=${filter.q}`)

        if(!user.data.code) {
            setCompanySuggestions(user.data.results)
        }
        else {
            switch(user.data.code) {
                default:
                case 500:
                    throw new Error('Beim Laden des Kontakts ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        console.log(e)
    }
}

function hasParentClass(child, classname){
    if(child.className.split(' ').indexOf(classname) >= 0) return true;
    try {
        return child.parentNode && hasParentClass(child.parentNode, classname);
    } catch(TypeError){
        return false;
    }
}

const UserController = (props) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()

    const [user, setUser] = useState()
    const [oldState, setOldState] = useState()
    const [saveStatus, setSaveStatus] = useState("INIT")
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [validationErrors, setValidationErrors] = useState({})
    const { userId } = useParams()
    const history = useHistory()
    const isNew = userId === 'new'
    const [showTab, setShowTab] = useState('general')
    const [showProfileTab, setShowProfileTab] = useState('default')
    const [showProfileInfoTab, setShowProfileInfoTab] = useState('general')
    const [subcontacts, setSubcontacts] = useState([])
    const [parentCompanies, setParentCompanies] = useState({})
    const [companySuggestions, setCompanySuggestions] = useState([])
    const [shouldAutoSave, setShouldAutoSave] = useState(false)

    const languages = processAppSettingsLanguages(appSettings.appSettings.languages)
    const roles = processStaticSettingsObject(appSettings.staticSettings.roles)
    const types = processStaticSettingsObject(appSettings.staticSettings.types)

    const activeProfile = user?.profiles?.find(p => p.is_active_profile)

    const closeShowCompanySuggestionsDropdown = (e) => {
        if(!hasParentClass(e.target, 'show-customer-suggestions')) {
            setCompanySuggestions([])
        }
    }

    useEffect(() => {
        window.addEventListener('click', closeShowCompanySuggestionsDropdown)

        return () => {
            window.removeEventListener('click', closeShowCompanySuggestionsDropdown)
        }
    }, [])

    useEffect(() => {
        if(userId === 'new') {
            setUser(getNewUser())
        }
        else if(userId) {
            fetchUser(userId, setUser, setSubcontacts, setParentCompanies, authContext, setIsLoading, setError)
        }
        else {
            setUser(undefined)
            setError('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
        }
    }, [userId, authContext])

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        try {
            let res
            if(user.id) {
                res = await authContext.authPatchRequest(`/admin/users/${user.id}`, user)
            }
            else {
                res = await authContext.authPostRequest(`/admin/users`, {
                    ...user
                })
            }

            if(!res.data.code) {
                toast.success(`Der Kontakt wurde ${isNew ? 'erstellt' : 'aktualisiert'}`)
                history.push(`/admin/users/${res.data.id}`)
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                        break
                    case 401:
                        throw new Error("Ihre Session wurde beendet. Bitte loggen Sie sich erneut ein")
                        break
                    default:
                    case 500:
                        throw new Error('Beim Laden des Kontakts ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }
    
    useEffect(() => {
        if(shouldAutoSave) {
            autoSaveUser()
            setShouldAutoSave(false)
        }
    }, [shouldAutoSave])

    const autoSaveUser = async () => {
        setSaveStatus('SAVING')
        try {
            let res

            res = await authContext.authPatchRequest(`/admin/users/${user.id}`, user)
            
            if(!res.data.code) {
                setSaveStatus('SAVED')
            }
            else {
                setSaveStatus('FAILED')
            }
        }
        catch(e) {
            setSaveStatus('FAILED')
        }
    }

    const revertAutoSave = async () => {
        setSaveStatus('SAVING')
        try {
            let res

            
            res = await authContext.authPatchRequest(`/admin/users/${user.id}`, user)
            
            if(!res.data.code) {
                setSaveStatus('UNDO')
            }
            else {
                setSaveStatus('FAILED')
            }
        }
        catch(e) {
            setSaveStatus('FAILED')
        }
    }

    const setUserAndSave = (userToSave) => {
        setUser(userToSave)

        if(user.id) {
            setShouldAutoSave(true)
        }
    }

    const updateUser = (updates) => {
        setUserAndSave((user) => ({
            ...user,
            ...updates,
        }))
    }

    const updateActiveProfile = (updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.is_active_profile) {
                    return {
                        ...p,
                        ...updates,
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addRole = (role) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: [
                ...user.profiles,
                getNewProfile(role)
            ]
        }))
        setShowProfileTab(role)
    }

    const updateCurrentProfile = (role, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((profile) => {
                if(profile.role === role) {
                    return {
                        ...profile,
                        ...updates,
                    }
                }
                else {
                    return profile
                }
            })
        }))
    }

    const deleteRole = (role) => {
        setUserAndSave((user) => {
            let profiles = user.profiles.filter((profile) => profile.role !== role)
            if(!profiles.find(p => p.is_active_profile)) {
                console.log("here")
                profiles = profiles.map((p, i) => {
                    if(i === 0) {
                        return {
                            ...p,
                            is_active_profile: true
                        }
                    }
                    else {
                        return p
                    }
                })
            }

            setShowProfileTab(profiles[0]?.role)

            return {
                ...user,
                profiles
            }
        })
    }

    const updateProfileCustomerProfile = (updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'customer') {
                    return {
                        ...p,
                        customer_profile: {
                            ...p.customer_profile,
                            ...updates
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updateProfileTranslatorProfile = (updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            ...updates
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const setKeywords = (keywords) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            keywords
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addLanguage = () => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            languages: [
                                ...p.translator_profile.languages,
                                getNewLanguage()
                            ]
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updateLanguage = (i, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            languages: p.translator_profile.languages.map((language, u) => {
                                if(u === i) { 
                                    return {
                                        ...language,
                                        ...updates
                                    }
                                }
                                else {
                                    return language
                                }
                            })
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const removeLanguage = (i) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            languages: p.translator_profile.languages.filter((l, u) => u !== i)
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addEducation = () => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            education: [
                                ...p.translator_profile.education,
                                getNewEducation()
                            ]
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updateEducation = (i, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            education: p.translator_profile.education.map((education, u) => {
                                if(u === i) {
                                    return {
                                        ...education,
                                        ...updates
                                    }
                                }
                                else {
                                    return education
                                }
                            })
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const removeEducation = (i) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            education: p.translator_profile.education.filter((e, u) => i !== u)
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addExperience = () => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            experience: [
                                ...p.translator_profile.experience,
                                getNewExperience()
                            ]
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updateExperience = (i, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            experience: p.translator_profile.experience.map((experience, u) => {
                                if(u === i) {
                                    return {
                                        ...experience,
                                        ...updates
                                    }
                                }
                                else {
                                    return experience
                                }
                            })
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const removeExperience = (i) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        translator_profile: {
                            ...p.translator_profile,
                            experience: p.translator_profile.experience.filter((e, u) => i !== u)
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const makeProfilePrivate = () => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        company_name: '',
                        type: 'private',
                        translator_profile: {
                            ...p.translator_profile,
                            experience: [],
                            education: []
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const makeProfileSchool = () => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === 'translator') {
                    return {
                        ...p,
                        type: 'school',
                        translator_profile: {
                            ...p.translator_profile,
                            experience: [],
                            education: [],
                            is_translator: false,
                            is_interpreter: false
                        }
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updatePersonalInformation = (role, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            ...updates,
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addPhone = (role) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            phones: [
                                ...p.personal_information.phones,
                                ''
                            ],
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updatePhone = (role, i, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            phones: p.personal_information.phones.map((phone, u) => {
                                if(u === i) {
                                    return updates
                                }
                                else {
                                    return phone
                                }
                            }),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const removePhone = (role, i) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            phones: p.personal_information.phones.filter((p, u) => u !== i),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addFax = (role) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            faxes: [
                                ...p.personal_information.faxes,
                                ''
                            ],
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updateFax = (role, i, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            faxes: p.personal_information.faxes.map((fax, u) => {
                                if(u === i) {
                                    return updates
                                }
                                else {
                                    return fax
                                }
                            }),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const removeFax = (role, i) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            faxes: p.personal_information.faxes.filter((f, u) => u !== i),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addEmail = (role) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            emails: [
                                ...p.personal_information.emails,
                                ''
                            ],
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updateEmail = (role, i, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            emails: p.personal_information.emails.map((email, u) => {
                                if(u === i) {
                                    return updates
                                }
                                else {
                                    return email
                                }
                            }),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const removeEmail = (role, i) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            emails: p.personal_information.emails.filter((e, u) => u !== i),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addWeb = (role) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            webs: [
                                ...p.personal_information.webs,
                                ''
                            ],
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updateWeb = (role, i, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            webs: p.personal_information.webs.map((web, u) => {
                                if(u === i) {
                                    return updates
                                }
                                else {
                                    return web
                                }
                            }),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const removeWeb = (role, i) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            webs: p.personal_information.webs.filter((e, u) => u !== i),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const addAddress = (role) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            addresses: [
                                ...p.personal_information.addresses,
                                getNewAddress()
                            ],
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const updateAddress = (role, i, updates) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            addresses: p.personal_information.addresses.map((address, u) => {
                                if(u === i) {
                                    return {
                                        ...address,
                                        ...updates,
                                    }
                                }
                                else {
                                    return address
                                }
                            }),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const removeAddress = (role, i) => {
        setUserAndSave((user) => ({
            ...user,
            profiles: user.profiles.map((p) => {
                if(p.role === role) {
                    return {
                        ...p,
                        personal_information: {
                            ...p.personal_information,
                            addresses: p.personal_information.addresses.filter((e, u) => u !== i),
                        },
                    }
                }
                else {
                    return p
                }
            }),
        }))
    }

    const searchCompanies = async (q) => {
        fetchCompanies(setCompanySuggestions, { 
            sortBy: 'company_name', 
            q,
            limit: 20, 
            page: 1,
        }, authContext)
    }

    const assignCompany = (role, company) => {
        setCompanySuggestions([])
        setParentCompanies((prevValue) => ({
            ...prevValue,
            [role]: company
        }))

        if(company) {
            updateCurrentProfile(role, {
                company: {
                    id: company.id,
                    name: company.company_name,
                    type: company.company_type,
                }
            })
        }
        else {
            updateCurrentProfile(role, {
                company: undefined
            })
        }
    }

    return {
        user,
        oldState,
        saveStatus,
        updateUser,
        isNew,
        isLoading,
        error,
        handleSubmitForm,
        validationErrors,
        activeProfile,
        updateActiveProfile,
        roles,
        types,
        languages,
        showTab, 
        setShowTab,
        showProfileTab, 
        setShowProfileTab,
        addRole,
        updateCurrentProfile,
        deleteRole,
        showProfileInfoTab, 
        setShowProfileInfoTab,
        updateProfileCustomerProfile,
        updateProfileTranslatorProfile,
        setKeywords,
        addLanguage,
        updateLanguage,
        removeLanguage,
        addEducation,
        updateEducation,
        removeEducation,
        addExperience,
        updateExperience,
        removeExperience,
        makeProfilePrivate,
        makeProfileSchool,
        updatePersonalInformation,
        addPhone,
        updatePhone,
        removePhone,
        addFax,
        updateFax,
        removeFax,
        addEmail,
        updateEmail,
        removeEmail,
        addWeb,
        updateWeb,
        removeWeb,
        addAddress,
        updateAddress,
        removeAddress,
        subcontacts,
        searchCompanies,
        companySuggestions,
        assignCompany,
        revertAutoSave,
        parentCompanies,
    }
}

export default UserController
