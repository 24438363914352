// @ts-nocheck
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"

import { Link, useHistory } from "react-router-dom"

import UserController from 'controllers/admin/User.controller'
import UserSkeleton from 'screens/skeletons/admin/User.skeleton'
import GeneralError from 'screens/errors/GeneralError'

import { apiToDisplayDate, displayToApiDate } from 'utils/datetime'

const User = (props) => {
    
    const {
        isNew,
        user,
        oldState,
        saveStatus,
        updateUser,
        isLoading,
        error,
        handleSubmitForm,
        validationErrors,
        activeProfile,
        updateActiveProfile,
        roles,
        types,
        languages,
        showTab, 
        setShowTab,
        showProfileTab, 
        setShowProfileTab,
        addRole,
        updateCurrentProfile,
        deleteRole,
        showProfileInfoTab, 
        setShowProfileInfoTab,
        updateProfileCustomerProfile,
        updateProfileTranslatorProfile,
        setKeywords,
        addLanguage,
        updateLanguage,
        removeLanguage,
        addEducation,
        updateEducation,
        removeEducation,
        addExperience,
        updateExperience,
        removeExperience,
        makeProfilePrivate,
        makeProfileSchool,
        updatePersonalInformation,
        addPhone,
        updatePhone,
        removePhone,
        addFax,
        updateFax,
        removeFax,
        addEmail,
        updateEmail,
        removeEmail,
        addWeb,
        updateWeb,
        removeWeb,
        addAddress,
        updateAddress,
        removeAddress,
        subcontacts,
        searchCompanies,
        companySuggestions,
        assignCompany,
        revertAutoSave,
        parentCompanies,
    } = UserController(props)

    const history = useHistory()

    const showRole = showProfileTab !== 'default' ? showProfileTab : user?.profiles.find(p => p.is_active_profile)?.role
    const showProfile = showRole && user?.profiles.find(p => p.role === showRole)
    const isOnlyRole = user?.profiles?.length <= 1

    return (
        <div>
            { isLoading && <UserSkeleton /> }
            { error && (
                <GeneralError
                    title={ isNew ? 'Kontakt erstellen' : 'Kontakt aktualisieren' }
                >{ error }</GeneralError>
            ) }
            { user && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title"><strong className="text-black">Verwaltung</strong> / { isNew ? 'Kontakt erstellen' : 'Kontakt aktualisieren' }</h4>
                            </div>
                        </div>
                    </div>
                    {/* end page title */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-2">
                                        { saveStatus !== "INIT" && (
                                            <div className="float-end">
                                                { saveStatus === "SAVING" && "Wird gespeichert..." }
                                                { saveStatus === "SAVED" && (
                                                    <span>
                                                            <i className="dripicons-checkmark text-success me-2" />
                                                            Gespeichert
                                                    </span>
                                                ) }
                                                { saveStatus === "FAILED" && (
                                                    <span>
                                                        <i className="dripicons-warning text-danger me-2" />
                                                        Die Änderungen konnten nicht gespeichert werden
                                                    </span>
                                                ) }
                                                { saveStatus === "UNDO" && "Alle Änderungen wurden rückgängig gemacht" }
                                                { saveStatus !== "UNDO" && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-light btn-sm btn-icon ms-2"
                                                        onClick={ () => {
                                                            revertAutoSave()
                                                        } }
                                                    >
                                                        <i className="mdi mdi-undo" />
                                                    </button>
                                                ) }
                                            </div>
                                        ) }
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary rounded-pill px-2"
                                            onClick={ history.goBack }
                                        >
                                            <i className="dripicons-arrow-thin-left" />
                                        </button>
                                    </div>
                                    <form action="#" onSubmit={ handleSubmitForm }>
                                        <ul className="nav nav-tabs nav-bordered mb-3">
                                            <li className="nav-item">
                                                <a
                                                    href='#'
                                                    className={ `nav-link rounded-0 ${showTab === 'general' ? 'active' : ''}` }
                                                    onClick={ (e) => {
                                                        e.preventDefault();
                                                        setShowTab('general')
                                                    } }
                                                >
                                                    Allgemein
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    href='#'
                                                    className={ `nav-link rounded-0 ${showTab === 'profiles' ? 'active' : ''}` }
                                                    onClick={ (e) => {
                                                        e.preventDefault();
                                                        setShowTab('profiles')
                                                    } }
                                                >
                                                    Profile
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    href='#'
                                                    className={ `nav-link rounded-0 ${showTab === 'blocked' ? 'active' : ''}` }
                                                    onClick={ (e) => {
                                                        e.preventDefault();
                                                        setShowTab('blocked')
                                                    } }
                                                >
                                                    Sperrung
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            { showTab === 'general' && (
                                                <div
                                                    className="tab-pane active"
                                                >
                                                    { user.is_migrated_user && (
                                                        <div className="row">
                                                            <div className="col mb-3">
                                                                Dieser User wurde vom alten System migriert
                                                            </div>
                                                        </div>
                                                    ) }
                                                    <div className="row">
                                                        <div className="col mb-3">
                                                            <div className="btn-group">
                                                                <button
                                                                    type="button"
                                                                    className={ `btn ${!user.is_company ? 'btn-info' : 'btn-light'}` }
                                                                    disabled={ !!user.id }
                                                                    onClick={ () => {
                                                                        updateUser({
                                                                            is_company: false,
                                                                            company_type: '',
                                                                        })
                                                                    } }
                                                                >Person</button>
                                                                <button
                                                                    type="button"
                                                                    className={ `btn ${user.is_company ? 'btn-info' : 'btn-light'}` }
                                                                    disabled={ !!user.id }
                                                                    onClick={ () => {
                                                                        updateUser({
                                                                            is_company: true,
                                                                            company_type: 'company',
                                                                        })
                                                                    } }
                                                                >Organisation</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { !user.is_company ? (
                                                        <div className="row">
                                                            <div className="col-lg-2">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="salutation"
                                                                        className="form-label"
                                                                    >
                                                                        Anrede
                                                                    </label>
                                                                    <select
                                                                        id="salutation"
                                                                        className="form-select"
                                                                        value={ user.salutation }
                                                                        onChange={ (e) => {
                                                                            updateUser({
                                                                                salutation: e.target.value
                                                                            })
                                                                        } }
                                                                    >
                                                                        <option value="">-Bitte auswählen-</option>
                                                                        <option value="mr">Herr</option>
                                                                        <option value="mrs">Frau</option>
                                                                        <option value="other">sonstiges</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="first_name"
                                                                        className="form-label"
                                                                    >
                                                                        Vorname
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="first_name"
                                                                        className="form-control"
                                                                        value={ user.first_name }
                                                                        onChange={ (e) => {
                                                                            updateUser({
                                                                                first_name: e.target.value
                                                                            })
                                                                        } }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="last_name"
                                                                        className="form-label"
                                                                    >
                                                                        Nachname
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="last_name"
                                                                        className="form-control"
                                                                        value={ user.last_name }
                                                                        onChange={ (e) => {
                                                                            updateUser({
                                                                                last_name: e.target.value
                                                                            })
                                                                        } }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="birth_name"
                                                                        className="form-label"
                                                                    >
                                                                        Geb. Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="birth_name"
                                                                        className="form-control"
                                                                        value={ user.birth_name }
                                                                        onChange={ (e) => {
                                                                            updateUser({
                                                                                birth_name: e.target.value
                                                                            })
                                                                        } }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="company_name"
                                                                        className="form-label"
                                                                    >
                                                                        Organisationsname
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="company_name"
                                                                        className="form-control"
                                                                        value={ user.company_name }
                                                                        onChange={ (e) => {
                                                                            updateUser({
                                                                                company_name: e.target.value
                                                                            })
                                                                        } }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="type"
                                                                        className="form-label"
                                                                    >
                                                                        Organisation-Typ
                                                                    </label>
                                                                    <select
                                                                        id="type"
                                                                        className="form-select"
                                                                        value={ user.company_type }
                                                                        onChange={ (e) => {
                                                                            updateUser({
                                                                                company_type: e.target.value
                                                                            })
                                                                        } }
                                                                    >
                                                                        <option value='company'>Firma</option>
                                                                        <option value='government'>Staatliche Institution</option>
                                                                        <option value='school'>Sprachschule</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) }
                                                    
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="date_of_birth"
                                                                    className="form-label"
                                                                >
                                                                    Geburtsdatum
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    id="date_of_birth"
                                                                    className="form-control"
                                                                    value={ apiToDisplayDate(user.date_of_birth) }
                                                                    onChange={ (e) => {
                                                                        updateUser({
                                                                            date_of_birth: displayToApiDate(e.target.value)
                                                                        })
                                                                    } }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="has_account"
                                                                    className="form-label"
                                                                >
                                                                    Hat ein Konto
                                                                </label>
                                                                <div>
                                                                    <input
                                                                        type="checkbox"
                                                                        id="has_account"
                                                                        data-switch="info"
                                                                        checked={ user.has_account }
                                                                        onChange={ (e) => {
                                                                            if(e.target.checked) {
                                                                                updateUser({
                                                                                    has_account: true
                                                                                })
                                                                            }
                                                                            else {
                                                                                updateUser({
                                                                                    has_account: false,
                                                                                    password: '',
                                                                                    email: undefined
                                                                                })
                                                                            }
                                                                        } }
                                                                    />
                                                                    <label
                                                                        htmlFor="has_account"
                                                                        data-on-label="Ja"
                                                                        data-off-label="Nein"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { user.has_account && (
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="email"
                                                                        className="form-label"
                                                                    >
                                                                        E-Mail
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        id="email"
                                                                        className="form-control"
                                                                        value={ user.email }
                                                                        onChange={ (e) => {
                                                                            updateUser({
                                                                                email: e.target.value
                                                                            })
                                                                        } }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="password"
                                                                        className="form-label"
                                                                    >
                                                                        Kennwort
                                                                    </label>
                                                                    <input
                                                                        type="password"
                                                                        id="password"
                                                                        className="form-control"
                                                                        value={ user.password }
                                                                        onChange={ (e) => {
                                                                            updateUser({
                                                                                password: e.target.value
                                                                            })
                                                                        } }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) }
                                                    { user.profiles.filter(p => p.personal_information).length > 0 && (
                                                        <div className="card mb-3 shadow-none border p-3 pb-0">
                                                            <h4 className="mb-3">Kontaktinformationen</h4>
                                                            { user.profiles.filter(p => p.personal_information).map(profile => (
                                                                <div className="mb-3">
                                                                    { profile.role === 'customer' && (
                                                                        <h5 className="mb-3">Als Kunde</h5>
                                                                    ) }
                                                                    { profile.role === 'translator' && (
                                                                        <h5 className="mb-3">Als Mitarbeiter</h5>
                                                                    ) }

                                                                    { (profile.personal_information.addresses.length > 0 || parentCompanies[profile.role]?.profile?.personal_information?.phones?.length > 0) && (
                                                                        <div className="row">
                                                                            <div className="col-3">
                                                                                Addresse
                                                                            </div>
                                                                            <div className="col-9">
                                                                                { parentCompanies[profile.role]?.profile?.personal_information?.addresses && parentCompanies[showProfile.role]?.profile?.personal_information?.addresses.map((address, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { address.address_street }<br />
                                                                                        { `${address.address_zip}, ${address.address_city}, ${address.address_country}` }
                                                                                    </div>  
                                                                                ) ) }
                                                                                { profile.personal_information.addresses.map((address, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { address.address_street }<br />
                                                                                        { `${address.address_zip}, ${address.address_city}, ${address.address_country}` }
                                                                                    </div>
                                                                                )) }
                                                                            </div>
                                                                        </div>
                                                                    ) }

                                                                    { (profile.personal_information.phones.length > 0 || parentCompanies[profile.role]?.profile?.personal_information?.phones?.length > 0)  && (
                                                                        <div className="row">
                                                                            <div className="col-3">
                                                                                Telefone
                                                                            </div>
                                                                            <div className="col-9">
                                                                                { parentCompanies[profile.role]?.profile?.personal_information?.phones && parentCompanies[showProfile.role]?.profile?.personal_information?.phones.map((phone, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { phone }
                                                                                    </div>
                                                                                )) }
                                                                                { profile.personal_information.phones.map((phone, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { phone }
                                                                                    </div>
                                                                                )) }
                                                                            </div>
                                                                        </div>
                                                                    ) }

                                                                    { (profile.personal_information.faxes.length > 0 || parentCompanies[profile.role]?.profile?.personal_information?.faxes?.length > 0)  && (
                                                                        <div className="row">
                                                                            <div className="col-3">
                                                                                Fax
                                                                            </div>
                                                                            <div className="col-9">
                                                                                { parentCompanies[profile.role]?.profile?.personal_information?.faxes && parentCompanies[showProfile.role]?.profile?.personal_information?.faxes.map((fax, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { fax }
                                                                                    </div>
                                                                                )) }
                                                                                { profile.personal_information.faxes.map((fax, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { fax }
                                                                                    </div>
                                                                                )) }
                                                                            </div>
                                                                        </div>
                                                                    ) }

                                                                     { (profile.personal_information.emails.length > 0 || parentCompanies[profile.role]?.profile?.personal_information?.emails?.length > 0)  && (
                                                                        <div className="row">
                                                                            <div className="col-3">
                                                                                Emails
                                                                            </div>
                                                                            <div className="col-9">
                                                                                { parentCompanies[profile.role]?.profile?.personal_information?.emails && parentCompanies[showProfile.role]?.profile?.personal_information?.emails.map((email, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { email }
                                                                                    </div>
                                                                                )) }
                                                                                { profile.personal_information.emails.map((email, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { email }
                                                                                    </div>
                                                                                )) }
                                                                            </div>
                                                                        </div>
                                                                    ) }

                                                                    { (profile.personal_information.webs.length > 0 || parentCompanies[profile.role]?.profile?.personal_information?.webs?.length > 0)  && (
                                                                        <div className="row">
                                                                            <div className="col-3">
                                                                                Webseiten
                                                                            </div>
                                                                            <div className="col-9">
                                                                                { parentCompanies[profile.role]?.profile?.personal_information?.webs && parentCompanies[showProfile.role]?.profile?.personal_information?.webs.map((website, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { website }
                                                                                    </div>
                                                                                )) }
                                                                                { profile.personal_information.webs.map((website, i) => (
                                                                                    <div className="mb-2" key={i}>
                                                                                        { website }
                                                                                    </div>
                                                                                )) }
                                                                            </div>
                                                                        </div>
                                                                    ) }
                                                                </div>
                                                            )) }
                                                        </div>
                                                    ) }

                                                    { user.profiles.filter(p => p.role === 'translator' && p.personal_information).length > 0 && (
                                                        <div className="card mb-3 shadow-none border p-3 pb-0">
                                                            <h4 className="mb-3">Honorar &amp; Entlohnung</h4>
                                                            { user.profiles.filter(p => p.role === 'translator' && p.personal_information).map(profile => (
                                                                <div className="mb-3">
                                                                    <h5 className="mb-3">
                                                                        Honorar für Dolmetscher:in
                                                                    </h5>

                                                                    <div className="row">
                                                                        <div className="col-4 mb-3">
                                                                            Honorar pro Stunde: { profile.translator_profile.fees_per_hour && `${profile.translator_profile.fees_per_hour}€` }
                                                                        </div>
                                                                        <div className="col-4 mb-3">
                                                                            Fahrpauschale: { profile.translator_profile.travel_fees && `${profile.translator_profile.travel_fees}€` }
                                                                        </div>
                                                                        <div className="col-4 mb-3">
                                                                            Fahrpauschale: { profile.translator_profile.travel_fees_per_trip && `${profile.translator_profile.travel_fees_per_trip}€` }
                                                                        </div>
                                                                    </div>

                                                                    <h5 className="mb-3">
                                                                        Entlohnung
                                                                    </h5>

                                                                    <div className="row">
                                                                        <div className="col-4 mb-3">
                                                                            Mitarbeiter Entlohnung: { profile.translator_profile.remuneration && `${profile.translator_profile.remuneration}€` }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )) }
                                                        </div>
                                                    ) }
                                                </div>
                                            ) }

                                            { showTab === 'profiles' && (
                                                <div
                                                    className="row"
                                                >
                                                    <div className="col-sm-3 mb-2 mb-sm-0">
                                                        <ul className="nav flex-column nav-pills mb-3">
                                                            {[{k: 'customer', v: 'Kunde'}, {k: 'translator', v: 'Übersetzer'}, {k: 'admin', v: 'Administrator'}].map(role => {
                                                                return (
                                                                    <li className="nav-item">
                                                                        { user.profiles.find(p => p.role === role.k) ? (
                                                                            <a
                                                                                href='#'
                                                                                className={ `nav-link rounded-0 ${showRole === role.k ? 'bg-light' : ''}` }
                                                                                onClick={ (e) => {
                                                                                    e.preventDefault();
                                                                                    setShowProfileTab(role.k)
                                                                                    setShowProfileInfoTab('general')
                                                                                } }
                                                                            >
                                                                                { role.v }
                                                                            </a>
                                                                        ) : (
                                                                            <a
                                                                                href='#'
                                                                                className="nav-link text-muted rounded-0"
                                                                                onClick={ (e) => {
                                                                                    e.preventDefault();
                                                                                    addRole(role.k)
                                                                                    setShowProfileInfoTab('general')
                                                                                } }
                                                                            >
                                                                                { `${role.v} ` }
                                                                                <i className="uil-plus-circle" />
                                                                            </a>
                                                                        ) }
                                                                    </li>
                                                                )
                                                            }) }
                                                        </ul>
                                                    </div>
                                                    <div className="col-sm-9 ms-n3 mb-3">
                                                        <div className="tab-content p-3 border">
                                                            <div
                                                                className="tab-pane active"
                                                            >
                                                                { showProfile && (
                                                                    <div>
                                                                        <div className="text-end">
                                                                            <button
                                                                                type="button"
                                                                                disabled={isOnlyRole}
                                                                                className={ `btn btn-light ${isOnlyRole ? 'disabled' : ''}`}
                                                                                onClick={ (e) => {
                                                                                    if(!isOnlyRole) {
                                                                                        deleteRole(showRole)
                                                                                    }
                                                                                } }
                                                                            >
                                                                                Profil löschen{" "}
                                                                                <i className="uil-times" />
                                                                            </button>
                                                                        </div>
                                                                        { ['customer', 'translator'].includes(showRole) ? (
                                                                            <>
                                                                                <ul className="nav nav-tabs nav-bordered mb-3">
                                                                                    <li className="nav-item">
                                                                                        <a
                                                                                            href='#'
                                                                                            className={ `nav-link rounded-0 ${showProfileInfoTab === 'general' ? 'active' : ''}` }
                                                                                            onClick={ (e) => {
                                                                                                e.preventDefault();
                                                                                                setShowProfileInfoTab('general')
                                                                                            } }
                                                                                        >
                                                                                            Allgemein
                                                                                        </a>
                                                                                    </li>
                                                                                    <li className="nav-item">
                                                                                        <a
                                                                                            href='#'
                                                                                            className={ `nav-link rounded-0 ${showProfileInfoTab === 'profile' ? 'active' : ''}` }
                                                                                            onClick={ (e) => {
                                                                                                e.preventDefault();
                                                                                                setShowProfileInfoTab('profile')
                                                                                            } }
                                                                                        >
                                                                                            Qualifikationen / Sprachfähigkeiten
                                                                                        </a>
                                                                                    </li>
                                                                                    <li className="nav-item">
                                                                                        <a
                                                                                            href='#'
                                                                                            className={ `nav-link rounded-0 ${showProfileInfoTab === 'personal' ? 'active' : ''}` }
                                                                                            onClick={ (e) => {
                                                                                                e.preventDefault();
                                                                                                setShowProfileInfoTab('personal')
                                                                                            } }
                                                                                        >
                                                                                            Kontaktdetails
                                                                                        </a>
                                                                                    </li>
                                                                                    <li className="nav-item">
                                                                                        <a
                                                                                            href='#'
                                                                                            className={ `nav-link rounded-0 ${showProfileInfoTab === 'payments' ? 'active' : ''}` }
                                                                                            onClick={ (e) => {
                                                                                                e.preventDefault();
                                                                                                setShowProfileInfoTab('payments')
                                                                                            } }
                                                                                        >
                                                                                            Zahlungsinformationen
                                                                                        </a>
                                                                                    </li>
                                                                                    { showRole === 'translator' && (
                                                                                        <li className="nav-item">
                                                                                            <a
                                                                                                href='#'
                                                                                                className={ `nav-link rounded-0 ${showProfileInfoTab === 'fees' ? 'active' : ''}` }
                                                                                                onClick={ (e) => {
                                                                                                    e.preventDefault();
                                                                                                    setShowProfileInfoTab('fees')
                                                                                                } }
                                                                                            >
                                                                                                Honorar
                                                                                            </a>
                                                                                        </li>
                                                                                    ) }
                                                                                </ul>
                                                                                <div className="tab-content">
                                                                                    { showProfileInfoTab === 'general' && (
                                                                                        <div
                                                                                            className="tab-pane active"
                                                                                        >
                                                                                            { user.is_company ? (
                                                                                                <>
                                                                                                    { subcontacts.length > 0 && (
                                                                                                        <div className="row">
                                                                                                            <div className="col-12">
                                                                                                                <h5 className="mb-3 mt-2">Ansprechpartner</h5>
                                                                                                                <table className="table table-bordered">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th>Name</th>
                                                                                                                            <th>Telefone</th>
                                                                                                                            <th>Tax</th>
                                                                                                                            <th>Email</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        { subcontacts.map((subcontact, i) => {
                                                                                                                            const subprofile = subcontact.profiles.find(p => p.role === showRole)

                                                                                                                            return (
                                                                                                                                <tr key={ i }>
                                                                                                                                    <td className="text-muted">
                                                                                                                                        <Link className="text-info d-inline-block pt-1" to={ `/admin/users/${subcontact.id}` } target="_blank">
                                                                                                                                            { `${subcontact.first_name} ${subcontact.last_name}` }
                                                                                                                                        </Link>
                                                                                                                                    </td>
                                                                                                                                    <td className="text-muted">
                                                                                                                                        { [
                                                                                                                                            subprofile && subprofile.personal_information.phones
                                                                                                                                        ].filter(x => x).join(', ') }
                                                                                                                                    </td>
                                                                                                                                    <td className="text-muted">
                                                                                                                                        { [
                                                                                                                                            subprofile && subprofile.personal_information.faxes
                                                                                                                                        ].filter(x => x).join(', ') }
                                                                                                                                    </td>
                                                                                                                                    <td className="text-muted">
                                                                                                                                        { [
                                                                                                                                            subcontact.email,
                                                                                                                                            subprofile && subprofile.personal_information.emails
                                                                                                                                        ].filter(x => x).join(', ') }
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            )
                                                                                                                        }) }
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) }
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    { showProfile.company ? (
                                                                                                        <div className="row">
                                                                                                            <div className="col-6">
                                                                                                                <h5 className="mb-3 mt-2">Organisation</h5>
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <div className="list-group mb-3">
                                                                                                                            <div className="list-group-item">
                                                                                                                                <Link className="text-info d-inline-block pt-1" to={ `/admin/users/${showProfile.company.id}` } target="_blank">
                                                                                                                                    { showProfile.company.name }
                                                                                                                                </Link>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-auto">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-sm pt-2 btn-default"
                                                                                                                            onClick={ () => {
                                                                                                                                updateCurrentProfile(showRole, {
                                                                                                                                    company: undefined
                                                                                                                                })
                                                                                                                            } }
                                                                                                                        >
                                                                                                                            <i className="uil-times" />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div className="row">
                                                                                                            <div className="col-4">   
                                                                                                                <div className="mb-3">
                                                                                                                    <label
                                                                                                                        className="form-label"
                                                                                                                    >
                                                                                                                        Organisation
                                                                                                                    </label>
                                                                                                                    <div className="input-group">
                                                                                                                        <input 
                                                                                                                            type="text" 
                                                                                                                            className="form-control dropdown-toggle"  
                                                                                                                            placeholder="Organisationen suchen..."
                                                                                                                            onChange={ (e) => {
                                                                                                                                searchCompanies(e.target.value)
                                                                                                                            } }
                                                                                                                        />
                                                                                                                        <span className="input-group-text mdi mdi-magnify search-icon" />
                                                                                                                    </div>
                                                                                                                    { companySuggestions.length > 0 && (
                                                                                                                        <div
                                                                                                                            className="dropdown-menu dropdown-menu-end show-customer-suggestions show"
                                                                                                                        >
                                                                                                                            { companySuggestions.map((companySuggestion) => (
                                                                                                                                <a 
                                                                                                                                    href='#' 
                                                                                                                                    className="dropdown-item py-1 px-3"
                                                                                                                                    onClick={ (e) => {
                                                                                                                                        e.preventDefault()
                                                                                                                                        assignCompany(showRole, companySuggestion)
                                                                                                                                    } }
                                                                                                                                >
                                                                                                                                    {companySuggestion.company_name}
                                                                                                                                </a>
                                                                                                                            )) }
                                                                                                                        </div>    
                                                                                                                    ) }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ) }
                                                                                                </>
                                                                                            ) }
                                                                                            <div className="row">
                                                                                                <div className="col-lg-4">
                                                                                                    <div className="mb-3">
                                                                                                        <label
                                                                                                            htmlFor="customer_number"
                                                                                                            className="form-label"
                                                                                                        >
                                                                                                            Kundennummer
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            id="customer_number"
                                                                                                            className="form-control"
                                                                                                            value={ showProfile.customer_number }
                                                                                                            onChange={ (e) => {
                                                                                                                updateCurrentProfile(showRole, {
                                                                                                                    customer_number: e.target.value
                                                                                                                })
                                                                                                            } }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-12">
                                                                                                    <div className="mb-3">
                                                                                                        <label
                                                                                                            htmlFor="profile.notes"
                                                                                                            className="form-label"
                                                                                                        >
                                                                                                            Notizen
                                                                                                        </label>
                                                                                                        <textarea
                                                                                                            className="form-control"
                                                                                                            id="profile.notes"
                                                                                                            rows={7}
                                                                                                            value={ showProfile.notes }
                                                                                                            onChange={ (e) => {
                                                                                                                updateCurrentProfile(showRole, {
                                                                                                                    notes: e.target.value
                                                                                                                })
                                                                                                            } }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>{" "}
                                                                                                {/* end col */}
                                                                                            </div>
                                                                                            { user.is_migrated_user && showRole === 'customer' && (
                                                                                                <>
                                                                                                    <h5 className="mb-3 mt-2">
                                                                                                        Information des alten Systems
                                                                                                    </h5>
                                                                                                    <div className="row">
                                                                                                        <div className="col-6">
                                                                                                            <div className="mb-3">
                                                                                                                <label
                                                                                                                    htmlFor="customer_profile.info1"
                                                                                                                    className="form-label"
                                                                                                                >
                                                                                                                    Info 1
                                                                                                                </label>
                                                                                                                <textarea
                                                                                                                    className="form-control"
                                                                                                                    id="customer_profile.info1"
                                                                                                                    rows={5}
                                                                                                                    value={ showProfile.customer_profile.info1 }
                                                                                                                    onChange={ (e) => {
                                                                                                                        updateProfileCustomerProfile({
                                                                                                                            info1: e.target.value
                                                                                                                        })
                                                                                                                    } }
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>{" "}
                                                                                                        <div className="col-6">
                                                                                                            <div className="mb-3">
                                                                                                                <label
                                                                                                                    htmlFor="customer_profile.info2"
                                                                                                                    className="form-label"
                                                                                                                >
                                                                                                                    Info 2
                                                                                                                </label>
                                                                                                                <textarea
                                                                                                                    className="form-control"
                                                                                                                    id="customer_profile.info2"
                                                                                                                    rows={5}
                                                                                                                    value={ showProfile.customer_profile.info2 }
                                                                                                                    onChange={ (e) => {
                                                                                                                        updateProfileCustomerProfile({
                                                                                                                            info2: e.target.value
                                                                                                                        })
                                                                                                                    } }
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>{" "}
                                                                                                    </div>
                                                                                                </>
                                                                                            ) }
                                                                                        </div>
                                                                                    ) }
                                                                                    { showProfileInfoTab === 'profile' && (
                                                                                        <div
                                                                                            className="tab-pane active"
                                                                                        >
                                                                                            { showProfile.role === 'customer' && (
                                                                                                <div className="row">
                                                                                                    <div className="col-12">
                                                                                                        <div className="mb-3">
                                                                                                            <label
                                                                                                                htmlFor="description"
                                                                                                                className="form-label"
                                                                                                            >
                                                                                                                Beschreibung
                                                                                                            </label>
                                                                                                            <textarea
                                                                                                                className="form-control"
                                                                                                                id="description"
                                                                                                                rows={4}
                                                                                                                value={ showProfile.customer_profile.description }
                                                                                                                onChange={ (e) => {
                                                                                                                    updateProfileCustomerProfile({
                                                                                                                        description: e.target.value
                                                                                                                    })
                                                                                                                } }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>{" "}
                                                                                                    {/* end col */}
                                                                                                </div>
                                                                                            ) }
                                                                                            { showProfile.role === 'translator' && (
                                                                                                <>
                                                                                                    <div className="row">
                                                                                                        <div className="col-12">
                                                                                                            <div className="mb-3">
                                                                                                                <label
                                                                                                                    htmlFor="description"
                                                                                                                    className="form-label"
                                                                                                                >
                                                                                                                    Beschreibung
                                                                                                                </label>
                                                                                                                <textarea
                                                                                                                    className="form-control"
                                                                                                                    id="description"
                                                                                                                    rows={4}
                                                                                                                    value={ showProfile.translator_profile.description }
                                                                                                                    onChange={ (e) => {
                                                                                                                        updateProfileTranslatorProfile({
                                                                                                                            description: e.target.value
                                                                                                                        })
                                                                                                                    } }
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>{" "}
                                                                                                        {/* end col */}
                                                                                                    </div>{" "}
                                                                                                    { showProfile.type !== "school" && (
                                                                                                        <>
                                                                                                            <div className="mb-2">
                                                                                                                Dolmetscher:in / Übersetzer:in / Lehrer:in:
                                                                                                            </div>
                                                                                                            <div className="form-check form-checkbox-info mb-2">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    id="is_translator"
                                                                                                                    checked={showProfile.translator_profile.is_translator}
                                                                                                                    onChange={(e) => { 
                                                                                                                        updateProfileTranslatorProfile({
                                                                                                                            is_translator: e.target.checked
                                                                                                                        })
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor="is_translator">
                                                                                                                    Übersertzer
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="form-check form-checkbox-info mb-2">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    id="is_interpreter"
                                                                                                                    checked={showProfile.translator_profile.is_interpreter}
                                                                                                                    onChange={(e) => { 
                                                                                                                        updateProfileTranslatorProfile({
                                                                                                                            is_interpreter: e.target.checked
                                                                                                                        })
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor="is_interpreter">
                                                                                                                    Dolmetscher:in
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="form-check form-checkbox-info mb-3">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    id="is_teacher"
                                                                                                                    checked={showProfile.translator_profile.is_teacher}
                                                                                                                    onChange={(e) => { 
                                                                                                                        updateProfileTranslatorProfile({
                                                                                                                            is_teacher: e.target.checked
                                                                                                                        })
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor="is_teacher">
                                                                                                                    Lehrer
                                                                                                                </label>
                                                                                                            </div>
                                                                                                            <div className="form-check form-checkbox-info mb-3">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    className="form-check-input"
                                                                                                                    id="is_certified"
                                                                                                                    checked={showProfile.translator_profile.is_certified}
                                                                                                                    onChange={(e) => { 
                                                                                                                        updateProfileTranslatorProfile({
                                                                                                                            is_certified: e.target.checked
                                                                                                                        })
                                                                                                                    }}
                                                                                                                />
                                                                                                                <label className="form-check-label" htmlFor="is_certified">
                                                                                                                    Vereidigter Übersetzer
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) }
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="keywords" className="form-label mb-2">
                                                                                                            Fähigkeiten
                                                                                                        </label>
                                                                                                        <ReactTagInput 
                                                                                                            id="keywords"
                                                                                                            tags={showProfile.translator_profile.keywords} 
                                                                                                            onChange={setKeywords}
                                                                                                            maxTags={10}
                                                                                                            placeholder="Tippen Sie und drücken Sie Enter"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <h5 className="mb-3 mt-2">
                                                                                                        Sprachen
                                                                                                    </h5>
                                                                                                    { showProfile.translator_profile.languages.map((language, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-xl-6 col-md-8">
                                                                                                                <div className="mb-2">
                                                                                                                    <div className="float-end">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-sm btn-default"
                                                                                                                            onClick={ () => {
                                                                                                                                removeLanguage(i)
                                                                                                                            } }
                                                                                                                        >
                                                                                                                            <i className="uil-times" />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                    <label htmlFor={ `language_${i}`} className="form-label">
                                                                                                                        Sprache
                                                                                                                    </label>
                                                                                                                    <select
                                                                                                                        className="form-select"
                                                                                                                        id={ `language_${i}`}
                                                                                                                        value={language.language}
                                                                                                                        onChange={(e) => { 
                                                                                                                            updateLanguage(i, {
                                                                                                                                language: e.target.value
                                                                                                                            })
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <option value="">- Bitte auswählen -</option>
                                                                                                                        { languages.map(({key, value}) => (
                                                                                                                            <option key={key} value={key}>
                                                                                                                                { value }
                                                                                                                            </option>
                                                                                                                        )) }
                                                                                                                    </select>
                                                                                                                </div>
                                                                                                                <div className="form-check form-checkbox-info mb-2">
                                                                                                                    <input
                                                                                                                        type="checkbox"
                                                                                                                        className="form-check-input"
                                                                                                                        id={ `is_native_${i}` }
                                                                                                                        checked={language.is_native}
                                                                                                                        onChange={(e) => { 
                                                                                                                            updateLanguage(i, {
                                                                                                                                is_native: e.target.checked
                                                                                                                            })
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    <label className="form-check-label" htmlFor={ `is_native_${i}` }>
                                                                                                                        Muttersprache
                                                                                                                    </label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    <div>
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-light mb-2"
                                                                                                            onClick={ addLanguage }
                                                                                                        >   
                                                                                                            <i className="uil-plus" />{ " " }
                                                                                                            <span className="ps-2">Sprache hinzufügen</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                    { showProfile.type !== 'school' && (
                                                                                                        <>
                                                                                                            <h5 className="mb-3 mt-2">
                                                                                                                Bildung
                                                                                                            </h5>
                                                                                                            { showProfile.translator_profile.education?.map((education, i) => (
                                                                                                                <div className="row mb-3 border-bottom border-light">
                                                                                                                    <div className="col-xl-6 col-md-8">
                                                                                                                        <div className="mb-2">
                                                                                                                            <div className="float-end">
                                                                                                                                <button 
                                                                                                                                    type="button"
                                                                                                                                    className="btn btn-sm btn-default"
                                                                                                                                    onClick={ () => {
                                                                                                                                        removeEducation(i)
                                                                                                                                    } }
                                                                                                                                >
                                                                                                                                    <i className="uil-times" />
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                            <div className="mb-3">
                                                                                                                                <label htmlFor={ `education_${i}_title` } className="form-label">
                                                                                                                                    Titel
                                                                                                                                </label>
                                                                                                                                <input
                                                                                                                                    type="text" 
                                                                                                                                    id={ `education_${i}_title` }
                                                                                                                                    className="form-control" 
                                                                                                                                    value={ education.title }
                                                                                                                                    onChange={(e) => { 
                                                                                                                                        updateEducation(i, {
                                                                                                                                            title: e.target.value
                                                                                                                                        })
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="mb-3">
                                                                                                                        <label htmlFor={ `education_${i}_description` } className="form-label mb-3">
                                                                                                                            <strong>Beschreibung</strong>
                                                                                                                        </label>
                                                                                                                        <textarea
                                                                                                                            className="form-control"
                                                                                                                            id={ `education_${i}_title` }
                                                                                                                            rows={6}
                                                                                                                            value={ education.description }
                                                                                                                            onChange={(e) => { 
                                                                                                                                updateEducation(i, {
                                                                                                                                    description: e.target.value
                                                                                                                                })
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )) }
                                                                                                            <div>
                                                                                                                <button 
                                                                                                                    type="button"
                                                                                                                    className="btn btn-light mb-2"
                                                                                                                    onClick={ addEducation }
                                                                                                                >   
                                                                                                                    <i className="uil-plus" />{ " " }
                                                                                                                    <span className="ps-2">Bildung hinzufügen</span>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                            {/* end row */}
                                                                                                            <h5 className="mb-3 mt-2">
                                                                                                                Erfahrung
                                                                                                            </h5>
                                                                                                            { showProfile.translator_profile.experience?.map((experience, i) => (
                                                                                                                <div className="row mb-3 border-bottom border-light">
                                                                                                                    <div className="col-xl-6 col-md-8">
                                                                                                                        <div className="mb-2">
                                                                                                                            <div className="float-end">
                                                                                                                                <button 
                                                                                                                                    type="button"
                                                                                                                                    className="btn btn-sm btn-default"
                                                                                                                                    onClick={ () => {
                                                                                                                                        removeExperience(i)
                                                                                                                                    } }
                                                                                                                                >
                                                                                                                                    <i className="uil-times" />
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                            <div className="mb-3">
                                                                                                                                <label htmlFor={ `experience_${i}_title` } className="form-label">
                                                                                                                                    Titel
                                                                                                                                </label>
                                                                                                                                <input
                                                                                                                                    type="text" 
                                                                                                                                    id={ `experience_${i}_title` }
                                                                                                                                    className="form-control" 
                                                                                                                                    value={ experience.title }
                                                                                                                                    onChange={(e) => { 
                                                                                                                                        updateExperience(i, {
                                                                                                                                            title: e.target.value
                                                                                                                                        })
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="mb-3">
                                                                                                                        <label htmlFor={ `experience_${i}_description` } className="form-label mb-3">
                                                                                                                            <strong>Beschreibung</strong>
                                                                                                                        </label>
                                                                                                                        <textarea
                                                                                                                            className="form-control"
                                                                                                                            id={ `experience_${i}_title` }
                                                                                                                            rows={6}
                                                                                                                            value={ experience.description }
                                                                                                                            onChange={(e) => { 
                                                                                                                                updateExperience(i, {
                                                                                                                                    description: e.target.value
                                                                                                                                })
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )) }
                                                                                                            <div>
                                                                                                                <button 
                                                                                                                    type="button"
                                                                                                                    className="btn btn-light"
                                                                                                                    onClick={ addExperience }
                                                                                                                >   
                                                                                                                    <i className="uil-plus" />{ " " }
                                                                                                                    <span className="ps-2">Erfahrung hinzufügen</span>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    ) }
                                                                                                </>
                                                                                            ) }
                                                                                        </div>
                                                                                    ) }
                                                                                    { showProfileInfoTab === 'personal' && (
                                                                                        <div
                                                                                            className="tab-pane active"
                                                                                        >
                                                                                            <h5 className="mb-3 mt-2">
                                                                                                Kontakt
                                                                                            </h5>
                                                                                            
                                                                                            <div className="card bg-light mb-3 shadow-none border">
                                                                                                <div className="p-3">
                                                                                                    <p className="mb-3">
                                                                                                        <strong>Addresse</strong>
                                                                                                    </p>
                                                                                                    { parentCompanies[showProfile.role]?.profile?.personal_information?.addresses && parentCompanies[showProfile.role]?.profile?.personal_information?.addresses.map((address, i) => (
                                                                                                        <div className="card p-3 bg-light shadow-none border mb-3">
                                                                                                            <div className="row">
                                                                                                                <div className="col-8">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col">
                                                                                                                            <div className="mb-3">
                                                                                                                                <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control"
                                                                                                                                    placeholder="Straße"
                                                                                                                                    disabled
                                                                                                                                    value={ address.address_street }
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-4">
                                                                                                                            <div className="mb-3">
                                                                                                                                <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control"
                                                                                                                                    placeholder="PLZ"
                                                                                                                                    value={ address.address_zip }
                                                                                                                                    disabled
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-8">
                                                                                                                            <div className="mb-3">
                                                                                                                                <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control"
                                                                                                                                    placeholder="Stadt"
                                                                                                                                    value={ address.address_city }
                                                                                                                                    disabled
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            className="form-control"
                                                                                                                            placeholder="Land"
                                                                                                                            value={ address.address_country }
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    { showProfile.personal_information.addresses.map((address, i) => (
                                                                                                        <div className="card p-3 bg-light shadow-none border mb-3">
                                                                                                            <div className="row">
                                                                                                                <div className="col-8">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col">
                                                                                                                            <div className="mb-3">
                                                                                                                                <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control"
                                                                                                                                    placeholder="Straße"
                                                                                                                                    value={ address.address_street }
                                                                                                                                    onChange={ (e) => {
                                                                                                                                        updateAddress(showProfile.role, i, {
                                                                                                                                            address_street: e.target.value
                                                                                                                                        })
                                                                                                                                    } }
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-auto">
                                                                                                                            <button 
                                                                                                                                type="button"
                                                                                                                                className="btn btn-sm btn-default"
                                                                                                                                onClick={ () => {
                                                                                                                                    removeAddress(showProfile.role, i)
                                                                                                                                } }
                                                                                                                            >
                                                                                                                                <i className="uil-times" />
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-4">
                                                                                                                            <div className="mb-3">
                                                                                                                                <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control"
                                                                                                                                    placeholder="PLZ"
                                                                                                                                    value={ address.address_zip }
                                                                                                                                    onChange={ (e) => {
                                                                                                                                        updateAddress(showProfile.role, i, {
                                                                                                                                            address_zip: e.target.value
                                                                                                                                        })
                                                                                                                                    } }
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-8">
                                                                                                                            <div className="mb-3">
                                                                                                                                <input
                                                                                                                                    type="text"
                                                                                                                                    className="form-control"
                                                                                                                                    placeholder="Stadt"
                                                                                                                                    value={ address.address_city }
                                                                                                                                    onChange={ (e) => {
                                                                                                                                        updateAddress(showProfile.role, i, {
                                                                                                                                            address_city: e.target.value
                                                                                                                                        })
                                                                                                                                    } }
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            className="form-control"
                                                                                                                            placeholder="Land"
                                                                                                                            value={ address.address_country }
                                                                                                                            onChange={ (e) => {
                                                                                                                                updateAddress(showProfile.role, i, {
                                                                                                                                    address_country: e.target.value
                                                                                                                                })
                                                                                                                            } }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    <div>
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary"
                                                                                                            onClick={ () => {
                                                                                                                addAddress(showProfile.role)
                                                                                                            } }
                                                                                                        >   
                                                                                                            <i className="uil-plus" />{ " " }
                                                                                                            <span className="ps-2">Addresse hinzufügen</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="card bg-light mb-3 shadow-none border">
                                                                                                <div className="p-3">
                                                                                                    <p className="mb-3">
                                                                                                        <strong>Telefone</strong>
                                                                                                    </p>
                                                                                                    { parentCompanies[showProfile.role]?.profile?.personal_information?.phones && parentCompanies[showProfile.role]?.profile?.personal_information?.phones.map((phone, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-12">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            id={ `phone_${showProfile.role}_${i}` }
                                                                                                                            className="form-control"
                                                                                                                            value={ phone }
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    { showProfile.personal_information.phones.map((phone, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-8">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            id={ `phone_${showProfile.role}_${i}` }
                                                                                                                            className="form-control"
                                                                                                                            value={ phone }
                                                                                                                            onChange={ (e) => {
                                                                                                                                updatePhone(showProfile.role, i, e.target.value)
                                                                                                                            } }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className="col-auto">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-sm btn-default"
                                                                                                                            onClick={ () => {
                                                                                                                                removePhone(showProfile.role, i)
                                                                                                                            } }
                                                                                                                        >
                                                                                                                            <i className="uil-times" />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    <div>
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary"
                                                                                                            onClick={ () => {
                                                                                                                addPhone(showProfile.role)
                                                                                                            } }
                                                                                                        >   
                                                                                                            <i className="uil-plus" />{ " " }
                                                                                                            <span className="ps-2">Telefon hinzufügen</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="card bg-light mb-3 shadow-none border">
                                                                                                <div className="p-3">
                                                                                                    <p className="mb-3">
                                                                                                        <strong>Fax</strong>
                                                                                                    </p>
                                                                                                    { parentCompanies[showProfile.role]?.profile?.personal_information?.faxes && parentCompanies[showProfile.role]?.profile?.personal_information?.faxes.map((fax, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-8">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            id={ `fax_${showProfile.role}_${i}` }
                                                                                                                            className="form-control"
                                                                                                                            value={ fax }
                                                                                                                            onChange={ (e) => {
                                                                                                                                updateFax(showProfile.role, i, e.target.value)
                                                                                                                            } }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className="col-auto">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-sm btn-default"
                                                                                                                            onClick={ () => {
                                                                                                                                removeFax(showProfile.role, i)
                                                                                                                            } }
                                                                                                                        >
                                                                                                                            <i className="uil-times" />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    { showProfile.personal_information.faxes.map((fax, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-8">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            id={ `fax_${showProfile.role}_${i}` }
                                                                                                                            className="form-control"
                                                                                                                            value={ fax }
                                                                                                                            onChange={ (e) => {
                                                                                                                                updateFax(showProfile.role, i, e.target.value)
                                                                                                                            } }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className="col-auto">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-sm btn-default"
                                                                                                                            onClick={ () => {
                                                                                                                                removeFax(showProfile.role, i)
                                                                                                                            } }
                                                                                                                        >
                                                                                                                            <i className="uil-times" />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    <div>
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary"
                                                                                                            onClick={ () => {
                                                                                                                addFax(showProfile.role)
                                                                                                            } }
                                                                                                        >   
                                                                                                            <i className="uil-plus" />{ " " }
                                                                                                            <span className="ps-2">Fax hinzufügen</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="card bg-light mb-3 shadow-none border">
                                                                                                <div className="p-3">
                                                                                                    <p className="mb-3">
                                                                                                        <strong>Emails</strong>
                                                                                                    </p>
                                                                                                    { parentCompanies[showProfile.role]?.profile?.personal_information?.emails && parentCompanies[showProfile.role]?.profile?.personal_information?.emails.map((email, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-12">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            id={ `email_${showProfile.role}_${i}` }
                                                                                                                            className="form-control"
                                                                                                                            value={ email }
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    { user.email && (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-8">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            className="form-control"
                                                                                                                            value={ user.email }
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div> 
                                                                                                    ) }
                                                                                                    { showProfile.personal_information.emails.map((email, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-8">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            id={ `email_${showProfile.role}_${i}` }
                                                                                                                            className="form-control"
                                                                                                                            value={ email }
                                                                                                                            onChange={ (e) => {
                                                                                                                                updateEmail(showProfile.role, i, e.target.value)
                                                                                                                            } }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className="col-auto">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-sm btn-default"
                                                                                                                            onClick={ () => {
                                                                                                                                removeEmail(showProfile.role, i)
                                                                                                                            } }
                                                                                                                        >
                                                                                                                            <i className="uil-times" />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    <div>
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary"
                                                                                                            onClick={ () => {
                                                                                                                addEmail(showProfile.role)
                                                                                                            } }
                                                                                                        >   
                                                                                                            <i className="uil-plus" />{ " " }
                                                                                                            <span className="ps-2">Email hinzufügen</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="card bg-light mb-3 shadow-none border">
                                                                                                <div className="p-3">
                                                                                                    <p className="mb-3">
                                                                                                        <strong>Webseiten</strong>
                                                                                                    </p>
                                                                                                    { parentCompanies[showProfile.role]?.profile?.personal_information?.webs && parentCompanies[showProfile.role]?.profile?.personal_information?.webs.map((web, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-12">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            id={ `web_${showProfile.role}_${i}` }
                                                                                                                            className="form-control"
                                                                                                                            value={ web }
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    { showProfile.personal_information.webs.map((web, i) => (
                                                                                                        <div className="row mb-3">
                                                                                                            <div className="col-8">
                                                                                                                <div className="row">
                                                                                                                    <div className="col">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            id={ `web_${showProfile.role}_${i}` }
                                                                                                                            className="form-control"
                                                                                                                            value={ web }
                                                                                                                            onChange={ (e) => {
                                                                                                                                updateWeb(showProfile.role, i, e.target.value)
                                                                                                                            } }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div className="col-auto">
                                                                                                                        <button 
                                                                                                                            type="button"
                                                                                                                            className="btn btn-sm btn-default"
                                                                                                                            onClick={ () => {
                                                                                                                                removeWeb(showProfile.role, i)
                                                                                                                            } }
                                                                                                                        >
                                                                                                                            <i className="uil-times" />
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )) }
                                                                                                    <div>
                                                                                                        <button 
                                                                                                            type="button"
                                                                                                            className="btn btn-secondary"
                                                                                                            onClick={ () => {
                                                                                                                addWeb(showProfile.role)
                                                                                                            } }
                                                                                                        >   
                                                                                                            <i className="uil-plus" />{ " " }
                                                                                                            <span className="ps-2">Webseite hinzufügen</span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) }
                                                                                    { showProfileInfoTab === 'payments' && (
                                                                                        <div
                                                                                            className="tab-pane active"
                                                                                        >
                                                                                            <h5 className="mb-3 mt-2">
                                                                                                Zahlungsinformationen
                                                                                            </h5>

                                                                                            <div className="row">
                                                                                                <div className="col-6">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="iban" className="form-label">
                                                                                                            IBAN
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            id="iban"
                                                                                                            className="form-control"
                                                                                                            value={ showProfile.personal_information.iban }
                                                                                                            onChange={ (e) => {
                                                                                                                updatePersonalInformation(showProfile.role, {
                                                                                                                    iban: e.target.value
                                                                                                                })
                                                                                                            } }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="tax_number" className="form-label">
                                                                                                            Steuernummer
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            id="tax_number"
                                                                                                            className="form-control"
                                                                                                            value={ showProfile.personal_information.tax_number }
                                                                                                            onChange={ (e) => {
                                                                                                                updatePersonalInformation(showProfile.role, {
                                                                                                                    tax_number: e.target.value
                                                                                                                })
                                                                                                            } }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="tax_id" className="form-label">
                                                                                                            Umsatzsteuer-ID
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            id="tax_id"
                                                                                                            className="form-control"
                                                                                                            value={ showProfile.personal_information.tax_id }
                                                                                                            onChange={ (e) => {
                                                                                                                updatePersonalInformation(showProfile.role, {
                                                                                                                    tax_id: e.target.value
                                                                                                                })
                                                                                                            } }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-6">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="bic" className="form-label">
                                                                                                            BIC
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            id="bic"
                                                                                                            className="form-control"
                                                                                                            value={ showProfile.personal_information.bic }
                                                                                                            onChange={ (e) => {
                                                                                                                updatePersonalInformation(showProfile.role, {
                                                                                                                    bic: e.target.value
                                                                                                                })
                                                                                                            } }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <h5 className="mb-3 mt-2">
                                                                                                Sonstiges
                                                                                            </h5>

                                                                                            <div className="mb-3">
                                                                                                <div className="float-start">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        id="is_zugferd"
                                                                                                        data-switch="info"
                                                                                                        checked={ showProfile.personal_information.is_zugferd }
                                                                                                        onChange={ (e) => {
                                                                                                            if(e.target.checked) {
                                                                                                                updatePersonalInformation(showProfile.role, {
                                                                                                                    is_zugferd: true
                                                                                                                })
                                                                                                            }
                                                                                                            else {
                                                                                                                updatePersonalInformation(showProfile.role, {
                                                                                                                    is_zugferd: false,
                                                                                                                    routing_number: '',
                                                                                                                })
                                                                                                            }
                                                                                                        } }
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor="is_zugferd"
                                                                                                        data-on-label="Ja"
                                                                                                        data-off-label="Nein"
                                                                                                    />
                                                                                                </div>
                                                                                                <label
                                                                                                    htmlFor="is_zugferd"
                                                                                                    className="form-label ms-2"
                                                                                                >
                                                                                                    ZUGFeRD 2 Standard 
                                                                                                </label>
                                                                                            </div>
                                                                                            {  showProfile.personal_information.is_zugferd && (
                                                                                                <div className="row">
                                                                                                    <div className="col-6">
                                                                                                        <div className="mb-3">
                                                                                                            <label htmlFor="routing_number" className="form-label">
                                                                                                                Leitweg-ID / Leitwegsnummer
                                                                                                            </label>
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                id="routing_number"
                                                                                                                className="form-control"
                                                                                                                value={ showProfile.personal_information.routing_number }
                                                                                                                onChange={ (e) => {
                                                                                                                    updatePersonalInformation(showProfile.role, {
                                                                                                                        routing_number: e.target.value
                                                                                                                    })
                                                                                                                } }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) }

                                                                                            <h5 className="mb-3 mt-2">
                                                                                                PayPal
                                                                                            </h5>

                                                                                            <div className="row">
                                                                                                <div className="col-6">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="paypal_address" className="form-label">
                                                                                                            PayPal Addresse
                                                                                                        </label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            id="paypal_address"
                                                                                                            className="form-control"
                                                                                                            value={ showProfile.personal_information.paypal_address }
                                                                                                            onChange={ (e) => {
                                                                                                                updatePersonalInformation(showProfile.role, {
                                                                                                                    paypal_address: e.target.value
                                                                                                                })
                                                                                                            } }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            { showProfile.personal_information.is_migrated_bank_info && (
                                                                                                <>
                                                                                                    <h5 className="mb-3 mt-2">
                                                                                                        Zahlungsinformationen des alten Systems
                                                                                                    </h5>

                                                                                                    <div className="row">
                                                                                                        <div className="col-6">
                                                                                                            <div className="mb-3">
                                                                                                                <label htmlFor="migrated_bank_info.Bank" className="form-label">
                                                                                                                    Bank
                                                                                                                </label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    id="migrated_bank_info.Bank"
                                                                                                                    className="form-control"
                                                                                                                    value={ showProfile.personal_information.migrated_bank_info.Bank }
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-6">
                                                                                                            <div className="mb-3">
                                                                                                                <label htmlFor="migrated_bank_info.BLZ" className="form-label">
                                                                                                                    BLZ
                                                                                                                </label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    id="migrated_bank_info.BLZ"
                                                                                                                    className="form-control"
                                                                                                                    value={ showProfile.personal_information.migrated_bank_info.BLZ }
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row">
                                                                                                        <div className="col-6">
                                                                                                            <div className="mb-3">
                                                                                                                <label htmlFor="migrated_bank_info.Konto" className="form-label">
                                                                                                                    Konto
                                                                                                                </label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    id="migrated_bank_info.Konto"
                                                                                                                    className="form-control"
                                                                                                                    value={ showProfile.personal_information.migrated_bank_info.Konto }
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-6">
                                                                                                            <div className="mb-3">
                                                                                                                <label htmlFor="migrated_bank_info.Nomer" className="form-label">
                                                                                                                    Nomer
                                                                                                                </label>
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    id="migrated_bank_info.Nomer"
                                                                                                                    className="form-control"
                                                                                                                    value={ showProfile.personal_information.migrated_bank_info.Nomer }
                                                                                                                    disabled
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) }
                                                                                        </div>
                                                                                    ) }
                                                                                    { showProfileInfoTab === 'fees' && (
                                                                                        <div
                                                                                            className="tab-pane active"
                                                                                        >
                                                                                            <h5 className="mb-3 mt-2">
                                                                                                Honorar für Dolmetscher:in
                                                                                            </h5>

                                                                                            <div className="row">
                                                                                                <div className="col-4">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="fees_per_hour" className="form-label">
                                                                                                            Honorar pro Stunde
                                                                                                        </label>
                                                                                                        <div className="input-group">
                                                                                                            <input 
                                                                                                                type="number" 
                                                                                                                className="form-control"
                                                                                                                id="fees_per_hour"
                                                                                                                value={ showProfile.translator_profile.fees_per_hour }
                                                                                                                onChange={ (e) => {
                                                                                                                    updateProfileTranslatorProfile({
                                                                                                                        fees_per_hour: Number(e.target.value)
                                                                                                                    })
                                                                                                                } }
                                                                                                            />
                                                                                                            <span className="input-group-text">
                                                                                                                <i className="uil-euro" />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="travel_fees" className="form-label">
                                                                                                            Fahrpauschale
                                                                                                        </label>
                                                                                                        <div className="input-group">
                                                                                                            <input 
                                                                                                                type="number" 
                                                                                                                className="form-control"
                                                                                                                id="travel_fees"
                                                                                                                value={ showProfile.translator_profile.travel_fees }
                                                                                                                onChange={ (e) => {
                                                                                                                    updateProfileTranslatorProfile({
                                                                                                                        travel_fees: Number(e.target.value)
                                                                                                                    })
                                                                                                                } }
                                                                                                            />
                                                                                                            <span className="input-group-text">
                                                                                                                <i className="uil-euro" />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-4">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="travel_fees_per_trip" className="form-label">
                                                                                                            Honorar pro Fahrtweg
                                                                                                        </label>
                                                                                                        <div className="input-group">
                                                                                                            <input 
                                                                                                                type="number" 
                                                                                                                className="form-control"
                                                                                                                id="travel_fees_per_trip"
                                                                                                                value={ showProfile.translator_profile.travel_fees_per_trip }
                                                                                                                onChange={ (e) => {
                                                                                                                    updateProfileTranslatorProfile({
                                                                                                                        travel_fees_per_trip: Number(e.target.value)
                                                                                                                    })
                                                                                                                } }
                                                                                                            />
                                                                                                            <span className="input-group-text">
                                                                                                                <i className="uil-euro" />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <h5 className="mb-3 mt-2">
                                                                                                Entlohnung
                                                                                            </h5>

                                                                                            <div className="row">
                                                                                                <div className="col-4">
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="remuneration" className="form-label">
                                                                                                            Mitarbeiter Entlohnung
                                                                                                        </label>
                                                                                                        <div className="input-group">
                                                                                                            <input 
                                                                                                                type="number" 
                                                                                                                className="form-control"
                                                                                                                id="remuneration"
                                                                                                                value={ showProfile.translator_profile.remuneration }
                                                                                                                onChange={ (e) => {
                                                                                                                    updateProfileTranslatorProfile({
                                                                                                                        remuneration: Number(e.target.value)
                                                                                                                    })
                                                                                                                } }
                                                                                                            />
                                                                                                            <span className="input-group-text">
                                                                                                                <i className="uil-euro" />
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) }
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <p><strong>Administrator</strong> - Keine Profilinformation vorhanden</p>
                                                                        ) }
                                                                    </div>
                                                                ) }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) }

                                            { showTab === 'blocked' && (
                                                <div
                                                    className="tab-pane active"
                                                >
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="is_blocked"
                                                                    className="form-label"
                                                                >
                                                                    Ist gesperrt
                                                                </label>
                                                                <div>
                                                                    <input
                                                                        type="checkbox"
                                                                        id="is_blocked"
                                                                        data-switch="info"
                                                                        checked={ user.is_blocked }
                                                                        onChange={ (e) => {
                                                                            if(e.target.checked) {
                                                                                updateUser({
                                                                                    is_blocked: true
                                                                                })
                                                                            }
                                                                            else {
                                                                                updateUser({
                                                                                    is_blocked: false,
                                                                                    blocked_note: '',
                                                                                    blocked_date: displayToApiDate('')
                                                                                })
                                                                            }
                                                                        } }
                                                                    />
                                                                    <label
                                                                        htmlFor="is_blocked"
                                                                        data-on-label="Ja"
                                                                        data-off-label="Nein"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { user.is_blocked && (
                                                        <>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            htmlFor="blocked_note"
                                                                            className="form-label"
                                                                        >
                                                                            Hinweis zur Sperrung
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="blocked_note"
                                                                            rows={5}
                                                                            value={ user.blocked_note }
                                                                            onChange={ (e) => {
                                                                                updateUser({
                                                                                    blocked_note: e.target.value
                                                                                })
                                                                            } }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-4">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            htmlFor="blocked_date"
                                                                            className="form-label"
                                                                        >
                                                                            Sperrdatum
                                                                        </label>
                                                                        <input
                                                                            type="date"
                                                                            id="blocked_date"
                                                                            className="form-control"
                                                                            value={ apiToDisplayDate(user.blocked_date) }
                                                                            onChange={ (e) => {
                                                                                updateUser({
                                                                                    blocked_date: displayToApiDate(e.target.value)
                                                                                })
                                                                            } }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) } 
                                                </div>
                                            ) }
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button 
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >{ isNew ? 'Kontakt erstellen' : 'Kontakt aktualisieren' }</button>
                                                <Link to="/admin/users">
                                                    <button
                                                        type="button"
                                                        className="btn btn-light ms-2"
                                                    >Abbrechen</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>{" "}
                                {/* end card-body */}
                            </div>{" "}
                            {/* end card*/}
                        </div>{" "}
                        {/* end col*/}
                    </div>
                    {/* end row*/}
                </>
            ) }
        </div>
    )
}

export default User