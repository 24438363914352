export const processAppSettingsLanguages = (rawLanguages: any[]): any[] => {
    rawLanguages.sort(function(a, b) {
        const langA = a.value.toUpperCase();
        const langB = b.value.toUpperCase();
        return (langA < langB) ? -1 : (langA > langB) ? 1 : 0;
    })

    if(rawLanguages) {
        return rawLanguages.map(({key, value}) => ({
            key,
            value,
        }))
    } else {
        return []
    }
}

export const processAppSettingsKeyValueArray = (array: any[]): any[] => {
    return array.map(({key, value}) => ({
        key,
        value,
    }))
} 

export const processStaticSettingsObject = (object: any): any[] => {
    return Object.entries(object).map(([key, value]) => ({
        key,
        value,
    }))
}