// @ts-nocheck
import {
    useParams,
    useHistory
  } from "react-router-dom";
  
import CustomerProfile from 'components/CustomerProfile'

const CustomerCustomer = () => {
    const { id } = useParams()

    return (
        <CustomerProfile
            isMyProfile={ false }
            id={id}
        />
    )
}

export default CustomerCustomer