// @ts-nocheck
import { useState, useEffect } from 'react'
import { getRequest } from "clients/ApiClient"
import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"
import { useBackgroundJobs } from "contexts/BackgroundJobsContext"
import {
    processAppSettingsKeyValueArray,
    processStaticSettingsObject,
} from 'utils/settings'

import { toast } from 'react-toastify';

const fetchTask = async (taskId, setTask, setIsLoading, setError, authContext, fetchActivity, setActivity) => {
    
    setIsLoading(true)
    try {
        const task = await getRequest(`/tasks/${taskId}`)

        if(!task.data.code) {
            setTask(task.data)
            const activity = await fetchActivity(task.data.id, authContext)
            setActivity(activity)
        }
        else {
            switch(task.data.code) {
                case 404:
                case 400:
                    console.log(task)
                    throw new Error('Der Auftrag wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden des Auftrags ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setTask(undefined)
        setError(e.message)
    }
    setIsLoading(false)
}

const fetchActivity = async (taskId, authContext) => {
    const { authGetRequest } = authContext

    const activity = await authGetRequest(`/activity/by-task/${taskId}`)

    if(!activity.data.code) {
        return activity.data
    }
    else {
        switch(activity.data.code) {
            case 404:
            case 400:
                return undefined
            default:
            case 500:
                throw new Error('Beim Laden des Verlaufs ist ein Fehler aufgetreten')
        }
    }
}

const TaskController = ({
    id
}) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()
    const backgroundJobs = useBackgroundJobs()
    const [task, setTask] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [paymentStep, setPaymentStep] = useState()
    const [activity, setActivity] = useState([])
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
    const [hasEstimationError, setHasEstimationError] = useState(false)
    const [isEstimationLoading, setIsEstimationLoading] = useState(false)

    const languages = processAppSettingsKeyValueArray(appSettings.appSettings.languages)
    const taskTypes = processAppSettingsKeyValueArray(appSettings.appSettings.task_types)
    const statuses = processStaticSettingsObject(appSettings.staticSettings.statuses)

    const isMyTask = task?.customer?.id === authContext.currentUser.id
    const isTaskAssignedToMe = task && (
        ["interpretation", "lesson"].includes(task.task_type) 
            ? task.interpretations.filter(i => i.translator?.id === authContext.currentUser.id).length > 0 
            : task.files.filter(f => f.translator?.id === authContext.currentUser.id).length > 0 
        )

    useEffect(() => {
        return () => {
            backgroundJobs.clearAllSubscriptions()
        }
    }, [])

    useEffect(() => {
        backgroundJobs.clearAllSubscriptions()
        if(id) {
            fetchTask(id, setTask, setIsLoading, setError, authContext, fetchActivity, setActivity)
            setIsEstimationLoading(backgroundJobs.hasOngoingJob(id))
            backgroundJobs.subscribeTask(id, (resultingTask) => {
                setTask(resultingTask)
                setIsEstimationLoading(false)
            })
        }
        else {
            setTask(undefined)
            setError('Der Auftrag wurde entweder gelöscht oder konnte nicht gefunden werden.')
        }
    }, [id])

    const refetchTask = () => {
        fetchTask(id, setTask, setIsLoading, setError, authContext, fetchActivity, setActivity)
    }

    const refetchActivity = async () => {
        const act = await fetchActivity(task.id, authContext)
        setActivity(act)
    }

    const setFileStatus = async (file, status) => {
        if(!isTaskAssignedToMe) return
        if(task.status !== "in_progress") return

        try {
            const res = await authContext.authPostRequest(`/my/translator/set-file-status/${task.id}/${file._id}`, {
                status
            })

            if(!res.data.code && res.data) {
                setTask(res.data)
                refetchActivity()
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                    default:
                    case 500:
                        throw new Error('Beim Ändern des Status ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }

    const updateTaskStatus = async (status) => {
        if(!isMyTask) return

        try {
            const res = await authContext.authPostRequest(`/my/customer/set-task-status/${task.id}`, {
                status
            })

            if(!res.data.code && res.data) {
                setTask(res.data)
                refetchActivity()
                setIsUpdatingStatus(false)
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                    default:
                    case 500:
                        throw new Error('Beim Ändern des Status ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }

    return {
        isLoading,
        error,
        languages,
        taskTypes,
        task,
        setTask,
        statuses,
        isMyTask,
        isTaskAssignedToMe,
        paymentStep,
        setPaymentStep,
        refetchTask,
        activity,
        currentUser: authContext.currentUser,
        setFileStatus,
        isUpdatingStatus,
        setIsUpdatingStatus,
        updateTaskStatus,
        refetchActivity,
        hasEstimationError,
        isEstimationLoading,
    }
}

export default TaskController
