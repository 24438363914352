// @ts-nocheck
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const TaskStatusShareChart = ({ data, view }) => {
    let COLORS = []

    if(view === "admin") {
        COLORS = ['#6c757d', '#0acf97', '#39b0d1', '#f29500', '#fa5c7c'];
    } else if(view === "translator") {
        COLORS = ['#39b0d1', '#f29500', '#fa5c7c'];
    }

    return (
        <ResponsiveContainer width="100%" height={255}>
            <PieChart>
                <Pie
                data={data}
                cy={120}
                innerRadius={80}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
                >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
}

export default TaskStatusShareChart