// @ts-nocheck
import React, { useEffect, useState } from "react";
import Participant from "./Participant";

const Room = ({ room }) => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} />
  ));

  return (
    <div className="room">
      <div className="local-participant">
        {room && (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            isLocal={true}
          />
        )}
      </div>
      { participants.length > 0 && (
        <div className="remote-participant">
          <Participant 
            key={participants[0].sid} 
            participant={participants[0]}
            isLocal={false}
          />
        </div>
      ) }
    </div>
  );
};

export default Room;
