// @ts-nocheck
import {
    useHistory
  } from "react-router-dom";
  
import CustomerProfile from 'components/CustomerProfile'

const CustomerCustomer = () => {

    return (
        <CustomerProfile
            isMyProfile={ true }
        />
    )
}

export default CustomerCustomer