// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import moment from 'moment'

import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"

let latestTimestamp
let oldestTimestamp
let hasNoOlderChats = false
let isLoadingNewChats = false

const fetchInitialChats = async (setChats, setIsLoading, authContext, chatListFrame) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        const chats = await authGetRequest(`/my/chats?limit=20`)

        if(!chats.data.code) {
            chats.data.sort((a, b) => {
                const aLastSent = moment(a.last_message_sent_at)
                const bLastSent = moment(b.last_message_sent_at)
                if(aLastSent.isBefore(moment(bLastSent))) {
                    return 1
                }
                else if(moment(bLastSent).isBefore(moment(aLastSent))) {
                    return -1
                }
                return 0
            })

            setChats(chats.data)
            let localLatestTimestamp
            let localOldestTimestamp
            chats.data.forEach(chat => {
                const timestampObject = moment.utc(chat.last_message_sent_at)
                
                if(!localOldestTimestamp || timestampObject.isBefore(localOldestTimestamp)) {
                    localOldestTimestamp = timestampObject
                }
                if(!localLatestTimestamp || timestampObject.isAfter(localLatestTimestamp)) {
                    localLatestTimestamp = timestampObject
                }
            })

            if(localOldestTimestamp) {
                oldestTimestamp = localOldestTimestamp
            }
            if(localLatestTimestamp) {
                latestTimestamp = localLatestTimestamp
            }

            setTimeout(() => {
                chatListFrame.current.scrollTop = 0
            }, 0)
        }
        else {
            switch(chats.data.code) {
                case 404:
                case 400:
                    throw new Error('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden des Kontakes ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setChats([])
        toast.error(e.chat)
    }
    setIsLoading(false)
}

const fetchLatestChats = async (setChats, setIsLoading, authContext, chatListFrame) => {
    const { authGetRequest } = authContext
    
    try {
        const chats = await authGetRequest(`/my/chats?dateFrom=${latestTimestamp ? latestTimestamp.format('YYYY-MM-DD HH:mm:ss') : ''}`)

        if(!chats.data.code) {
            const hasNewChats = chats.data.length > 0
            
            if(hasNewChats) {
                const shouldScrollToTop = chatListFrame.current.scrollTop <= 20

                setChats((prevChats) => [
                    ...chats.data,
                    ...prevChats.filter((chat) => !chats.data.find(c => c.id === chat.id))
                ])
    
                chats.data.forEach(chat => {
                    const timestampObject = moment.utc(chat.created)
                    
                    if(!timestampObject || timestampObject.isAfter(latestTimestamp)) {
                        latestTimestamp = timestampObject
                    }
                })

                setTimeout(() => {
                    if(shouldScrollToTop) {
                        chatListFrame.current.scrollTop = 0
                    }
                }, 0)
            }
        }
        else {
            switch(chats.data.code) {
                case 404:
                case 400:
                    throw new Error('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden der Nachrichten ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setChats([])
        toast.error(e.chat)
    }
}

const fetchOldestChats = async (setChats, setIsLoading, authContext, chatListFrame) => {
    isLoadingNewChats = true
    setIsLoading(true)
    
    const { authGetRequest } = authContext
    
    try {
        const chats = await authGetRequest(`/my/chats?limit=20&dateTo=${oldestTimestamp ? oldestTimestamp.format('YYYY-MM-DD HH:mm:ss') : ''}`)

        if(!chats.data.code) {
            const hasOlderChats = chats.data.length > 0
            
            if(hasOlderChats) {

                chats.data.sort((a, b) => {
                    const aLastSeng = moment(a.last_message_sent_at)
                    const bLastSent = moment(b.last_message_sent_at)
                    if(aLastSeng.isBefore(moment(bLastSent))) {
                        return 1
                    }
                    else if(moment(bLastSent).isBefore(moment(aLastSeng))) {
                        return -1
                    }
                    return 0
                })

                setChats((prevChats) => [
                    ...prevChats.filter((chat) => !chats.data.find(c => c.id === chat.id)),
                    ...chats.data
                ])
    
                chats.data.forEach(chat => {
                    const timestampObject = moment.utc(chat.last_message_sent_at)
                    
                    if(!timestampObject || timestampObject.isBefore(oldestTimestamp)) {
                        oldestTimestamp = timestampObject
                    }
                })
            }
            else {
                hasNoOlderChats = true
            }
        }
        else {
            switch(chats.data.code) {
                case 404:
                case 400:
                    throw new Error('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden der Nachrichten ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setChats([])
        toast.error(e.chat)
    }

    isLoadingNewChats = false
    setIsLoading(false)
}

const ChatsController = (props) => {
    const authContext = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [chats, setChats] = useState([])
    const chatListFrame = useRef(null)
    const chatListContainer = useRef(null)

    const processScroll = () => {
        const isScrolledDown = chatListFrame.current.scrollTop >= chatListFrame.current.scrollHeight - chatListFrame.current.clientHeight - 20
        if(!hasNoOlderChats && !isLoadingNewChats && isScrolledDown) {
            fetchOldestChats(setChats, setIsLoading, authContext, chatListFrame)
        }
    }

    const markChatAsRead = (chatId) => {
        setChats((prevChats) => prevChats.map((chat) => {
            if(chat.id === chatId) {
                return {
                    ...chat,
                    unseen_messages: 0
                }
            }
            else {
                return chat
            }
        }))
    }

    useEffect(() => {
        let interval
        latestTimestamp = undefined
        oldestTimestamp = undefined
        hasNoOlderChats = false
        isLoadingNewChats = false
        fetchInitialChats(setChats, setIsLoading, authContext, chatListFrame).then(() => {
            interval = setInterval(() => {
                fetchLatestChats(setChats, setIsLoading, authContext, chatListFrame)
            }, 2000)
            chatListFrame.current.addEventListener('scroll', processScroll)
        })

        return () => {
            clearInterval(interval)
            chatListFrame.current?.removeEventListener('scroll', processScroll)
        }
    }, [])

    return {
        currentUser: authContext.currentUser,
        isLoading,
        chats,
        chatListFrame,
        chatListContainer,
        markChatAsRead,
    }
}

export default ChatsController