// @ts-nocheck
import { useEffect, useState, useRef } from "react"

const TaskPriceEstimationLoading = () => {
    const [step, setStep] = useState(0)
    const interval = useRef(null)

    useEffect(() => {
        interval.current = setInterval(() => {
            setStep(s => {
                return s > 4 ? 0 : s + 1
            })
        }, 1000)

        return () => {
            if(interval.current) {
                clearInterval(interval.current)
            }
        }
    }, [])

    let dots = ""

    for(let i = 0; i <= step; i++) {
        dots += "."
    }

    return (
        <div className="card">
            <div className="card-body border">
                Schätzung des Preises für diesen Aufrag läuft{dots}
            </div>
        </div>
    )
}

export default TaskPriceEstimationLoading