// @ts-nocheck
import TranslatorDashboardController from 'screens/translator/TranslatorDashboard/TranslatorDashboard.controller'
import TranslatorDashboardSkeleton from 'screens/translator/TranslatorDashboard/TranslatorDashboard.skeleton'

import TaskStatusShareChart from 'components/Dashboard/TaskStatusShareChart'
import RevenueChart from 'components/Dashboard/RevenueChart'

const TranslatorDashboard = () => {
    const {
        metrics,
        isLoading,
        error,
    } = TranslatorDashboardController()

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <h4 className="page-title">Dashboard</h4>
                    </div>
                </div>
            </div>

            {/* end page title */}
            { !metrics && (isLoading || error) && (
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {isLoading && <TranslatorDashboardSkeleton />}
                                {error && (
                                    <div>{error}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) }
            { metrics && (
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title mb-4">Umsatz (soll und ist)</h4>
                                    <RevenueChart
                                        data={metrics.revenue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="header-title mb-4">Status meiner Aufträge</h4>
                                    <TaskStatusShareChart data={[
                                        { name: 'In Bearbeitung', value: metrics.my_tasks_in_progress },
                                        { name: 'Abgeschlossen', value: metrics.my_tasks_completed },
                                        { name: 'Storniert', value: metrics.my_tasks_cancelled },
                                    ]} view="translator" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row g-0">
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-bottom">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-checklist text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.my_tasks_total }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Meine Aufträge (gesamt)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-start border-bottom">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-document-edit text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.my_tasks_in_progress }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Meine Aufträge (in Bearbeitung)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-start border-bottom">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-exit text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.my_tasks_completed }</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Meine Aufträge (abgeschlossen)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-0">
                                        <div className="col-4">
                                            <div className="card shadow-none m-0">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-graph-line text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.revenue_total } €</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Umsatz (gesamt)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-start">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-graph-line text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.revenue_this_month } €</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Umsatz (diesen Monat)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="card shadow-none m-0 border-start">
                                                <div className="card-body text-center">
                                                    <i
                                                        className="dripicons-graph-line text-muted"
                                                        style={{ fontSize: "24px" }}
                                                    />
                                                    <h3>
                                                        <span>{ metrics.revenue_last_month } €</span>
                                                    </h3>
                                                    <p className="text-muted font-15 mb-0">
                                                        Umsatz (letzten Monat)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) }
        </>
    )
}

export default TranslatorDashboard
