// @ts-nocheck
import moment from "moment"
import { Link, useHistory, useLocation } from "react-router-dom";
import ProfilePicture from 'components/ProfilePicture/ProfilePicture'

const ProfileCardContent = ({
    user,
    profile, 
    isMyProfile, 
    isFirstTimeProfile, 
    handleSubmitForm, 
    handleCancelForm,
    showTab, 
    setShowTab,
    languages,
    view,
}) => {
    const history = useHistory()
    const location = useLocation()

    const viewer = location.pathname.substr(0, '/customer'.length) === '/customer' ? 'customer' : 'translator'

    const createTask = () => {
        const isSchool = profile.type === "school" ? "1" : ""
        history.push(`/customer/my-tasks/new/translation?userId=${user.id}&isSchool=${isSchool}`)
    }

    return (
        <>
            <ProfilePicture
                profile={profile}
                isMyProfile={isMyProfile}
            />
            <h4 className="mb-0 mt-2">{ user.is_company ? user.company_name : `${user.first_name} ${user.last_name}` }</h4>
            <p className="text-muted font-14">
                { profile.type === 'school' && (user.is_company ? 'Sprachschule' : 'Ansprechpartner') }
                { profile.type !== 'school' && !user.is_company && [profile.translator_profile?.is_translator && 'Übersetzer:in', profile.translator_profile?.is_interpreter && 'Dolmetscher:in'].filter(e => !!e).join(' / ') }
            </p>
            { !isMyProfile ? (
                <>
                    <Link
                        to={`/${viewer}/messenger/${user.id}`}
                        className="btn btn-danger btn-sm mb-2 ms-1"
                    >
                        Nachricht senden
                    </Link>
                    { view === "translator" && (
                        <button
                            type="button"
                            className="btn btn-dark btn-sm mb-2 ms-1"
                            onClick={ createTask }
                        >
                            Auftrag / Anfrage erstellen
                        </button>
                    ) }
                </>
            ) : isFirstTimeProfile ? (
                <button
                    type="button"
                    className="btn btn-primary btn-sm mb-2"
                    onClick={ handleSubmitForm }
                >
                    Profil erstellen
                </button>
            ) : showTab === 'info' ? (
                <button
                    type="button"
                    className="btn btn-light btn-sm mb-2"
                    onClick={ () => setShowTab('update') }
                >
                    Profil bearbeiten
                </button>
            ) : showTab === 'update' && (
                <>
                    <button
                        type="button"
                        className="btn btn-dark btn-sm mb-2"
                        onClick={ handleSubmitForm }
                    >
                        Profil aktualisieren
                    </button>
                    <button
                        type="button"
                        className="btn btn-light btn-sm mb-2"
                        onClick={ handleCancelForm }
                    >
                        abbrechen
                    </button>
                </>
            ) }
            <div className="text-start mt-3">
                { profile.translator_profile?.description && (
                    <>
                        <h4 className="font-13 text-uppercase">
                            Beschreibung:
                        </h4>
                        <p className="text-muted font-13 mb-3">
                            { profile.translator_profile.description?.split("\n").map(line => (
                                <>
                                    {line}
                                    <br/>
                                </>
                            )) }
                        </p>
                    </>
                ) }
                <p className="text-muted font-13">
                    <strong>Mitglied seit: </strong> { moment(user.created).format('DD.MM.YYYY') }
                </p>
                { profile.translator_profile?.languages.length > 0 && (
                    <p className="text-muted font-13">
                        <strong>Sprachen: </strong> { profile.translator_profile?.languages.map((l) => {
                            const languageName = languages.find(lg => lg.key === l.language)?.value
                            if(!languageName) {
                                return undefined
                            }

                            return (l.is_native) ? `${languageName} (Muttersprache)` : languageName
                        }).filter(l => !!l).join(' - ') }
                    </p>
                ) }
                { profile.translator_profile?.keywords.length > 0 && (
                    <p className="text-muted font-13">
                        <strong>Fähigkeiten: </strong> { profile.translator_profile?.keywords.join(', ') }
                    </p>
                ) }
            </div>
        </>
    )
}

export default ProfileCardContent