// @ts-nocheck
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";

import { getRequest } from "clients/ApiClient"
import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"
import {
    processAppSettingsKeyValueArray,
    processStaticSettingsObject,
} from 'utils/settings'

const fetchTasks = async (setTasks, { sortBy, limit, page, q, type }, setIsLoading, authContext) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    const user = await authGetRequest(`/my/translator/tasks?sortBy=${sortBy}&limit=${limit}&page=${page}&q=${q}&type=${type}`)
    setTasks(user.data)
    setIsLoading(false)
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const getDefaultSearchCriteria = (query) => {
    return {
        q: query.get('q'),
        type: query.get('type') ? query.get('type') : 'all'
    }
}

const TranslatorTasksController = (props) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()

    const [tasks, setTasks] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const languages = processAppSettingsKeyValueArray(appSettings.appSettings.languages)
    const taskTypes = processAppSettingsKeyValueArray(appSettings.appSettings.task_types)
    const statuses = processStaticSettingsObject(appSettings.staticSettings.statuses)

    const history = useHistory()
    const query = useQuery()

    const [searchCriteria, setSearchCriteria] = useState(getDefaultSearchCriteria(query))

    const sortBy = query.get('sortBy') || 'entry_date'
    const limit = query.get('limit') || 10
    const page = query.get('page') || 1
    const q = query.get('q') || ''
    const type = query.get('type') || 'all';

    const handleUpdateSearch = (e) => {
        e.preventDefault()
        const {
            q,
            type,
        } = searchCriteria

        history.push(buildLink({
            q: q || "",
            type
        }))
    }

    useEffect(() => {
        fetchTasks(setTasks, { sortBy, limit, page, q, type }, setIsLoading, authContext)
    }, [sortBy, limit, page, q, type])

    const buildLink = ({ 
        page: newPage, 
        limit: newLimit, 
        sortBy: newSortBy,
        q: newQ,
        type: newType,
    }) => {
        return `/translator/tasks?page=${newPage || page}&limit=${newLimit || limit}&sortBy=${newSortBy || sortBy}` + 
                `&q=${newQ !== undefined ? newQ : q}&type=${newType !== undefined ? newType : type}`;
    }

    return {
        tasks,
        setTasks,
        isLoading,
        error,
        languages,
        statuses,
        taskTypes,
        buildLink,
        searchCriteria,
        setSearchCriteria,
        handleUpdateSearch,
    }
}

export default TranslatorTasksController