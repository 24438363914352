// @ts-nocheck
import React, { useState } from "react"

import { Link, useHistory } from "react-router-dom"

import { toast } from 'react-toastify';
import { useAuth } from "contexts/AuthContext"

const Login = () => {
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isUserNotVerified, setIsUserNotVerified] = useState(false)
    const { login } = useAuth()
    const history = useHistory()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        if(isLoading) {
            return
        }

        setIsLoading(true)

        try {
            const loginStatus = await login(e.target.elements.emailaddress.value, e.target.elements.password.value)
            switch(loginStatus) {
                case "INVALID_LOGIN_ATTEMPT":
                    setError("Falscher Benutzername oder falsches Passwort")
                    break
                case "MEMBER_BLOCKED":
                    setError("Ihr Konto wurde gesperrt")
                    break
                case "SERVER_ERROR":
                    throw new Error("")
                    break
                case "MEMBER_NOT_VERIFIED":
                    setIsUserNotVerified(true)
                    break
                default:
                    history.push("/")
                    break
            }
        }
        catch (e) {
            setError("Beim Einloggen ist ein Fehler aufgetreten. Bitte aktualisieren Sie die Seite und versuchen Sie es später erneut.")
        }

        setIsLoading(false)
    }

    return (
        <>
            <h4 className="mt-0">Einloggen</h4>
            <p className="text-muted mb-4">Bitte Email Addresse und Kenttwort eingeben.</p>
            { error && (
                <div className="alert alert-danger">{ error }</div>
            ) }

            { isUserNotVerified ? (
                <div>
                    <h2 className="mb-4">Ihr Konto ist nicht verifiziert</h2>
                    <p>
                        Wir haben Ihnen eine E-Mail mit den Anweisungen zur Verifizierung Ihrer E-Mail-Adresse geschickt, als Sie Ihr Konto bei uns erstellt haben
                    </p>
                    <p>
                        Bitte klicken Sie auf den dort angegebenen Link, um fortzufahren.
                    </p>
                    <p>
                        Wenn Sie dies bereits getan haben oder sich mit einer anderen E-Mail-Adresse anmelden möchten, klicken Sie bitte 
                        <a
                            href="#"
                            onClick={ (e) => {
                                e.preventDefault()
                                setIsUserNotVerified(false)
                            }}
                        > here</a>
                    </p>
                </div>
            ) : (
                <>
                    <form action="#" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="emailaddress" className="form-label">Email Addresse</label>
                            <input className="form-control" type="email" name="emailaddress" id="emailaddress" required />
                        </div>
                        <div className="mb-3">
                            <Link to="/auth/password" className="text-muted float-end">
                                <small>Kennwort vergessen?</small>
                            </Link>
                            <label htmlFor="password" className="form-label">Kennwort</label>
                            <input className="form-control" type="password" required id="password" name="password" />
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="remember-me" name="remember-me" />
                                <label className="form-check-label" htmlFor="remember-me">Angemeldet bleiben</label>
                            </div>
                        </div>
                        <div className="d-grid mb-0 text-center">
                            <button className="btn btn-primary" type="submit" disabled={ isLoading }><i className="mdi mdi-login"></i> Einloggen </button>
                        </div>
                    </form>

                    <footer className="footer footer-alt">
                        <p className="text-muted">Noch kein Konto? <Link to="/auth/register" className="text-muted ms-1"><b>Konto erstellen</b></Link></p>
                    </footer>
                </>
            ) }
        </>
    )
}

export default Login