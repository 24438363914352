// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom"

import SettingsController from 'controllers/Settings.controller'
import SettingsSkeleton from 'screens/skeletons/Settings.skeleton'
import GeneralError from 'screens/errors/GeneralError'

import { apiToDisplayDate, displayToApiDate } from 'utils/datetime'

const AdminAppSettings = (props) => {
    
    const {
        currentUser,
        isLoading,
        error,
        handleSubmitGeneralForm,
        handleSubmitSecurityForm,
        generalFormValidationErrors,
        securityFormValidationErrors,
        showTab, 
        setShowTab,
    } = SettingsController(props)

    return (
        <div>
            { isLoading && <SettingsSkeleton /> }
            { error && (
                <GeneralError
                    title="Verwaltung / Systemeinstellungen"
                >{ error }</GeneralError>
            ) }
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <h4 className="page-title">Einstellungen</h4>
                    </div>
                </div>
            </div>
            {/* end page title */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-bordered mb-3">
                                <li className="nav-item">
                                    <a
                                        href='#'
                                        className={ `nav-link rounded-0 ${showTab === 'general' ? 'active' : ''}` }
                                        onClick={ (e) => {
                                            e.preventDefault();
                                            setShowTab('general')
                                        } }
                                    >
                                        Allgemein
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        href='#'
                                        className={ `nav-link rounded-0 ${showTab === 'security' ? 'active' : ''}` }
                                        onClick={ (e) => {
                                            e.preventDefault();
                                            setShowTab('security')
                                        } }
                                    >
                                        Kennwort ändern
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                { showTab === 'general' && (
                                    <div
                                        className="tab-pane active mb-3"
                                    >
                                        <form action="#" onSubmit={ handleSubmitGeneralForm }>
                                            { Object.values(generalFormValidationErrors).map((error, i) => (
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div key={i} className="alert alert-danger">{ error }</div>
                                                    </div>
                                                </div>
                                            )) }
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="first_name"
                                                            className="form-label"
                                                        >
                                                            Vorname
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="first_name"
                                                            className="form-control"
                                                            defaultValue={currentUser.first_name}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="last_name"
                                                            className="form-label"
                                                        >
                                                            Nachname
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="last_name"
                                                            className="form-control"
                                                            defaultValue={currentUser.last_name}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button 
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >Aktualisieren</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ) }

                                { showTab === 'security' && (
                                    <div
                                        className="tab-pane active mb-3"
                                    >
                                        <form action="#" onSubmit={ handleSubmitSecurityForm }>
                                            { Object.values(securityFormValidationErrors).map((error, i) => (
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div key={i} className="alert alert-danger">{ error }</div>
                                                    </div>
                                                </div>
                                            )) }
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="current_password"
                                                            className="form-label"
                                                        >
                                                            Aktuelles Kennwort
                                                        </label>
                                                        <input
                                                            type="password"
                                                            id="current_password"
                                                            name="current_password"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="new_password"
                                                            className="form-label"
                                                        >
                                                            Neues Kennwort
                                                        </label>
                                                        <input
                                                            type="password"
                                                            id="new_password"
                                                            name="new_password"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="new_password_confirm"
                                                            className="form-label"
                                                        >
                                                            Neues Kennwort bestätigen
                                                        </label>
                                                        <input
                                                            type="password"
                                                            id="new_password_confirm"
                                                            name="new_password_confirm"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <button 
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >Aktualisieren</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                ) }
                            </div>
                        </div>{" "}
                        {/* end card-body */}
                    </div>{" "}
                    {/* end card*/}
                </div>{" "}
                {/* end col*/}
            </div>
        </div>
    )
}

export default AdminAppSettings