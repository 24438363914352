// @ts-nocheck
import React, { useContext, useState, useEffect, useRef } from "react"
// import { postRequest, putRequest, patchRequest, getRequest, deleteRequest } from "clients/ApiClient"
import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"

const BackgroundJobsContext = React.createContext()

export const useBackgroundJobs = () => {
    return useContext(BackgroundJobsContext)
}

const getPendingTasks = async (authGetRequest) => {
    try {
        const pendingTasks = await authGetRequest("/my/customer/get-tasks-to-estimate")

        if(!pendingTasks.data.code) {
            return pendingTasks.data
        }
    }
    catch(e) {
        console.error(e)
    }

    return []
}

export const BackgroundJobsProvider = ({ children }) => {
    const { appSettings } = useAppSettings()
    const { currentUser, authPostRequest, authGetRequest } = useAuth()

    const subscribedTasks = useRef({})
    const ongoingJobs = useRef([])
    
    useEffect(() => {
        initializeApp()
    }, [])

    const initializeApp = async () => {
        if(!appSettings.is_automatic_task_calculation_enabled || !currentUser) {
            return
        }

        const pendingTasks = await getPendingTasks(authGetRequest)
        for(const pendingTask of pendingTasks) {
            runBackgroundJob(pendingTask)
        }
    }

    const subscribeTask = (taskId, callback) => {
        console.log("subscribeTask")
        subscribedTasks.current[taskId] = callback
    }

    const clearAllSubscriptions = () => {
        subscribedTasks.current = {}
    }

    const runBackgroundJob = async (taskId) => {
        console.log("runBackgroundJob", taskId)
        ongoingJobs.current.push(taskId)
        const res = await authPostRequest(`/my/customer/estimate-task-price/${taskId}`)
        ongoingJobs.current = ongoingJobs.current.filter(j => j !== taskId)

        if(!res.data.code && subscribedTasks.current[taskId]) {
            const callback = subscribedTasks.current[taskId]
            callback(res.data)
        }
    }

    const hasOngoingJob = (taskId) => {
        return ongoingJobs.current.includes(taskId)
    }

    const value = {
        subscribeTask,
        runBackgroundJob,
        clearAllSubscriptions,
        hasOngoingJob,
    }

    return (
        <BackgroundJobsContext.Provider value={value}>
            { children }
        </BackgroundJobsContext.Provider>
    )
}