// @ts-nocheck
import { useState, useEffect } from 'react'
import {
    useParams,
    useHistory
  } from "react-router-dom";
import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"

const getNewProfile = () => {
    return { 
        description: "",
    }
}

const fetchCustomerProfile = async (customerProfileId, setCustomerProfile, authContext, setIsLoading, setError) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        const userCustomerProfile = await authGetRequest(`/customers/${customerProfileId}`)

        if(!userCustomerProfile.data.code) {
            const customerProfile = {
                user: {...userCustomerProfile.data},
                profile: {...userCustomerProfile.data.profiles.find(p => p.role === 'customer')}
            }
        
            if(customerProfile.profile.company) {
                setIsLoading(true)
        
                const [company, error] = await fetchCompanyProfile(customerProfile.profile.company.id, authContext)
                setIsLoading(false)
        
                if(error) {
                    setError(error)
                    return
                }
        
                customerProfile.companyUser = company.user
                customerProfile.companyProfile = company.profile
            }
        
            setCustomerProfile(customerProfile)
        }
        else {
            switch(userCustomerProfile.data.code) {
                case 404:
                case 400:
                    console.log(userCustomerProfile)
                    throw new Error('Der Profil wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden des Profils ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setCustomerProfile(undefined)
        setError(e.message)
    }
    setIsLoading(false)
}

const fetchCompanyProfile = async (companyProfileId, authContext) => {
    const { authGetRequest } = authContext
    let company
    let error
    try {
        const companyProfile = await authGetRequest(`/customers/${companyProfileId}`)

        if(!companyProfile.data.code) {
            company = {
                user: {...companyProfile.data},
                profile: {...companyProfile.data.profiles.find(p => p.role === 'customer')}
            }
        }
        else {
            switch(companyProfile.data.code) {
                case 404:
                case 400:
                    console.log(companyProfile)
                    throw new Error('Der Profil wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden des Profils ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        error = e.message
    }
    
    return [company, error]
}

const setCustomerProfileFromAuthContext = async (
    setCustomerProfile, 
    authContext, 
    setIsLoading, 
    setError, 
    setOriginalCustomerProfile,
    setIsFirstTimeProfile,
    setShowTab,
    updateProfile,
    updateCompanyProfile
) => {
    const customerProfile = {
        user: {...authContext.currentUser},
        profile: {...authContext.currentUser.activeProfile}
    }

    if(customerProfile.profile.company) {
        setIsLoading(true)

        const [company, error] = await fetchCompanyProfile(customerProfile.profile.company.id, authContext)
        setIsLoading(false)

        if(error) {
            setError(error)
            return
        }

        customerProfile.companyUser = company.user
        customerProfile.companyProfile = company.profile
    }

    setCustomerProfile(customerProfile)
    setOriginalCustomerProfile({
        ...customerProfile
    })

    if(!authContext.currentUser.activeProfile?.is_completed) {
        setIsFirstTimeProfile(true)
        setShowTab('update')
        updateProfile({
            customer_profile: getNewProfile()
        })
        if(customerProfile.companyUser) {
            updateCompanyProfile({
                customer_profile: getNewProfile()
            })
        }
    }
}

const CustomerProfileController = ({
    isMyProfile,
    id
}) => {
    const authContext = useAuth()

    const [customerProfile, setCustomerProfile] = useState()
    const [originalCustomerProfile, setOriginalCustomerProfile] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [validationErrors, setValidationErrors] = useState({})
    const [isFirstTimeProfile, setIsFirstTimeProfile] = useState(false)
    const [showTab, setShowTab] = useState('info')
    const [showProfile, setShowProfile] = useState(0)

    useEffect(() => {
        if(isMyProfile) {
            setCustomerProfileFromAuthContext(
                setCustomerProfile, 
                authContext, 
                setIsLoading, 
                setError, 
                setOriginalCustomerProfile,
                setIsFirstTimeProfile,
                setShowTab,
                updateProfile,
                updateCompanyProfile
            )
        }
        else if(id) {
            fetchCustomerProfile(id, setCustomerProfile, authContext, setIsLoading, setError)
        }
        else {
            setCustomerProfile(undefined)
            setError('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
        }
    }, [isMyProfile, id, authContext])

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        try {
            const res = await authContext.authPatchRequest(`/my/customer-profile`, {
                ...customerProfile.profile,
                is_completed: true
            })
            
            if(!res.data.code) {
                if(customerProfile?.companyProfile) {
                    const res2 = await authContext.authPatchRequest(`/my/customer-profile-company`, {
                        ...customerProfile.companyProfile,
                        is_completed: true
                    })

                    if(res2.data.code) {
                        switch(res.data.code) {
                            case 400:
                                throw new Error(res.data.message)
                            default:
                            case 500:
                                throw new Error('Beim Aktualisieren Ihres Profils ist ein Fehler aufgetreten')
                        }
                    }
                }

                toast.success('Ihr Profil wurde erfolgreich aktualisiert')
                setShowTab('info')
                setIsFirstTimeProfile(false)
                authContext.setCurrentUser({
                    ...res.data,
                    activeProfile: res.data.profiles.find(p => p.is_active_profile)
                })
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                    default:
                    case 500:
                        throw new Error('Beim Aktualisieren Ihres Profils ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }

    const updateProfile = (updates) => {
        setCustomerProfile((prevProfile) => ({
            user: prevProfile.user,
            companyUser: prevProfile.companyUser,
            companyProfile: prevProfile.companyProfile,
            profile: {
                ...prevProfile.profile,
                ...updates
            }
        }))
    }

    const updateProfileCustomerProfile = (updates) => {
        setCustomerProfile((prevProfile) => ({
            user: prevProfile.user,
            companyUser: prevProfile.companyUser,
            companyProfile: prevProfile.companyProfile,
            profile: {
                ...prevProfile.profile,
                customer_profile: {
                    ...prevProfile.profile.customer_profile,
                    ...updates
                }
            }
        }))
    }

    const updateCompanyProfile = (updates) => {
        setCustomerProfile((prevProfile) => ({
            user: prevProfile.user,
            companyUser: prevProfile.companyUser,
            profile: prevProfile.profile,
            companyProfile: {
                ...prevProfile.companyProfile,
                ...updates
            }
        }))
    }

    const updateCompanyProfileCustomerProfile = (updates) => {
        setCustomerProfile((prevProfile) => ({
            user: prevProfile.user,
            companyUser: prevProfile.companyUser,
            profile: prevProfile.profile,
            companyProfile: {
                ...prevProfile.companyProfile,
                customer_profile: {
                    ...prevProfile.companyProfile.customer_profile,
                    ...updates
                }
            }
        }))
    }

    const handleCancelForm = () => {
        setCustomerProfile({
            ...originalCustomerProfile
        })
        setShowTab('info')
    }

    return {
        user: customerProfile?.user,
        profile: customerProfile?.profile,
        companyUser: customerProfile?.companyUser,
        companyProfile: customerProfile?.companyProfile,
        showProfile, 
        setShowProfile,
        isLoading,
        error,
        showTab,
        setShowTab,
        handleSubmitForm,
        handleCancelForm,
        validationErrors,
        isFirstTimeProfile,
        updateProfile,
        updateProfileCustomerProfile,
        updateCompanyProfile,
        updateCompanyProfileCustomerProfile,
    }
}

export default CustomerProfileController