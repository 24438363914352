// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import moment from 'moment'

import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"

const ProfileController = (props) => {
    const authContext = useAuth()

    const [isLoading, setIsLoading] = useState(false)

    const callUser = async () => {
        try {
            setIsLoading(true)
            const res = await authContext.authPostRequest(`/my/video?userId=${props.user.id}`)

            if(!res.data.code) {
                window.location.replace(`/video/${res.data.id}#r=${window.location.href}`)
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                        break
                    case 401:
                        throw new Error("Ihre Session wurde beendet. Bitte loggen Sie sich erneut ein")
                        break
                    default:
                    case 500:
                        throw new Error('Beim Anrufen dieses Kontaktes ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }

        setIsLoading(false)
    }

    return {
        callUser,
        isLoading,
    }
}

export default ProfileController