// @ts-nocheck
import AdminAppSettingsController from 'controllers/admin/AdminAppSettings.controller'
import GeneralError from 'screens/errors/GeneralError'

const AdminAppSettings = (props) => {
    
    const {
        appSettings,
        error,
        handleSubmitForm,
        validationErrors,
        showTab, 
        setShowTab,
        updateAppSettings,
        addLanguage,
        updateLanguage,
        removeLanguage,
        addTaskType,
        updateTaskType,
        removeTaskType,
        updateHomescreenContent,
        addLanguagePricePerWord,
        updateLanguagePricePerWord,
        removeLanguagePricePerWord,
        addTaskTypePrice,
        updateTaskTypePrice,
        removeTaskTypePrice,
        confirmRemoveLanguage,
        clearRemoveLanguage,
        languageToConfirmDeletion,
    } = AdminAppSettingsController(props)

    return (
        <div>
            { languageToConfirmDeletion && (
                <div className="popup-backdrop">
                    <div className="popup">
                        <p>
                            Wollen Sie diese Sprache wirklich löschen?
                        </p>
                        <div className="text-end">
                            <button
                                className="btn btn-primary me-1"
                                onClick={() => {
                                    removeLanguage(languageToConfirmDeletion)
                                    clearRemoveLanguage()
                                }}
                            >Löschen</button>
                            <button
                                className="btn btn-light"
                                onClick={clearRemoveLanguage}
                            >Abbrechen</button>
                        </div>
                    </div>
                </div>
            ) }
            { error && (
                <GeneralError
                    title="Verwaltung / Systemeinstellungen"
                >{ error }</GeneralError>
            ) }
            { appSettings && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <h4 className="page-title"><strong className="text-black">Verwaltung</strong> / Systemeinstellungen</h4>
                            </div>
                        </div>
                    </div>
                    {/* end page title */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <form action="#" onSubmit={ handleSubmitForm }>
                                        <ul className="nav nav-tabs nav-bordered mb-3">
                                            <li className="nav-item">
                                                <a
                                                    href='#'
                                                    className={ `nav-link rounded-0 ${showTab === 'general' ? 'active' : ''}` }
                                                    onClick={ (e) => {
                                                        e.preventDefault();
                                                        setShowTab('general')
                                                    } }
                                                >
                                                    Allgemein
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    href='#'
                                                    className={ `nav-link rounded-0 ${showTab === 'homescreen' ? 'active' : ''}` }
                                                    onClick={ (e) => {
                                                        e.preventDefault();
                                                        setShowTab('homescreen')
                                                    } }
                                                >
                                                    Homepage-Inhalt
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    href='#'
                                                    className={ `nav-link rounded-0 ${showTab === 'task-price-calculation' ? 'active' : ''}` }
                                                    onClick={ (e) => {
                                                        e.preventDefault();
                                                        setShowTab('task-price-calculation')
                                                    } }
                                                >
                                                    Automatische Auftragspreiskalkulation
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            { showTab === 'general' && (
                                                <div
                                                    className="tab-pane active mb-3"
                                                >
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="description"
                                                                    className="form-label"
                                                                >
                                                                    Aktuelle Auftrag ID
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    id="hours_worked"
                                                                    className="form-control"
                                                                    value={ appSettings.current_task_id }
                                                                    onChange={ (e) => {
                                                                        updateAppSettings({
                                                                            current_task_id: Number(e.target.value)
                                                                        })
                                                                    } }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5 className="mb-3 mt-2">
                                                        Sprachen
                                                    </h5>
                                                    { appSettings.languages.map((language, i) => (
                                                        <div className="row mb-3">
                                                            <div className="col-xl-6 col-md-8">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="mb-2">
                                                                            <label htmlFor={ `language_${i}_value`} className="form-label">
                                                                                Sprache
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id={ `language_${i}_value` }
                                                                                className="form-control"
                                                                                value={ language.value }
                                                                                placeholder="z.B: Deutsch"
                                                                                onChange={ (e) => {
                                                                                    updateLanguage(i, {
                                                                                        value: e.target.value
                                                                                    })
                                                                                } }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="mb-2">
                                                                            <div className="float-end">
                                                                                <button 
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-default"
                                                                                    onClick={ () => {
                                                                                        confirmRemoveLanguage(i)
                                                                                    } }
                                                                                >
                                                                                    <i className="uil-times" />
                                                                                </button>
                                                                            </div>
                                                                            <label htmlFor={ `language_${i}_key`} className="form-label">
                                                                                Code
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id={ `language_${i}_key` }
                                                                                className="form-control"
                                                                                value={ language.key }
                                                                                placeholder="z.B: DE"
                                                                                disabled={ language.is_read_only }
                                                                                onChange={ (e) => {
                                                                                    updateLanguage(i, {
                                                                                        key: e.target.value
                                                                                    })
                                                                                } }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) }
                                                    <div>
                                                        <button 
                                                            type="button"
                                                            className="btn btn-light mb-2"
                                                            onClick={ addLanguage }
                                                        >   
                                                            <i className="uil-plus" />{ " " }
                                                            <span className="ps-2">Sprache hinzufügen</span>
                                                        </button>
                                                    </div>

                                                    <h5 className="mb-3 mt-2">
                                                        Dokument-Typen
                                                    </h5>
                                                    { appSettings.task_types.map((task_type, i) => (
                                                        <div className="row mb-3">
                                                            <div className="col-xl-6 col-md-8">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="mb-2">
                                                                            <label htmlFor={ `task_type_${i}_value`} className="form-label">
                                                                                Typ
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id={ `task_type_${i}_value` }
                                                                                className="form-control"
                                                                                value={ task_type.value }
                                                                                onChange={ (e) => {
                                                                                    if(task_type.is_read_only) {
                                                                                        updateTaskType(i, {
                                                                                            value: e.target.value,
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        updateTaskType(i, {
                                                                                            value: e.target.value,
                                                                                            key: e.target.value.toLowerCase().replace(/[^a-z0-9]+/gi, ' ').trim().replace(/\s/g, '-'),
                                                                                        })
                                                                                    }
                                                                                } }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="mb-2">
                                                                            <div className="float-end">
                                                                                <button 
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-default"
                                                                                    onClick={ () => {
                                                                                        removeTaskType(i)
                                                                                    } }
                                                                                >
                                                                                    <i className="uil-times" />
                                                                                </button>
                                                                            </div>
                                                                            <label htmlFor={ `task_type_${i}_key` } className="form-label">
                                                                                Code
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                id={ `task_type_${i}_key` }
                                                                                className="form-control"
                                                                                value={ task_type.key }
                                                                                disabled={ task_type.is_read_only }
                                                                                onChange={ (e) => {
                                                                                    updateTaskType(i, {
                                                                                        key: e.target.value
                                                                                    })
                                                                                } }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) }
                                                    <div>
                                                        <button 
                                                            type="button"
                                                            className="btn btn-light mb-2"
                                                            onClick={ addTaskType }
                                                        >   
                                                            <i className="uil-plus" />{ " " }
                                                            <span className="ps-2">Dokument-Typ hinzufügen</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            ) }

                                            { showTab === 'homescreen' && (
                                                <div
                                                    className="tab-pane active mb-3"
                                                >
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="homescreen_quote_title"
                                                                    className="form-label"
                                                                >
                                                                    Titel
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="homescreen_quote_title"
                                                                    className="form-control"
                                                                    value={ appSettings.homescreen_quote?.title || "" }
                                                                    onChange={ (e) => {
                                                                        updateHomescreenContent({
                                                                            title: e.target.value
                                                                        })
                                                                    } }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="homescreen_quote_quote"
                                                                    className="form-label"
                                                                >
                                                                    Zitat
                                                                </label>
                                                                <textarea
                                                                    id="homescreen_quote_quote"
                                                                    className="form-control"
                                                                    value={ appSettings.homescreen_quote?.quote || "" }
                                                                    onChange={ (e) => {
                                                                        updateHomescreenContent({
                                                                            quote: e.target.value
                                                                        })
                                                                    } }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="homescreen_quote_author"
                                                                    className="form-label"
                                                                >
                                                                    Autor
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="homescreen_quote_author"
                                                                    className="form-control"
                                                                    value={ appSettings.homescreen_quote?.author || "" }
                                                                    onChange={ (e) => {
                                                                        updateHomescreenContent({
                                                                            author: e.target.value
                                                                        })
                                                                    } }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) }

                                            { showTab === 'task-price-calculation' && (
                                                <div
                                                    className="tab-pane active mb-3"
                                                >
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    type="checkbox"
                                                                    id="is_automatic_task_calculation_enabled"
                                                                    checked={appSettings.is_automatic_task_calculation_enabled}
                                                                    className="form-check-input"
                                                                    onChange={ (e) => {
                                                                        updateAppSettings({
                                                                            is_automatic_task_calculation_enabled: e.target.checked
                                                                        })
                                                                    } }
                                                                />
                                                                <label className="form-check-label" htmlFor="is_automatic_task_calculation_enabled">
                                                                    Automatische Auftragspreiskalkulation einschalten
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="mb-5">
                                                                <label
                                                                    htmlFor="homescreen_quote_title"
                                                                    className="form-label"
                                                                >
                                                                    Standardpreis pro Wort
                                                                </label>
                                                                <div className="input-group">
                                                                    <input 
                                                                        type="number" 
                                                                        className="form-control"
                                                                        value={ appSettings.default_price_per_word }
                                                                        onChange={ (e) => {
                                                                            updateAppSettings({
                                                                                default_price_per_word: Number(e.target.value)
                                                                            })
                                                                        } }
                                                                    />
                                                                    <span className="input-group-text">
                                                                        <i className="uil-euro" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5 className="mb-3 mt-2">
                                                        Preis pro Wort für bestimmte Sprachen
                                                    </h5>
                                                    { appSettings.language_prices_per_word.map((language_price_per_word, i) => (
                                                        <div className="row mb-3">
                                                            <div className="col-xl-6 col-md-8">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="mb-2">
                                                                            <label htmlFor={ `language_price_per_word_${i}_language_key`} className="form-label">
                                                                                Sprache
                                                                            </label>
                                                                            <select
                                                                                id={`language_price_per_word_${i}_language_key`}
                                                                                className="form-select"
                                                                                value={ language_price_per_word.language_key }
                                                                                onChange={ (e) => {
                                                                                    updateLanguagePricePerWord(i, {
                                                                                        language_key: e.target.value
                                                                                    })
                                                                                } }
                                                                            >
                                                                                <option value="">-Bitte auswählen-</option>
                                                                                { appSettings.languages.map((language) => (
                                                                                    <option 
                                                                                        key={ language.key }
                                                                                        value={ language.key }
                                                                                    >{ language.value }</option>
                                                                                )) }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="mb-2">
                                                                            <div className="float-end">
                                                                                <button 
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-default"
                                                                                    onClick={ () => {
                                                                                        removeLanguagePricePerWord(i)
                                                                                    } }
                                                                                >
                                                                                    <i className="uil-times" />
                                                                                </button>
                                                                            </div>
                                                                            <label htmlFor={ `language_price_per_word_${i}_price`} className="form-label">
                                                                                Preis
                                                                            </label>
                                                                            <div className="input-group">
                                                                                <input 
                                                                                    type="number" 
                                                                                    id={ `language_price_per_word_${i}_price` }
                                                                                    className="form-control"
                                                                                    value={ language_price_per_word.price }
                                                                                    onChange={ (e) => {
                                                                                        updateLanguagePricePerWord(i, {
                                                                                            price: Number(e.target.value)
                                                                                        })
                                                                                    } }
                                                                                />
                                                                                <span className="input-group-text">
                                                                                    <i className="uil-euro" />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) }
                                                    <div>
                                                        <button 
                                                            type="button"
                                                            className="btn btn-light mb-5"
                                                            onClick={ addLanguagePricePerWord }
                                                        >   
                                                            <i className="uil-plus" />{ " " }
                                                            <span className="ps-2">Sprache und Preis hinzufügen</span>
                                                        </button>
                                                    </div>

                                                    <h5 className="mb-3 mt-2">
                                                        Preisaufschlag für Dokument-Typ
                                                    </h5>
                                                    { appSettings.task_types_price.map((task_type_price, i) => (
                                                        <div className="row mb-3">
                                                            <div className="col-xl-6 col-md-8">
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <div className="mb-2">
                                                                            <label htmlFor={ `task_type_price_${i}_task_type_key`} className="form-label">
                                                                                Dokument-Typ
                                                                            </label>
                                                                            <select
                                                                                id={`task_type_price_${i}_task_type_key`}
                                                                                className="form-select"
                                                                                value={ task_type_price.task_type_key }
                                                                                onChange={ (e) => {
                                                                                    updateTaskTypePrice(i, {
                                                                                        task_type_key: e.target.value
                                                                                    })
                                                                                } }
                                                                            >
                                                                                <option value="">-Bitte auswählen-</option>
                                                                                { appSettings.task_types.map((task_type) => (
                                                                                    <option 
                                                                                        key={ task_type.key }
                                                                                        value={ task_type.key }
                                                                                    >{ task_type.value }</option>
                                                                                )) }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="mb-2">
                                                                            <div className="float-end">
                                                                                <button 
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-default"
                                                                                    onClick={ () => {
                                                                                        removeTaskTypePrice(i)
                                                                                    } }
                                                                                >
                                                                                    <i className="uil-times" />
                                                                                </button>
                                                                            </div>
                                                                            <label htmlFor={ `task_type_price_${i}_price`} className="form-label">
                                                                                Preis
                                                                            </label>
                                                                            <div className="input-group">
                                                                                <input 
                                                                                    type="number" 
                                                                                    id={ `task_type_price_${i}_price` }
                                                                                    className="form-control"
                                                                                    value={ task_type_price.price }
                                                                                    onChange={ (e) => {
                                                                                        updateTaskTypePrice(i, {
                                                                                            price: Number(e.target.value)
                                                                                        })
                                                                                    } }
                                                                                />
                                                                                <span className="input-group-text">
                                                                                    <i className="uil-euro" />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) }
                                                    <div>
                                                        <button 
                                                            type="button"
                                                            className="btn btn-light mb-5"
                                                            onClick={ addTaskTypePrice }
                                                        >   
                                                            <i className="uil-plus" />{ " " }
                                                            <span className="ps-2">Dokument-Typ und Preis hinzufügen</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            ) }
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button 
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >Aktualisieren</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>{" "}
                                {/* end card-body */}
                            </div>{" "}
                            {/* end card*/}
                        </div>{" "}
                        {/* end col*/}
                    </div>
                    {/* end row*/}
                </>
            ) }
        </div>
    )
}

export default AdminAppSettings