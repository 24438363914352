// @ts-nocheck
import moment from "moment"

import MessagesSkeleton from 'screens/skeletons/components/Messenger/Messages.skeleton'
import MessagesController from 'controllers/components/Messenger/Messages.controller'

const Messages = (props) => {
    const {
        user
    } = props

    const {
        currentUser,
        isLoading,
        messages,
        isSendingMessage,
        handleFormSubmit,
        messageListFrame,
        messageListContainer,
        newMessageInput,
        submitButton,
    } = MessagesController(props)

    return (
        <div className="card">
            <div className="card-body">
                <ul
                    className="conversation-list"
                    data-simplebar="init"
                    style={ { maxHeight: "537px" } }
                >
                    <div className="simplebar-wrapper" style={ { margin: "0px -15px" } }>
                        <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                            <div className="simplebar-offset" style={ { right: "0px", bottom: "0px" } }>
                                <div
                                    className="simplebar-content-wrapper"
                                    style={ { height: "auto", overflow: "hidden scroll" } }
                                    ref={messageListFrame}
                                >
                                    <div
                                        className="simplebar-content"
                                        style={ { padding: "0px 15px" } }
                                        ref={messageListContainer}
                                    >
                                        { isLoading && <MessagesSkeleton /> }
                                        { messages.length > 0 && messages.map((message) => {
                                            const isOwnMessage = message.user_1_id === currentUser.id
                                            const messageTime = moment(message.created)
                                            const messageIsToday = moment().diff(messageTime, 'days') === 0
                                            const messageIsThisYear = moment().diff(messageTime, 'years') === 0

                                            return (
                                                <li className={ `clearfix ${isOwnMessage ? 'odd' : ''}` } key={message.id}>
                                                    <div className="chat-avatar">
                                                        <img src="/assets/images/users/avatar-0.png" className="rounded" alt={ user.is_company ? user.company_name : `${user.first_name} ${user.last_name}` } />
                                                        { messageIsToday ? (
                                                            <i>{ messageTime.format('HH:mm') }</i>
                                                        ) : messageIsThisYear ? (
                                                            <i>{ messageTime.format('DD/MM') }</i>
                                                        ) : (
                                                            <i>{ messageTime.format('DD/MM/YY') }</i>
                                                        ) }
                                                    </div>
                                                    <div className="conversation-text">
                                                        <div className="ctext-wrap">
                                                            { isOwnMessage ? (
                                                                <i>{ currentUser.is_company ? currentUser.company_name : `${currentUser.first_name} ${currentUser.last_name}` }</i>
                                                            ) : (
                                                                <i>{ user.is_company ? user.company_name : `${user.first_name} ${user.last_name}` }</i>
                                                            ) }
                                                            <p>
                                                                { message.content }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }) }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simplebar-placeholder" style={ { width: "782px", height: "867px" } }></div>
                    </div>
                    <div className="simplebar-track simplebar-horizontal" style={ { visibility: "hidden" } }>
                        <div className="simplebar-scrollbar" style={ { width: "0px", display: "none", transform: "translate3d(0px, 0px, 0px)" } }></div>
                    </div>
                    <div className="simplebar-track simplebar-vertical" style={ { visibility: "visible" } }>
                        <div className="simplebar-scrollbar" style={ { height: "332px", transform: "translate3d(0px, 0px, 0px)", display: "block" } }></div>
                    </div>
                </ul>

                        
                <div className="row">
                    <div className="col">
                        <div className="mt-2 bg-light p-3 rounded">
                            <form onSubmit={ handleFormSubmit } name="chat-form" id="chat-form">
                                <div className="row">
                                    <div className="col mb-2 mb-sm-0">
                                        <textarea 
                                            type="text"
                                            className="form-control border-0"
                                            name="newmessage"
                                            placeholder="Nachricht eingeben"
                                            required=""
                                            ref={newMessageInput}
                                        />
                                        <div className="invalid-feedback">
                                            Nachricht eingeben
                                        </div>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="btn-group">
                                            <div className="d-grid">
                                                <button ref={submitButton} type="submit" className="btn btn-success chat-send" disabled={isSendingMessage}><i className="uil uil-message"></i></button>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </form>
                        </div> 
                    </div> 
                </div>
            </div> 
        </div>
    )
}

export default Messages