// @ts-nocheck
import { useState } from "react"
import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"

const TaskBid = ({ task, setTask, refetchActivity }) => {
    const [isApplying, setIsApplying] = useState(false)
    const authContext = useAuth();

    const onSubmitForm = async (event) => {
        event.preventDefault()

        try {
            const res = await authContext.authPostRequest(`/my/translator/bid-for-task/${task.id}`, {
                price: Number(event.target.elements.price.value),
                description: event.target.elements.description.value
            })

            if(!res.data.code && res.data) {
                toast.success("Ihre Bewerbung wurde gespeichert")
                setIsApplying(false)
                setTask(res.data)
                refetchActivity()
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                    default:
                    case 500:
                        throw new Error('Beim Speichern der Bewerbung ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }

    return (
        <>
            {isApplying ? (
                <form onSubmit={onSubmitForm}>
                    <div className="row">
                        <div className="col-6 mb-3">
                            <label htmlFor="application-price">Bewerbungspreis</label>
                            <div className="input-group">
                                <input 
                                    id="application-price"
                                    type="number" 
                                    className="form-control"
                                    name="price"
                                />
                                <span className="input-group-text">
                                    <i className="uil-euro" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="application-description">Bewerbungsschreiben</label>
                        <textarea id="application-description" name="description" rows="10" className="form-control"></textarea>
                    </div>
                    <div>
                        <input type="submit" className="btn btn-primary" value="Bewerbung schicken" />
                        <button onClick={() => setIsApplying(false)} type="button" className="btn btn-light">Abbrechen</button>
                    </div>
                </form>
            ) : (
                <>
                    {task.bids.length > 0 ? `Bewerbungen: ${task.bids.length}` : "Noch keine Bewerbungen"}
                    <button
                        className="btn btn-link"
                        onClick={() => setIsApplying(true)}
                    >Click Sie here um sich zu bewerben</button>
                </>
            )}
        </>
    )
}

export default TaskBid