// @ts-nocheck
import {
    useParams,
  } from "react-router-dom";
  
import Task from 'components/Task'

const CustomerTaskView = () => {
    const { id } = useParams()

    return (
        <Task
            id={id}
            view="customer"
        />
    )
}

export default CustomerTaskView