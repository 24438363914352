// @ts-nocheck
import { useState, useEffect, useRef } from 'react'
import moment from 'moment'

import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"

let latestTimestamp
let oldestTimestamp
let hasNoOlderMessages = false
let isLoadingNewMessages = false

const fetchInitialMessages = async (userId, setMessages, setIsLoading, authContext, messageListFrame) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        const messages = await authGetRequest(`/my/messages?userId=${userId}&limit=20`)

        if(!messages.data.code) {
            messages.data.sort((a, b) => {
                const aCreated = moment(a.created)
                const bCreated = moment(b.created)
                if(aCreated.isBefore(moment(bCreated))) {
                    return -1
                }
                else if(moment(bCreated).isBefore(moment(aCreated))) {
                    return 1
                }
                return 0
            })

            setMessages(messages.data)
            let localLatestTimestamp
            let localOldestTimestamp
            messages.data.forEach(message => {
                const timestampObject = moment.utc(message.created)
                
                if(!localOldestTimestamp || timestampObject.isBefore(localOldestTimestamp)) {
                    localOldestTimestamp = timestampObject
                }
                if(!localLatestTimestamp || timestampObject.isAfter(localLatestTimestamp)) {
                    localLatestTimestamp = timestampObject
                }
            })

            if(localOldestTimestamp) {
                oldestTimestamp = localOldestTimestamp
            }
            if(localLatestTimestamp) {
                latestTimestamp = localLatestTimestamp
            }

            setTimeout(() => {
                messageListFrame.current.scrollTop = messageListFrame.current.scrollHeight
            }, 0)
        }
        else {
            switch(messages.data.code) {
                case 404:
                case 400:
                    throw new Error('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden des Kontakes ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setMessages([])
        toast.error(e.message)
    }
    setIsLoading(false)
}

const fetchLatestMessages = async (userId, setMessages, setIsLoading, authContext, messageListFrame) => {
    const { authGetRequest } = authContext
    
    try {
        const messages = await authGetRequest(`/my/messages?userId=${userId}&dateFrom=${latestTimestamp ? latestTimestamp.format('YYYY-MM-DD HH:mm:ss') : ''}`)

        if(!messages.data.code) {
            const hasNewMessages = messages.data.length > 0
            
            if(hasNewMessages) {
                const shouldScrollToBottom = messageListFrame.current.scrollTop >= messageListFrame.current.scrollHeight - messageListFrame.current.clientHeight - 20

                setMessages((msgs) => [
                    ...msgs,
                    ...messages.data
                ])
    
                messages.data.forEach(message => {
                    const timestampObject = moment.utc(message.created)
                    
                    if(!latestTimestamp || timestampObject.isAfter(latestTimestamp)) {
                        latestTimestamp = timestampObject
                    }
                })

                setTimeout(() => {
                    if(shouldScrollToBottom) {
                        messageListFrame.current.scrollTop = messageListFrame.current.scrollHeight
                    }
                }, 0)
            }
        }
        else {
            switch(messages.data.code) {
                case 404:
                case 400:
                    throw new Error('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden der Nachrichten ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setMessages([])
        toast.error(e.message)
    }
}

const fetchOldestMessages = async (userId, setMessages, setIsLoading, authContext, messageListFrame) => {
    isLoadingNewMessages = true
    setIsLoading(true)
    
    const { authGetRequest } = authContext
    
    try {
        const messages = await authGetRequest(`/my/messages?userId=${userId}&limit=20&dateTo=${oldestTimestamp ? oldestTimestamp.format('YYYY-MM-DD HH:mm:ss') : ''}`)

        if(!messages.data.code) {
            const hasOlderMessages = messages.data.length > 0
            
            if(hasOlderMessages) {
                const prevScrollHeight = messageListFrame.current.scrollHeight

                messages.data.sort((a, b) => {
                    const aCreated = moment(a.created)
                    const bCreated = moment(b.created)
                    if(aCreated.isBefore(moment(bCreated))) {
                        return -1
                    }
                    else if(moment(bCreated).isBefore(moment(aCreated))) {
                        return 1
                    }
                    return 0
                })

                setMessages((msgs) => [
                    ...messages.data,
                    ...msgs
                ])
    
                messages.data.forEach(message => {
                    const timestampObject = moment.utc(message.created)
                    
                    if(!timestampObject || timestampObject.isBefore(oldestTimestamp)) {
                        oldestTimestamp = timestampObject
                    }
                })

                setTimeout(() => {
                    const difference = messageListFrame.current.scrollHeight - prevScrollHeight
                    messageListFrame.current.scrollTop = messageListFrame.current.scrollTop + difference
                }, 0)
            }
            else {
                hasNoOlderMessages = true
            }
        }
        else {
            switch(messages.data.code) {
                case 404:
                case 400:
                    throw new Error('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden der Nachrichten ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setMessages([])
        toast.error(e.message)
    }

    isLoadingNewMessages = false
    setIsLoading(false)
}

const MessagesController = (props) => {
    const authContext = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [messages, setMessages] = useState([])
    const [isSendingMessage, setIsSendingMessage] = useState(false)
    const messageListFrame = useRef(null)
    const messageListContainer = useRef(null)
    const newMessageInput = useRef(null)
    const submitButton = useRef(null)

    const processScroll = () => {
        if(!hasNoOlderMessages && !isLoadingNewMessages && messageListFrame.current.scrollTop <= 20) {
            fetchOldestMessages(props.user.id, setMessages, setIsLoading, authContext, messageListFrame)
        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        const content = e.target.elements.newmessage.value?.trim()
        if(!content) {
            return
        }

        try {
            setIsSendingMessage(true)
            const res = await authContext.authPostRequest(`/my/messages?userId=${props.user.id}`, {
                content
            })

            if(!res.data.code) {
                e.target.closest('form').reset()
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                        break
                    case 401:
                        throw new Error("Ihre Session wurde beendet. Bitte loggen Sie sich erneut ein")
                        break
                    default:
                    case 500:
                        throw new Error('Beim Laden der Nachrichten ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }

        setIsSendingMessage(false)
    }

    const onKeyDown = (e) => {
        if(e.keyCode === 13 && !e.shiftKey && !e.altKey && !e.ctrlKey) {
            submitButton.current?.click()
        }
    }

    useEffect(() => {
        let interval
        latestTimestamp = undefined
        oldestTimestamp = undefined
        hasNoOlderMessages = false
        isLoadingNewMessages = false
        fetchInitialMessages(props.user.id, setMessages, setIsLoading, authContext, messageListFrame).then(() => {
            interval = setInterval(() => {
                fetchLatestMessages(props.user.id, setMessages, setIsLoading, authContext, messageListFrame)
            }, 1000)
            messageListFrame.current.addEventListener('scroll', processScroll)
        })

        newMessageInput.current.focus()
        newMessageInput.current.addEventListener('keydown', onKeyDown)

        return () => {
            clearInterval(interval)
            messageListFrame.current?.removeEventListener('scroll', processScroll)
            newMessageInput.current?.removeEventListener('keydown', onKeyDown)
        }
    }, [props.user.id])

    return {
        currentUser: authContext.currentUser,
        isLoading,
        messages,
        isSendingMessage,
        handleFormSubmit,
        messageListFrame,
        messageListContainer,
        newMessageInput,
        submitButton,
    }
}

export default MessagesController