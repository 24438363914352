// @ts-nocheck
import { useState, useEffect } from "react"
import {
    Link
} from "react-router-dom"
import { postRequest, getRequest } from "clients/ApiClient"

const Password = () => {
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [foundUser, setFoundUser] = useState()
    // const { signup } = useAuth()

    const validateForm = (formElements) => {
        if(!formElements.emailaddress.value) {
            setError("Bitte geben Sie eine E-Mail Adresse ein")
            return false
        }

        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        if(isLoading) {
            return
        }

        if(!validateForm(e.target.elements)) {
            return
        }

        const body = {
            email: e.target.elements.emailaddress.value,
        }

        try {
            setIsLoading(true)
            const res = await postRequest("/auth/send-forgot-password-email", body)

            switch(res.status) {
                case 200:
                    setFoundUser(true)
                    break
                case 404:
                    setError("Die von Ihnen eingegebene E-Mail ist mit keinem Konto verbunden")
                    break
                default:
                    throw new Error()
            }
        }
        catch (e) {
            setError("Beim Senden der E-Mail zur Wiederherstellung des Passworts ist ein Fehler aufgetreten")
        }

        setIsLoading(false)
    }

    return (
        <>
            <h4 className="mt-0">Kennwort vergessen</h4>
            { error && (
                <div className="alert alert-danger">{ error }</div>
            ) }
            { foundUser ? (
                <div>
                    <h2 className="mb-4">E-Mail gesendet</h2>
                    <p>
                        Eine E-Mail mit den Anweisungen zum Zurücksetzen Ihres Kennworts wurde an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Spam-Ordner
                    </p>
                </div>
            ) : (
                <>
                    <p className="text-muted mb-4">
                        Geben Sie Ihre Email Adresse ein und wir senden Ihnen eine Email mit Anweisungen zum Zurücksetzen Ihres Kennworts.
                    </p>

                    <form action="#" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label for="emailaddress" className="form-label">Email Addresse</label>
                            <input className="form-control" type="email" id="emailaddress" name="emailaddress" required="" />
                        </div>
                        <div className="d-grid mb-0 text-center">
                            <button className="btn btn-primary" type="submit"><i className="mdi mdi-login"></i> Senden </button>
                        </div>
                    </form>

                    <footer className="footer footer-alt">
                        <p className="text-muted"><Link to="/auth/login" className="text-muted ms-1"><b>Einloggen</b></Link></p>
                    </footer>
                </>
            ) }
        </>
    )
}

export default Password