// @ts-nocheck
import { useState, useEffect } from "react"
import {
    Link
} from "react-router-dom"
import { postRequest, getRequest } from "clients/ApiClient"

const verifyPasswordToken = async (token, setIsLoading, setIsUrlValid, setError) => {
    setIsLoading(true)
    setIsUrlValid(false)
    const res = await getRequest(`/auth/verify-password-token/${token}`)
    if(res.status === 200) {
        setIsLoading(false)
        setIsUrlValid(true)
    }
    else {
        setIsLoading(false)
        setError('Die Url zum Zurücksetzen des Passworts ist entweder ungültig oder abgelaufen')
    }
}

const PasswordReset = (props) => {
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isUrlValid, setIsUrlValid] = useState(false)
    const [isPasswordReset, setIsPasswordReset] = useState(false)


    const token = props.match.params.token
    
    useEffect(() => {
        verifyPasswordToken(token, setIsLoading, setIsUrlValid, setError)
    }, [token])



    const validateForm = (formElements) => {
        if(!formElements.newpassword.value) {
            setError("Bitte geben Sie Ihr neues Kennwort ein")
            return false
        }

        if(!formElements.newpasswordverify.value) {
            setError("Bitte bestätigen Sie Ihr neues Kennwort")
            return false
        }

        if(formElements.newpassword.value && formElements.newpasswordverify.value && formElements.newpassword.value !== formElements.newpasswordverify.value) {
            setError("Beide Kennwörter stimmen nicht überein")
            return false
        }

        return true
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        if(isLoading) {
            return
        }

        if(!validateForm(e.target.elements)) {
            return
        }

        const body = {
            password: e.target.elements.newpassword.value,
        }

        try {
            setIsLoading(true)
            const res = await postRequest(`/auth/reset-password?token=${token}`, body)
            
            switch(res.status) {
                case 204:
                    setIsPasswordReset(true)
                    break
                default:
                    if(res.data.message) {
                        switch(res.data.message) {
                            case 'PASSWORD_TOO_SHORT':
                                setError("Das Passwort muss mindestens 8 Zeichen lang sein.")
                                break
                            case 'PASSWORD_INVALID':
                                setError("Das Passwort muss mindestens 1 Buchstaben und 1 Zahl enthalten.")
                                break
                            default:
                                setError(res.data.message)
                                break
                        }
                    }
                    else {
                        throw new Error()
                    }
            }
        }
        catch (e) {
            setError("Beim Zurücksetzen Ihres Kennworts ist ein Fehler aufgetreten")
        }

        setIsLoading(false)
    }

    return (
        <>
            <h4 className="mt-0">Kennwort wiederherstellen</h4>
            { error && (
                <div className="alert alert-danger">{ error }</div>
            ) }
            { isUrlValid && (
                <>
                    { isPasswordReset ? (
                        <p>
                            Ihr Passwort wurde erfolgreich zurückgesetzt. Klicken Sie <Link to="/auth/login" className="text-muted ms-1">hier</Link>, um sich anzumelden
                        </p>
                    ) : (
                        <>
                            <p className="text-muted mb-4">
                                Bitte geben Sie Ihr neues Kennwort ein
                            </p>

                            <form action="#" onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="newpassword" className="form-label">Neues Kennwort</label>
                                    <input className="form-control" type="password" id="newpassword" name="newpassword" required="" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="newpasswordverify" className="form-label">Neues Kennwort bestätigen</label>
                                    <input className="form-control" type="password" id="newpasswordverify" name="newpasswordverify" required="" />
                                </div>
                                <div className="d-grid mb-0 text-center">
                                    <button className="btn btn-primary" type="submit"><i className="mdi mdi-login"></i> Kennwort aktualisieren </button>
                                </div>
                            </form>
                        </>
                    ) }
                </>
                
            ) }
            <footer className="footer footer-alt">
                <p className="text-muted"><Link to="/auth/login" className="text-muted ms-1"><b>Einloggen</b></Link></p>
            </footer>
        </>
    )
}

export default PasswordReset