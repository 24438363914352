// @ts-nocheck
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"

const SettingsController = (props) => {
    const authContext = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [generalFormValidationErrors, setGeneralFormValidationErrors] = useState({})
    const [securityFormValidationErrors, setSecurityFormValidationErrors] = useState({})
    const [showTab, setShowTab] = useState('general')

    const validateGeneralForm = (formElements) => {
        const validationErrors = {}

        if(!formElements.first_name.value) {
            validationErrors.first_name = "Bitte geben Sie ihren Vornamen ein"
        }

        if(!formElements.last_name.value) {
            validationErrors.first_name = "Bitte geben Sie ihren Nachnamen ein"
        }

        setGeneralFormValidationErrors(validationErrors)

        return Object.values(validationErrors).length === 0
    }

    const validateSecurityForm = (formElements) => {
        const validationErrors = {}

        if(!formElements.current_password.value) {
            validationErrors.current_password = "Bitte geben Sie ihr aktuelles Kennwort ein"
        }

        if(!formElements.new_password.value) {
            validationErrors.new_password = "Bitte geben Sie ihr neues Kennwort ein"
        }

        if(!formElements.new_password_confirm.value) {
            validationErrors.new_password_confirm = "Bitte bestätigen Sie ihr neues Kennwort"
        }

        if(formElements.new_password.value && formElements.new_password_confirm.value && formElements.new_password.value !== formElements.new_password_confirm.value) {
            validationErrors.new_password_confirm = "Beide Kennwörter stimmen nicht überein"
        }

        setSecurityFormValidationErrors(validationErrors)

        return Object.values(validationErrors).length === 0
    }

    const handleSubmitGeneralForm = async (e) => {
        e.preventDefault()
        setGeneralFormValidationErrors({})

        try {
            if(!validateGeneralForm(e.target.elements)) {
                return
            }

            const body = {
                first_name: e.target.elements.first_name.value,
                last_name: e.target.elements.last_name.value,
            }
            
            const res = await authContext.authPatchRequest("/my/basic-information", body)

            console.log(res)

            if(!res.data.code) {
                authContext.updateUserInformation(res.data)
                toast.success("Die Information wurde aktualisiert")
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                    default:
                    case 500:
                        throw new Error('Beim Aktualisieren ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }


    // const handleSubmit = async (e) => {
    //     e.preventDefault()
    //     setError("")
    //     if(isLoading) {
    //         return
    //     }

    //     if(!validateForm(e.target.elements)) {
    //         return
    //     }

    //     const body = {
    //         password: e.target.elements.newpassword.value,
    //     }

    //     try {
    //         setIsLoading(true)
    //         const res = await postRequest(`/auth/reset-password?token=${token}`, body)
            
    //         switch(res.status) {
    //             case 204:
    //                 setIsPasswordReset(true)
    //                 break
    //             default:
    //                 if(res.data.message) {
    //                     switch(res.data.message) {
    //                         case 'PASSWORD_TOO_SHORT':
    //                             setError("Das Passwort muss mindestens 8 Zeichen lang sein.")
    //                             break
    //                         case 'PASSWORD_INVALID':
    //                             setError("Das Passwort muss mindestens 1 Buchstaben und 1 Zahl enthalten.")
    //                             break
    //                         default:
    //                             setError(res.data.message)
    //                             break
    //                     }
    //                 }
    //                 else {
    //                     throw new Error()
    //                 }
    //         }
    //     }
    //     catch (e) {
    //         setError("Beim Zurücksetzen Ihres Kennworts ist ein Fehler aufgetreten")
    //     }

    //     setIsLoading(false)
    // }

    const handleSubmitSecurityForm = async (e) => {
        e.preventDefault()

        try {
            if(!validateSecurityForm(e.target.elements)) {
                return
            }

            const body = {
                currentPassword: e.target.elements.current_password.value,
                newPassword: e.target.elements.new_password.value,
            }
            
            const res = await authContext.authPatchRequest("/my/password", body)

            console.log(res)

            if(!res.data.code) {
                toast.success("Die Information wurde aktualisiert")
            }
            else {
                switch(res.data.message) {
                    case 'BAD_PASSWORD':
                        setSecurityFormValidationErrors({
                            current_password: "Falsches Kennwort"
                        })
                        break
                    case 'PASSWORD_TOO_SHORT':
                        setSecurityFormValidationErrors({
                            new_password: "Das Passwort muss mindestens 8 Zeichen lang sein."
                        })
                        break
                    case 'PASSWORD_INVALID':
                        setSecurityFormValidationErrors({
                            new_password: "Das Passwort muss mindestens 1 Buchstaben und 1 Zahl enthalten."
                        })
                        break
                    default:
                        throw new Error('Beim Aktualisieren ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    }

    return {
        currentUser: authContext.currentUser,
        isLoading,
        error,
        handleSubmitGeneralForm,
        handleSubmitSecurityForm,
        generalFormValidationErrors,
        securityFormValidationErrors,
        showTab, 
        setShowTab,
    }
}

export default SettingsController