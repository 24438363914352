// @ts-nocheck
const MinimizedProfileCardContent = ({ 
    profileIndex, 
    setShowProfile,
    user,
    profile,
}) => {
    const showProfile = (e) => {
        e.preventDefault()
        setShowProfile(profileIndex)
    }

    return (
        <div className="inbox-item">
            <div className="inbox-item-img">
                <img
                    src={profile.profile_picture ? profile.profile_picture : "/assets/images/users/avatar-0.png"}
                    className="rounded-circle"
                    alt=""
                />
            </div>
            <p className="inbox-item-author mt-1">
                <a className="text-dark" href="#" onClick={showProfile}>
                    { user.is_company ? user.company_name : `${user.first_name} ${user.last_name}` }
                </a>
            </p>
        </div>
    )
}

export default MinimizedProfileCardContent