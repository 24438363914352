// @ts-nocheck
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';

const TranslatorCalendar = () => {
    return (
        <div className="mt-3">
            <FullCalendar
                plugins={[ dayGridPlugin, bootstrapPlugin ]}
                initialView="dayGridMonth"
                themeSystem="bootstrap"
                locale="de"
                bootstrapFontAwesome={ false }
                buttonText={{
                    today: "Heute",
                    month: "Monat",
                    week: "Woche",
                    day: "Tag",
                    list: "Liste",
                    prev: "<",
                    next: ">",
                }}
            />
        </div>
    );
};

export default TranslatorCalendar;
