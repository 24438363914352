// @ts-nocheck
import {
    useHistory
} from "react-router-dom";

import TranslatorProfile from 'components/TranslatorProfile'

const TranslatorMyProfile = () => {
    return (
        <TranslatorProfile
            isMyProfile={ true }
        />
    )
}

export default TranslatorMyProfile