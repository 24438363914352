// @ts-nocheck
import { useState, useEffect, useReducer } from 'react'
import {
    useHistory,
    useLocation,
  } from "react-router-dom";
import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"
import { useBackgroundJobs } from "contexts/BackgroundJobsContext"
import { processAppSettingsKeyValueArray } from 'utils/settings'
import { formatDateForApi } from 'utils/datetime'

const getNewTask = () => ({
    id: undefined,
    entry_date: formatDateForApi(new Date()),
    confirmed_date: null,
    issue_date: null,
    task_date: null,
    print_date: null,
    task_id: "",
    status: "draft",
    files: [],
    task_type: 'translation',
    task_date: '',
    interpretations: []
})

const getNewFile = () => ({
    filename: '',
    size: '',
    extension: '',
    source_language: '',
    target_language: '',
    doc_type: '',
})

const getNewInterpretation = (translator = null) => ({
    source_language: '',
    target_language: '',
    translator,
})

const taskReducer = (state, action) => {
    switch (action.type) {
        // root fields
        case 'UPDATE':
            return { ...state, ...action.payload }

        //files
        case 'ADD_FILE':
            return { ...state, files: [...state.files, action.payload] }
        case 'UPDATE_FILE':
            return { ...state, files: state.files.map((file, i) => action.payload.index == i ? ({ ...file, ...action.payload.update }) : file) }
        case 'REMOVE_FILE':
            return { ...state, files: state.files.filter((file, i) => i !== action.payload) }

        //interpretations
        case 'ADD_INTERPRETATION':
            return { ...state, interpretations: [...state.interpretations, getNewInterpretation(action.payload.translator)] }
        case 'UPDATE_INTERPRETATION':
            return { ...state, interpretations: state.interpretations.map((interpretation, i) => action.payload.index == i ? ({ ...interpretation, ...action.payload.update }) : interpretation) }
        case 'REMOVE_INTERPRETATION':
            return { ...state, interpretations: state.interpretations.filter((interpretation, i) => i !== action.payload) }
    }
}

const fetchDefaultUser = async (userId, authContext, setDefaultUser) => {
    const defaultUser = await fetchTranslatorProfile(userId, authContext)
    console.log({
        defaultUser
    })
    setDefaultUser(defaultUser)
}


const fetchTranslatorProfile = async (translatorProfileId, authContext) => {
    const { authGetRequest } = authContext
    try {
        const translatorRes = await authGetRequest(`/translators/${translatorProfileId}`)

        if(!translatorRes.data.code) {
            const translator = translatorRes.data
            const name = translator.is_company ? translator.company_name : `${translator.first_name} ${translator.last_name}`

            return {
                id: translator.id,
                name,
                role: !translator.is_company ? '' : translator.company_type === 'school' ? 'Sprachschule' : translator.company_type === 'government' ? 'Staatliche Institution' : 'Firma',
                email: translator.email,
                phone: translator.profiles.find(p => p.role === 'translator')?.personal_information?.phones?.[0],
            }
        }
        else {
            return undefined
        }
    }
    catch(e) {
        return undefined
    }
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CustomerNewTranslationTaskController = (props) => {

    const authContext = useAuth();
    const appSettings = useAppSettings()
    const backgroundJobs = useBackgroundJobs()

    const [oldtask, setTask] = useState(getNewTask());
    const [task, taskDispatch] = useReducer(taskReducer, getNewTask());
    const [error, setError] = useState();
    const [errorFields, setErrorFields] = useState([]);
    const history = useHistory();

    const languages = processAppSettingsKeyValueArray(appSettings.appSettings.languages)
    const taskTypes = processAppSettingsKeyValueArray(appSettings.appSettings.task_types)
    
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [defaultUser, setDefaultUser] = useState(undefined)
    const query = useQuery()

    const taskType = query.get('taskType') || "translation"
    const userId = query.get('userId')
    const isSchool = query.get('isSchool') === "1"

    useEffect(() => {
        if(taskType && task) {
            taskDispatch({ type: 'UPDATE', payload: { task_type: taskType } })
        }
    }, [taskType])


    useEffect(() => {
        if(task) {
            if(isSchool) {
                taskDispatch({ type: 'UPDATE', payload: { task_type: "lesson" } })
            }
        }
    }, [isSchool])

    useEffect(() => {
        if(userId) {
            fetchDefaultUser(userId, authContext, setDefaultUser)
        } else {
            setDefaultUser(undefined)
        }
    }, [userId])


    const validateForm = () => {

        let wrongFields = [];
        const validationFields = ['title', 'description'];
        validationFields.forEach((field) => {
            if( !(field in task && task[field].trim()) ) {
                wrongFields.push(field);
            }
        });

        if( task.task_type == 'translation' ) {
            if( task.files.length == 0 ) {
                wrongFields.push('files');
            } else {
                task.files.forEach(({ source_language = null, target_language = null, doc_type = null }) => {
                    if( ( !source_language || !target_language || target_language === source_language || !doc_type) && !wrongFields.includes('files') ) {
                        wrongFields.push('files')
                    }
                })
            }
        }
        if( ["interpretation", "lesson"].includes(task.task_type)) {
            if( task.interpretations.length == 0 ) {
                wrongFields.push('interpretations')
            } else {
                task.interpretations.forEach(({ source_language = null, target_language = null }) => {
                    if( ( !source_language || !target_language || target_language === source_language ) ) {
                        wrongFields.push('interpretations')
                    }
                })
            }
        }

        setErrorFields(wrongFields);
        return wrongFields.length == 0;
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        if(validateForm()) {
            try {
                const res = await authContext.authPostRequest(`/tasks`, {
                    ...task
                })

                if(!res.data.code) {
                    backgroundJobs.runBackgroundJob(res.data.id)
                    toast.success("Der Auftrag wurde erstellt")
                    history.push(`/customer/my-tasks/${res.data.id}`)
                }
                else {
                    switch(res.data.code) {
                        case 400:
                            throw new Error(res.data.message)
                        default:
                        case 500:
                            throw new Error('Beim Laden des Auftrags ist ein Fehler aufgetreten')
                    }
                }
            }
            catch(e) {
                toast.error(e.message)
            }

        }

    }

    const updateByKeyIndex = ({ target }, key, index) => {
        taskDispatch({ type: key, payload: { update: { [target.name]: target.value }, index } });
    }

    const removeErrorKeys = (keys) => {
        const newErrors = errorFields.filter((key) => !keys.includes(key));
        newErrors.length != errorFields.length && setErrorFields(newErrors);
    }

    const addFile = (file) => {
        setTask((task) => ({
            ...task,
            files: [
                ...task.files,
                file
            ]
        }))
    }

    const updateFile = (i, updates) => {
        setTask((task) => ({
            ...task,
            files: task.files.map((file, u) => {
                if(u === i) {
                    return {
                        ...file,
                        ...updates
                    }
                }
                else {
                    return file
                }
            })
        }))
    }

    const getUploadParams = ({ meta }) => { 
        return { 
            url: `${process.env.REACT_APP_API_URL}/v1/admin/files/task-file`
        } 
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file, xhr }, status) => { 
        if(status === 'done') {

            removeErrorKeys(['files'])
            const { key } = JSON.parse(xhr.response)

            taskDispatch({ 
                type: 'ADD_FILE',
                payload: { 
                    ...getNewFile(),
                    filename: meta.name,
                    size: meta.size,
                    real_filename: key,
                    extension: '.' + meta.name.split('.').pop().toUpperCase(),
                    translator: defaultUser
                }
            });
            setShowFileUpload(false)
        }
    }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files) => { console.log(files.map(f => f.meta)) }
    
    return {
        task,
        error,
        handleSubmitForm,
        languages,
        taskTypes,
        showFileUpload, 
        setShowFileUpload,
        getUploadParams,
        handleChangeStatus,
        handleSubmit,
        errorFields,
        taskDispatch,
        updateByKeyIndex,
        defaultUser,
        isSchool,
    }
}

export default CustomerNewTranslationTaskController
