// @ts-nocheck
import { useState, useEffect } from "react"

import MessengerSkeleton from 'screens/skeletons/Messenger.skeleton'
import MessengerController from 'controllers/Messenger.controller'


import Chats from 'components/Messenger/Chats'
import Messages from 'components/Messenger/Messages'
import Profile from 'components/Messenger/Profile'

import GeneralError from 'screens/errors/GeneralError'

const Messenger = (props) => {
    const {
        isLoading,
        error,
        user,
    } = MessengerController(props)

    return (
        <div className="d-print-none">
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <h4 className="page-title">Nachrichten</h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxl-3 col-xl-6 order-xl-1">
                        <Chats {...props} isLoading={isLoading} user={user} />
                    </div>
                    { isLoading ? (
                        <div className="col-xxl-9 col-xl-12 order-xl-2">
                            <MessengerSkeleton />
                        </div>
                    ) : error ? (
                        <div className="col-xxl-9 col-xl-12 order-xl-2">
                            <GeneralError>{ error }</GeneralError>
                        </div>
                    ) : user && (
                        <>
                            <div className="col-xxl-6 col-xl-12 order-xl-2">
                                <Messages {...props} user={user} />
                            </div>
                            <div className="col-xxl-3 col-xl-6 order-xl-1 order-xxl-2">
                                <Profile {...props} user={user} />
                            </div>
                        </>
                    ) }
                </div>
            </>
        </div>
    )
}

export default Messenger