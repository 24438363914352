// @ts-nocheck
const CustomerProfileContent = ({
    user, 
    profile,
    privateUser,
    privateProfile,
    isMyProfile,
    isFirstTimeProfile,
    showTab,
    setShowTab,
    handleSubmitForm,
    handleCancelForm,
    updateProfileCustomerProfile,
}) => {
    return (
        <div className="card">
            <div className="card-body">
                { isMyProfile && !isFirstTimeProfile && (
                    <ul className="nav nav-tabs nav-bordered mb-3">
                        <li className="nav-item">
                            <a
                                className={ `nav-link rounded-0 ${showTab === 'info' ? 'active' : ''}` }
                                href="#"
                                onClick={ (e) => {
                                    e.preventDefault();
                                    setShowTab('info')
                                } }
                            >
                                Information
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={ `nav-link rounded-0 ${showTab === 'update' ? 'active' : ''}` }
                                href="#"
                                onClick={ (e) => {
                                    e.preventDefault();
                                    setShowTab('update')
                                } }
                            >
                                Bearbeiten
                            </a>
                        </li>
                    </ul>
                ) }
                <div className="tab-content">
                    { showTab === 'info' && (
                        <div
                            className="tab-pane active"
                            id="aboutme"
                        >
                            { user.is_company ? (
                                <>
                                    <h5 className="text-uppercase">
                                        <i className="mdi mdi-briefcase me-1" />
                                        Informationen
                                    </h5>
                                    <div className="timeline-alt pb-0">
                                        <h5 className="mt-0 mb-1">
                                            Profiltyp
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            { profile.type === 'government' && "Staatliche Institution" }
                                            { profile.type === 'company' && "Firma" }
                                            { profile.type === 'private' && "Privatperson" }
                                        </p>
                                    </div>
                                    <div className="timeline-alt pb-0">
                                        <h5 className="mt-0 mb-1">
                                            Ansprechpartner
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            { privateUser.first_name } { privateUser.last_name }
                                        </p>
                                    </div>
                                    <div className="timeline-alt pb-0">
                                        <h5 className="mt-0 mb-1">
                                            Beschreibung
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            { profile.customer_profile.description?.split("\n").map(line => (
                                                <>
                                                    {line}
                                                    <br/>
                                                </>
                                            )) }
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h5 className="text-uppercase">
                                        <i className="mdi mdi-account-circle me-1" />{" "}
                                        Profilinformationen
                                    </h5>
                                    <div className="timeline-alt pb-0">
                                        <h5 className="mt-0 mb-1">
                                            Profiltyp
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            Privatperson
                                        </p>
                                        <h5 className="mt-0 mb-1">
                                            Beschreibung
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            { profile.customer_profile.description?.split("\n").map(line => (
                                                <>
                                                    {line}
                                                    <br/>
                                                </>
                                            )) }
                                        </p>
                                    </div>
                                </>
                                ) }
                        </div>
                    ) }
                    { showTab === 'update' && (
                        <div className="tab-pane active" id="settings">
                            <form action="#" onSubmit={handleSubmitForm}>
                                <h5 className="mb-4 text-uppercase">
                                    <i className="mdi mdi-account-circle me-1" />{" "}
                                    Profilinformationen
                                </h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="profile_type"
                                                className="form-label"
                                            >
                                                Profiltyp
                                            </label>
                                            <input
                                                type="text"
                                                readonly 
                                                className="form-control-plaintext"
                                                value={ profile.type === 'government' ? "Staatliche Institution" : profile.type === 'company' ? "Firma" : "Privatperson" }
                                            />
                                        </div>
                                    </div>
                                    { user.is_company && (
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="company_name"
                                                    className="form-label"
                                                >
                                                    { profile.type === 'company' && 'Name der Firma' }
                                                    { profile.type === 'government' && 'Name der Institution' }
                                                </label>
                                                <input
                                                    type="text"
                                                    id="company_name"
                                                    className="form-control-plaintext"
                                                    value={ user.company_name }
                                                    readonly
                                                />
                                            </div>
                                        </div>
                                    ) }
                                </div>{" "}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="description"
                                                className="form-label"
                                            >
                                                Beschreibung
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                rows={4}
                                                value={ profile.customer_profile.description }
                                                onChange={ (e) => {
                                                    updateProfileCustomerProfile({
                                                        description: e.target.value
                                                    })
                                                } }
                                            />
                                        </div>
                                    </div>{" "}
                                    {/* end col */}
                                </div>{" "}
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-2"
                                    >
                                        <i className="mdi mdi-content-save" />{" "}
                                        { isFirstTimeProfile ? 'Profil erstellen' : 'Profil aktualisieren' }
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light mt-2"
                                        onClick={ handleCancelForm }
                                    >
                                        Abbrechen
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) }
                </div>{" "}
                {/* end tab-content */}
            </div>{" "}
            {/* end card body */}
        </div>
    )
}

export default CustomerProfileContent