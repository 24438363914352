// @ts-nocheck
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"

const TranslatorProfileContent = ({
    user,
    profile,
    privateUser,
    privateProfile,
    isContactPerson,
    isMyProfile,
    isFirstTimeProfile,
    showTab,
    setShowTab,
    handleSubmitForm,
    handleCancelForm,
    updateProfile,
    addEducation,
    updateEducation,
    removeEducation,
    addExperience,
    updateExperience,
    removeExperience,
    updateProfileTranslatorProfile,
    languages,
    addLanguage,
    updateLanguage,
    removeLanguage,
    setKeywords,
}) => {
    return (
        <div className="card">
            <div className="card-body">
                { isMyProfile && !isFirstTimeProfile && (
                    <ul className="nav nav-tabs nav-bordered mb-3">
                        <li className="nav-item">
                            <a
                                className={ `nav-link rounded-0 ${showTab === 'info' ? 'active' : ''}` }
                                href="#"
                                onClick={ (e) => {
                                    e.preventDefault();
                                    setShowTab('info')
                                } }
                            >
                                Information
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={ `nav-link rounded-0 ${showTab === 'update' ? 'active' : ''}` }
                                href="#"
                                onClick={ (e) => {
                                    e.preventDefault();
                                    setShowTab('update')
                                } }
                            >
                                Bearbeiten
                            </a>
                        </li>
                    </ul>
                ) }
                <div className="tab-content">
                    { showTab === 'info' && (
                        <div
                            className="tab-pane active"
                            id="aboutme"
                        >
                            { user.is_company ? (
                                <>
                                    <h5 className="text-uppercase">
                                        <i className="mdi mdi-briefcase me-1" />
                                        Informationen
                                    </h5>
                                    <div className="timeline-alt pb-0">
                                        <h5 className="mt-0 mb-1">
                                            Profiltyp
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            Sprachschule
                                        </p>
                                        <h5 className="mt-0 mb-1">
                                            Ansprechpartner
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            { privateUser.first_name } { privateUser.last_name }
                                        </p>
                                        <h5 className="mt-0 mb-1">
                                            Beschreibung
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            { profile.translator_profile.description?.split("\n").map(line => (
                                                <>
                                                    {line}
                                                    <br/>
                                                </>
                                            )) }
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h5 className="text-uppercase">
                                        <i className="mdi mdi-briefcase me-1" />
                                        Informationen
                                    </h5>
                                    <div className="timeline-alt pb-0">
                                        <h5 className="mt-0 mb-1">
                                            Profiltyp
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            { isContactPerson && 'Ansprechpartner' }
                                            { !isContactPerson && [profile.translator_profile?.is_translator && 'Übersetzer:in', profile.translator_profile?.is_interpreter && 'Dolmetscher:in'].filter(e => !!e).join(' / ') }
                                        </p>
                                        <h5 className="mt-0 mb-1">
                                            Beschreibung
                                        </h5>
                                        <p className="text-muted mt-2 mb-0 pb-3">
                                            { profile.translator_profile.description?.split("\n").map(line => (
                                                <>
                                                    {line}
                                                    <br/>
                                                </>
                                            )) }
                                        </p>
                                    </div>
                                    { profile.translator_profile?.experience?.length > 0 && (
                                        <>
                                            <h5 className="text-uppercase">
                                                <i className="mdi mdi-briefcase me-1" />
                                                Erfahrung
                                            </h5>
                                            <div className="timeline-alt pb-0">
                                                { profile.translator_profile.experience?.map((experience) => (
                                                    <div className="timeline-item">
                                                        <i className="mdi mdi-circle bg-info-lighten text-info timeline-icon" />
                                                        <div className="timeline-item-info">
                                                            <h5 className="mt-0 mb-1">
                                                                { experience.title }
                                                            </h5>
                                                            <p className="text-muted mt-2 mb-0 pb-3">
                                                                { experience.description }
                                                            </p>
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>
                                        </>
                                    ) }
                                    { profile.translator_profile?.education?.length > 0 && (
                                        <>
                                            <h5 className="text-uppercase mt-4">
                                                <i className="mdi mdi-briefcase me-1" />
                                                Bildung
                                            </h5>
                                            <div className="timeline-alt pb-0">
                                                { profile.translator_profile.education?.map((education) => (
                                                    <div className="timeline-item">
                                                        <i className="mdi mdi-circle bg-primary-lighten text-primary timeline-icon" />
                                                        <div className="timeline-item-info">
                                                            <h5 className="mt-0 mb-1">
                                                                { education.title }
                                                            </h5>
                                                            <p className="text-muted mt-2 mb-0 pb-3">
                                                                { education.description }
                                                            </p>
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>
                                        </>
                                    ) }
                                </>
                            ) }
                        </div>
                    ) }
                    { showTab === 'update' && (
                        <div className="tab-pane active" id="settings">
                            <form action="#" onSubmit={handleSubmitForm}>
                                <h5 className="mb-4 text-uppercase">
                                    <i className="mdi mdi-account-circle me-1" />{" "}
                                    Profilinformationen
                                </h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="profile_type"
                                                className="form-label"
                                            >
                                                Profiltyp
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control-plaintext"
                                                value={ isContactPerson ? 'Ansprechpartner' : [profile.translator_profile?.is_translator && 'Übersetzer:in', profile.translator_profile?.is_interpreter && 'Dolmetscher:in'].filter(e => !!e).join(' / ') }                                                readonly
                                            />
                                        </div>
                                    </div>
                                    { user.is_company && (
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label
                                                    htmlFor="company_name"
                                                    className="form-label"
                                                >
                                                    Name der Schule
                                                </label>
                                                <input
                                                    type="text"
                                                    id="company_name"
                                                    readonly
                                                    value={ user.company_name }
                                                    className="form-control-plaintext"
                                                />
                                            </div>
                                        </div>
                                    ) }
                                </div>{" "}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mb-3">
                                            <label
                                                htmlFor="description"
                                                className="form-label"
                                            >
                                                Beschreibung
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                rows={4}
                                                value={ profile.translator_profile.description }
                                                onChange={ (e) => {
                                                    updateProfileTranslatorProfile({
                                                        description: e.target.value
                                                    })
                                                } }
                                            />
                                        </div>
                                    </div>{" "}
                                    {/* end col */}
                                </div>{" "}
                                { !user.is_company && !isContactPerson && (
                                    <>
                                        <div className="mb-2">
                                            Ich bin:
                                        </div>
                                        <div className="form-check form-checkbox-info mb-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="is_translator"
                                                checked={profile.translator_profile.is_translator}
                                                onChange={(e) => { 
                                                    updateProfileTranslatorProfile({
                                                        is_translator: e.target.checked
                                                    })
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="is_translator">
                                                Übersertzer:in
                                            </label>
                                        </div>
                                        <div className="form-check form-checkbox-info mb-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="is_interpreter"
                                                checked={profile.translator_profile.is_interpreter}
                                                onChange={(e) => { 
                                                    updateProfileTranslatorProfile({
                                                        is_interpreter: e.target.checked
                                                    })
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="is_interpreter">
                                                Dolmetscher:in
                                            </label>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="keywords" className="form-label mb-2">
                                                Fähigkeiten
                                            </label>
                                            <ReactTagInput 
                                                id="keywords"
                                                tags={profile.translator_profile.keywords} 
                                                onChange={setKeywords}
                                                maxTags={10}
                                                placeholder="Tippen Sie und drücken Sie Enter"
                                            />
                                        </div>
                                    </>
                                ) }
                                { !isContactPerson && (
                                    <>
                                        <h5 className="mb-3 text-uppercase bg-light p-2">
                                            <i className="mdi mdi-office-building me-1" />{" "}
                                            Sprachen
                                        </h5>
                                        { profile.translator_profile.languages.map((language, i) => (
                                            <div className="row mb-3">
                                                <div className="col-xl-6 col-md-8">
                                                    <div className="mb-2">
                                                        <div className="float-end">
                                                            <button 
                                                                type="button"
                                                                className="btn btn-sm btn-default"
                                                                onClick={ () => {
                                                                    removeLanguage(i)
                                                                } }
                                                            >
                                                                <i className="uil-times" />
                                                            </button>
                                                        </div>
                                                        <label htmlFor={ `language_${i}`} className="form-label">
                                                            Sprache
                                                        </label>
                                                        <select
                                                            className="form-select"
                                                            id={ `language_${i}`}
                                                            value={language.language}
                                                            onChange={(e) => { 
                                                                updateLanguage(i, {
                                                                    language: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option value="">- Bitte auswählen -</option>
                                                            { languages.map(({key, value}) => (
                                                                <option key={key} value={key}>
                                                                    { value }
                                                                </option>
                                                            )) }
                                                        </select>
                                                    </div>
                                                    { !user.is_company && (
                                                        <div className="form-check form-checkbox-info mb-2">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={ `is_native_${i}` }
                                                                checked={language.is_native}
                                                                onChange={(e) => { 
                                                                    updateLanguage(i, {
                                                                        is_native: e.target.checked
                                                                    })
                                                                }}
                                                            />
                                                            <label className="form-check-label" htmlFor={ `is_native_${i}` }>
                                                                Dies ist meine Muttersprache
                                                            </label>
                                                        </div>
                                                    ) }
                                                </div>
                                            </div>
                                        )) }
                                        <div>
                                            <button 
                                                type="button"
                                                className="btn btn-light mb-2"
                                                onClick={ addLanguage }
                                            >   
                                                <i className="uil-plus" />{ " " }
                                                <span className="ps-2">Sprache hinzufügen</span>
                                            </button>
                                        </div>
                                    </>
                                ) }
                                { !user.is_company && !isContactPerson && (
                                    <>
                                        <h5 className="mb-3 text-uppercase bg-light p-2">
                                            <i className="mdi mdi-office-building me-1" />{" "}
                                            Bildung
                                        </h5>
                                        { profile.translator_profile.education?.map((education, i) => (
                                            <div className="row mb-3 border-bottom border-light">
                                                <div className="col-xl-6 col-md-8">
                                                    <div className="mb-2">
                                                        <div className="float-end">
                                                            <button 
                                                                type="button"
                                                                className="btn btn-sm btn-default"
                                                                onClick={ () => {
                                                                    removeEducation(i)
                                                                } }
                                                            >
                                                                <i className="uil-times" />
                                                            </button>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor={ `education_${i}_title` } className="form-label">
                                                                Titel
                                                            </label>
                                                            <input
                                                                type="text" 
                                                                id={ `education_${i}_title` }
                                                                className="form-control" 
                                                                value={ education.title }
                                                                onChange={(e) => { 
                                                                    updateEducation(i, {
                                                                        title: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor={ `education_${i}_description` } className="form-label mb-3">
                                                        <strong>Beschreibung</strong>
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        id={ `education_${i}_title` }
                                                        rows={6}
                                                        value={ education.description }
                                                        onChange={(e) => { 
                                                            updateEducation(i, {
                                                                description: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )) }
                                        <div>
                                            <button 
                                                type="button"
                                                className="btn btn-light mb-2"
                                                onClick={ addEducation }
                                            >   
                                                <i className="uil-plus" />{ " " }
                                                <span className="ps-2">Bildung hinzufügen</span>
                                            </button>
                                        </div>
                                        {/* end row */}
                                        <h5 className="mb-3 text-uppercase bg-light p-2">
                                            <i className="mdi mdi-earth me-1" />{" "}
                                            Erfahrung
                                        </h5>
                                        { profile.translator_profile.experience?.map((experience, i) => (
                                            <div className="row mb-3 border-bottom border-light">
                                                <div className="col-xl-6 col-md-8">
                                                    <div className="mb-2">
                                                        <div className="float-end">
                                                            <button 
                                                                type="button"
                                                                className="btn btn-sm btn-default"
                                                                onClick={ () => {
                                                                    removeExperience(i)
                                                                } }
                                                            >
                                                                <i className="uil-times" />
                                                            </button>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label htmlFor={ `experience_${i}_title` } className="form-label">
                                                                Titel
                                                            </label>
                                                            <input
                                                                type="text" 
                                                                id={ `experience_${i}_title` }
                                                                className="form-control" 
                                                                value={ experience.title }
                                                                onChange={(e) => { 
                                                                    updateExperience(i, {
                                                                        title: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor={ `experience_${i}_description` } className="form-label mb-3">
                                                        <strong>Beschreibung</strong>
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        id={ `experience_${i}_title` }
                                                        rows={6}
                                                        value={ experience.description }
                                                        onChange={(e) => { 
                                                            updateExperience(i, {
                                                                description: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )) }
                                        <div>
                                            <button 
                                                type="button"
                                                className="btn btn-light"
                                                onClick={ addExperience }
                                            >   
                                                <i className="uil-plus" />{ " " }
                                                <span className="ps-2">Erfahrung hinzufügen</span>
                                            </button>
                                        </div>
                                    </>
                                ) }
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-2"
                                    >
                                        <i className="mdi mdi-content-save" />{" "}
                                        { isFirstTimeProfile ? 'Profil erstellen' : 'Profil aktualisieren' }
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-light mt-2 ms-1"
                                        onClick={ handleCancelForm }
                                    >
                                        Abbrechen
                                    </button>
                                </div>
                            </form>
                        </div>
                    ) }
                </div>{" "}
                {/* end tab-content */}
            </div>{" "}
            {/* end card body */}
        </div>
    )
}

export default TranslatorProfileContent