// @ts-nocheck
import moment from 'moment'

import TranslatorProfileSkeleton from 'screens/skeletons/TranslatorProfile.skeleton'
import GeneralError from 'screens/errors/GeneralError'
import TranslatorProfileController from 'controllers/TranslatorProfile.controller'

import ProfileCardContent from 'components/Profile/Translator/ProfileCardContent'
import MinimizedProfileCardContent from 'components/Profile/Translator/MinimizedProfileCardContent'
import TranslatorProfileContent from 'components/Profile/Translator/TranslatorProfileContent'

const TanslatorProfile = ({ 
    isMyProfile,
    id
 }) => {

    const {
        user,
        profile,
        companyUser,
        companyProfile,
        showProfile, 
        setShowProfile,
        isLoading,
        error,
        showTab,
        setShowTab,
        handleSubmitForm,
        handleCancelForm,
        validationErrors,
        languages,
        isFirstTimeProfile,
        updateProfile,
        updateProfileTranslatorProfile,
        updateCompanyProfile,
        updateCompanyProfileTranslatorProfile,
        setKeywords,
        addLanguage,
        updateLanguage,
        removeLanguage,
        addLanguageToCompany,
        updateLanguageToCompany,
        removeLanguageFromCompany,
        addEducation,
        updateEducation,
        removeEducation,
        addExperience,
        updateExperience,
        removeExperience,
    } = TranslatorProfileController({ id, isMyProfile });

    return (
        <>
            { isLoading && <TranslatorProfileSkeleton /> }
            { error && (
                <GeneralError
                    title={ isMyProfile ? 'Ihr Profil' : 'Fehler' }
                >{ error }</GeneralError>
            ) }
            { user && profile && (
                <>
                    { isMyProfile ? (
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box">
                                        <h4 className="page-title">Ihr Profil</h4>
                                    </div>
                                </div>
                            </div>
                            { isFirstTimeProfile && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-info">
                                            Bitte füllen Sie Ihr Profil aus und klicken Sie dann auf "Profil erstellen".
                                        </div>
                                    </div>
                                </div>
                            ) }
                        </>
                    ) : (
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box">
                                    <h4 className="page-title">{ user.is_company ? profile.company_name : `${user.first_name} ${user.last_name}` }</h4>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-xl-4 col-lg-5">
                            <div className="card text-center">
                                <div className="card-body">
                                    { showProfile === 0 ? (
                                        <ProfileCardContent
                                            user={companyUser ? companyUser : user}
                                            profile={companyProfile ? companyProfile : profile}
                                            isMyProfile={isMyProfile}
                                            isFirstTimeProfile={isFirstTimeProfile} 
                                            handleSubmitForm={handleSubmitForm}
                                            handleCancelForm={handleCancelForm}
                                            showTab={showTab}
                                            setShowTab={setShowTab}
                                            languages={languages}
                                            view="translator"
                                        />
                                    ) : (
                                        <div className="inbox-widget text-start">
                                            <MinimizedProfileCardContent
                                                profileIndex={0}
                                                profile={companyProfile ? companyProfile : profile}
                                                setShowProfile={setShowProfile}
                                                user={companyUser ? companyUser : user}
                                            />
                                        </div>
                                    ) }
                                </div>
                            </div>
                            { user && companyUser && (
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="header-title mb-3">Ansprechpartner</h4>
                                        <div className="inbox-widget">
                                            { showProfile === 1 ? (
                                                <div className="text-center">
                                                    <ProfileCardContent
                                                        user={user}
                                                        profile={profile}
                                                        isMyProfile={isMyProfile}
                                                        isFirstTimeProfile={isFirstTimeProfile} 
                                                        handleSubmitForm={handleSubmitForm}
                                                        handleCancelForm={handleCancelForm}
                                                        showTab={showTab}
                                                        setShowTab={setShowTab}
                                                        languages={languages}
                                                        view="translator"
                                                    />
                                                </div>
                                            ) : (
                                                <MinimizedProfileCardContent
                                                    profileIndex={1}
                                                    profile={profile}
                                                    setShowProfile={setShowProfile}
                                                    user={user}
                                                />
                                            ) }
                                        </div>{" "}
                                    </div>
                                </div>
                            )}
                        </div>{" "}
                        {/* end col*/}
                        <div className="col-xl-8 col-lg-7">
                            <TranslatorProfileContent
                                user={(showProfile === 0 && companyUser) ? companyUser : user}
                                profile={(showProfile === 0 && companyProfile) ? companyProfile : profile}
                                privateUser={user}
                                privateProfile={profile}
                                isMyProfile={isMyProfile}
                                isContactPerson={showProfile === 1}
                                isFirstTimeProfile={isFirstTimeProfile}
                                showTab={showTab}
                                setShowTab={setShowTab}
                                handleSubmitForm={handleSubmitForm}
                                handleCancelForm={handleCancelForm}
                                updateProfile={(showProfile === 0 && companyUser) ? updateCompanyProfile : updateProfile}
                                updateProfileTranslatorProfile={(showProfile === 0 && companyUser) ? updateCompanyProfileTranslatorProfile : updateProfileTranslatorProfile}
                                addExperience={addExperience}
                                updateExperience={updateExperience}
                                removeExperience={removeExperience}
                                languages={languages}
                                addLanguage={(showProfile === 0 && companyUser) ? addLanguageToCompany : addLanguage}
                                updateLanguage={(showProfile === 0 && companyUser) ? updateLanguageToCompany : updateLanguage}
                                removeLanguage={(showProfile === 0 && companyUser) ? removeLanguageFromCompany : removeLanguage}
                                setKeywords={setKeywords}
                                addEducation={addEducation}
                                updateEducation={updateEducation}
                                removeEducation={removeEducation}
                            />
                        </div>
                    </div>
                </>
            ) }
        </>
    )
}

export default TanslatorProfile