// @ts-nocheck
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useAuth } from "contexts/AuthContext"

import './StripeForm.css'

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#c4f0ff",
			color: "#fff",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#fce883" },
			"::placeholder": { color: "#87bbfd" }
		},
		invalid: {
			iconColor: "#ffc7ee",
			color: "#ffc7ee"
		}
	}
}

const StripeForm = ({ task, onSuccess, onCancel }) => {
    const authContext = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const stripe = useStripe()
    const elements = useElements()


    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        })

        if(!error) {
            try {
                const { id } = paymentMethod
                const res = await authContext.authPostRequest(`/my/payments/stripe/${task.id}`, {
                    paymentId: id
                })

                if(res.data.success) {
                    toast.success("Die Zahlung wurde erfolgreich verarbeitet.")
                    console.error({"res.data": res.data})
                    onSuccess()
                } else {
                    throw new Error()
                }

            } catch (error) {
                toast.error("Die Zahlung konnte nicht verarbeitet werden.")
                console.error({"error.1": error})
            }
        } else {
            toast.error("Die Zahlung konnte nicht verarbeitet werden.")
            console.error({"error.2": error})
        }

        setIsLoading(false)
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset className="FormGroup mb-2">
                    <div className="FormRow">
                        <CardElement options={CARD_OPTIONS}/>
                    </div>
                </fieldset>
                <button 
                    className="btn btn-primary"
                    disabled={isLoading}
                >
                    {isLoading ? 'Zahlung wird verarbeitet...' : 'Bezahlen'}
                </button>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={onCancel}
                >
                    Abbrechen
                </button>
            </form>    
        </>
    )
}

export default StripeForm