// @ts-nocheck
const GeneralError = ({ title, children }) => {

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <h4 className="page-title">{ title }</h4>
                    </div>
                </div>
            </div>
            {/* end page title */}
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            { children }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GeneralError