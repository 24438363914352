// @ts-nocheck
import { useState, useEffect } from 'react'
import { useAuth } from "contexts/AuthContext"

const AdminTestRequestResopnse = () => {
    const authContext = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [result, setResult] = useState({})

    const pingServer = async () => {
        setIsLoading(true)
        try {
            const res = await authContext.authPostRequest(`/my/test-request-response`, {
                thisIsNice: "lol",
                thatSucks: "yeah too"
            })

            if(!res.data.code) {
                setResult(res.data)
            }
            else {
                switch(res.data.code) {
                    case 404:
                    case 400:
                        console.error(res)
                    default:
                    case 500:
                        throw new Error('Beim Laden des Auftrags ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            setResult(undefined)
            setError(e.message)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        pingServer()
    }, [])

    return (
        <div>
            { error && (
                <div>
                    Error: {error}
                </div>
            ) }
            { isLoading && (
                <div>Loading...</div>
            ) }
            <pre>
                Result: {JSON.stringify(result, 2)}
            </pre>
        </div>
    )
};

export default AdminTestRequestResopnse;
