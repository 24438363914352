// @ts-nocheck
import {
    useParams,
    useHistory
  } from "react-router-dom";
  
import TranslatorProfile from 'components/TranslatorProfile'

const CustomerTranslator = () => {
    const { id } = useParams()

    return (
        <TranslatorProfile
            isMyProfile={ false }
            id={id}
        />
    )
}

export default CustomerTranslator