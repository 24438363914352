import React, { useContext, useState, useEffect } from "react"
import { postRequest, putRequest, patchRequest, getRequest, deleteRequest } from "clients/ApiClient"

import AppLoadingScreen from "components/AppLoadingScreen"

const AppSettingsContext = React.createContext<AppSettingsContextType | undefined>(undefined)

export const useAppSettings = () => {
    return useContext(AppSettingsContext)
}

interface AppSettingsContextType {
    appSettings: any; // change this
    setAppSettings: React.Dispatch<React.SetStateAction<any | undefined>>;
    staticSettings: any; // change this
}

interface AppSettingsProviderProps {
    children: React.ReactElement;
}
export const AppSettingsProvider = ({ children }: AppSettingsProviderProps) => {
    const [appSettings, setAppSettings] = useState<any>({})
    const [staticSettings, setStaticSettings] = useState({})

    useEffect(() => {
        initializeApp()
    }, [])

    const initializeApp = async () => {
        getRequest('/static/app-settings')
            .then((data) => {
                setStaticSettings(data.data)
            })
        getRequest('/settings/app-settings')
            .then((data) => {
                setAppSettings(data.data)
            })
    }
    
    const value: any = {
        appSettings,
        setAppSettings,
        staticSettings
    }

    if(!appSettings || !staticSettings) {
        return <AppLoadingScreen />
    }

    return (
        <AppSettingsContext.Provider value={value}>
            { children }
        </AppSettingsContext.Provider>
    )
}