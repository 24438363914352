// @ts-nocheck
import { Redirect, useHistory } from "react-router-dom"
import { useAuth } from "contexts/AuthContext"

const Redirector = () => {
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    const logoutAndRedirect = async () => {
        await logout()
        history.push("/auth/login")
    }

    if(!currentUser) { 
        return <Redirect to="/auth/login" />
    }

    switch(currentUser.activeProfile.role) {
        case "customer":
            return <Redirect to="/customer/dashboard" />
        case "translator":
            return <Redirect to="/translator/dashboard" />
        case "admin":
            return <Redirect to="/admin/dashboard" />
        default:
            return (
                <div>
                    <button type="button" onClick={ logoutAndRedirect }>
                        Zum Fortfahren hier klicken
                    </button>
                </div>
            )
    }
}

export default Redirector