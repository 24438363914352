// @ts-nocheck
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";

import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"
import { processAppSettingsKeyValueArray } from 'utils/settings'

const fetchUsers = async (setUsers, { sortBy, limit, page, q, showTranslators, showInterpreters, showSchools, langs }, setIsLoading, authContext) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    const user = await authGetRequest(`/translators?sortBy=${sortBy}&limit=${limit}&page=${page}&q=${q}&showTranslators=${showTranslators}&showInterpreters=${showInterpreters}&showSchools=${showSchools}&langs=${langs}`)
    setUsers(user.data)
    setIsLoading(false)
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const getDefaultSearchCriteria = (query) => {
    return {
        q: query.get('q'),
        showTranslators: query.has('showTranslators') && query.get('showTranslators') === '0' ? false : true,
        showInterpreters: query.has('showInterpreters') && query.get('showInterpreters') === '0' ? false : true,
        showSchools: query.has('showSchools') && query.get('showSchools') === '0' ? false : true,
        langs: query.get('langs') ? query.get('langs').split(',') : []
    }
}

const CustomerTranslatorsController = (props) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()

    const [users, setUsers] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const history = useHistory()
    const query = useQuery()

    const [searchCriteria, setSearchCriteria] = useState(getDefaultSearchCriteria(query))

    const sortBy = query.get('sortBy') || 'entry_date'
    const limit = query.get('limit') || 10
    const page = query.get('page') || 1
    const q = query.get('q') || ''
    const showTranslators = query.has('showTranslators') ? query.get('showTranslators') : true;
    const showInterpreters = query.has('showInterpreters') ? query.get('showInterpreters') : true;
    const showSchools = query.has('showSchools') ? query.get('showSchools') : true;
    const langs = query.get('langs');

    const languages = processAppSettingsKeyValueArray(appSettings.appSettings.languages)

    const handleUpdateSearch = (e) => {
        e.preventDefault()
        const {
            q,
            showTranslators,
            showInterpreters,
            showSchools,
            langs,
        } = searchCriteria

        history.push(buildLink({
            q,
            showTranslators: showTranslators ? 1 : 0,
            showInterpreters: showInterpreters ? 1 : 0,
            showSchools: showSchools ? 1 : 0,
            langs: langs ? langs.join(',') : ''
        }))
    }

    useEffect(() => {
        fetchUsers(setUsers, { sortBy, limit, page, q, showTranslators, showInterpreters, showSchools, langs }, setIsLoading, authContext)
    }, [sortBy, limit, page, q, showTranslators, showInterpreters, showSchools, langs])

    const buildLink = ({ 
        page: newPage, 
        limit: newLimit, 
        sortBy: newSortBy,
        q: newQ,
        showTranslators: newShowTranslators,
        showInterpreters: newShowInterpreters,
        showSchools: newShowSchools,
        langs: newLangs,
    }) => {
        return `/customer/translators?page=${newPage || page}&limit=${newLimit || limit}&sortBy=${newSortBy || sortBy}` + 
                `&q=${newQ !== undefined ? newQ : q}&showTranslators=${newShowTranslators !== undefined ? newShowTranslators : showTranslators}&showInterpreters=${newShowInterpreters !== undefined ? newShowInterpreters : showInterpreters}&showSchools=${newShowSchools !== undefined ?  newShowSchools : showSchools}&langs=${newLangs !== undefined ? newLangs : langs}`;
    }

    return {
        users,
        setUsers,
        isLoading,
        error,
        languages,
        buildLink,
        searchCriteria,
        setSearchCriteria,
        handleUpdateSearch,
    }
}

export default CustomerTranslatorsController