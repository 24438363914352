// @ts-nocheck
import { useState, useEffect } from 'react'

import { useAuth } from "contexts/AuthContext"

const fetchUser = async (userId, setUser, setIsLoading, setError, authContext) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    try {
        const user = await authGetRequest(`/users/${userId}`)

        if(!user.data.code) {
            setUser(user.data)
        }
        else {
            switch(user.data.code) {
                case 404:
                case 400:
                    console.log(user)
                    throw new Error('Der Kontakt wurde entweder gelöscht oder konnte nicht gefunden werden.')
                default:
                case 500:
                    throw new Error('Beim Laden des Kontakes ist ein Fehler aufgetreten')
            }
        }
    }
    catch(e) {
        setUser(undefined)
        setError(e.message)
    }
    setIsLoading(false)
}

const MessengerController = (props) => {
    const authContext = useAuth()

    const [user, setUser] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()

    const userId = props.match.params.userId

    useEffect(() => {
        if(!userId) {
            setUser(undefined)
        } 
        else {
            fetchUser(userId, setUser, setIsLoading, setError, authContext)
        }
    }, [userId])

    return {
        user,
        isLoading,
        error,
    }
}

export default MessengerController