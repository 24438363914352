// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment"
import { Link } from "react-router-dom"

import ChatsSkeleton from 'screens/skeletons/components/Messenger/Chats.skeleton'
import ChatsController from 'controllers/components/Messenger/Chats.controller'

const truncate = (string, length) => {
    if(string.length <= length) {
        return string
    }
    if(length > 3) {
        return string.substr(0, length - 3) + '...'
    }
    return string.substr(0, length)
}

const Chats = (props) => {
    const {
        currentUser,
        isLoading,
        chats,
        chatListFrame,
        chatListContainer,
        markChatAsRead,
    } = ChatsController(props)

    return (
        <div className="card">
            <div className="card-body p-0">
                <div className="tab-content">
                    <div className="tab-pane show active p-3" id="newpost">
                        <div className="app-search">
                            <form>
                                <div className="mb-2 position-relative">
                                    <input type="text" className="form-control" placeholder="In Nachrichten suchen..." />
                                    <span className="mdi mdi-magnify search-icon"></span>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div data-simplebar="init" style={ { maxHeight: "550px" } }>
                                    <div className="simplebar-wrapper" style={ { margin: "0px" } }>
                                        <div className="simplebar-height-auto-observer-wrapper">
                                            <div className="simplebar-height-auto-observer" />
                                        </div>
                                        <div className="simplebar-mask">
                                            <div className="simplebar-offset" style={ { right: "0px", bottom: "0px" } }>
                                                <div
                                                    className="simplebar-content-wrapper"
                                                    style={ { height: "auto", overflow: "hidden scroll" } }
                                                    ref={chatListFrame}
                                                >
                                                    <div 
                                                        className="simplebar-content" 
                                                        style={ { padding: "0px" } }
                                                        ref={chatListContainer}
                                                    >
                                                        { chats.length > 0 && chats.map((chat) => {
                                                            // const isOwnMessage = message.user_1_id === currentUser.id
                                                            const chatTime = moment(chat.last_message_sent_at)
                                                            const chatIsToday = moment().diff(chatTime, 'days') === 0
                                                            const chatIsThisYear = moment().diff(chatTime, 'years') === 0
                                                            const isSelected = props.user && props.user.id === chat.user_interactor.id

                                                            const role = currentUser.profiles.find((p) => p.is_active_profile)?.role
                                                            let chatUrl = '/'
                                                            if(role) {
                                                                chatUrl = `/${role}/messenger/${chat.user_interactor.id}`
                                                            }

                                                            return (
                                                                <Link
                                                                    to={ chatUrl }
                                                                    className="text-body"
                                                                    key={chat.id}
                                                                    onClick={() => {
                                                                        markChatAsRead(chat.id)
                                                                    }}
                                                                >
                                                                    <div className={ `d-flex align-items-start  ${isSelected ? 'bg-light' : ''} p-2` }>
                                                                        <img src="/assets/images/users/avatar-0.png" className="me-2 rounded-circle" height="48" alt="Brandon Smith" />
                                                                        <div className="w-100 overflow-hidden">
                                                                            <h5 className="mt-0 mb-0 font-14">
                                                                                <span className="float-end text-muted font-12">
                                                                                    { chatIsToday ? (
                                                                                        <i>{ chatTime.format('HH:mm') }</i>
                                                                                    ) : chatIsThisYear ? (
                                                                                        <i>{ chatTime.format('DD/MM') }</i>
                                                                                    ) : (
                                                                                        <i>{ chatTime.format('DD/MM/YY') }</i>
                                                                                    ) }
                                                                                </span>
                                                                                { chat.user_interactor.name }
                                                                            </h5>
                                                                            <p className="mt-1 mb-0 text-muted font-14">
                                                                                { chat.unseen_messages > 0 && (
                                                                                    <span className="w-25 float-end text-end"><span className="badge badge-danger-lighten">{ chat.unseen_messages }</span></span>
                                                                                ) }
                                                                                <span className="w-75">
                                                                                    { truncate(chat.last_message_content, 50) }
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            )
                                                        }) }
                                                        { isLoading && <ChatsSkeleton /> }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="simplebar-placeholder" style={ { width: "782px", height: "618px" } } />
                                    </div>
                                    <div className="simplebar-track simplebar-horizontal" style={ { visibility: "hidden" } }>
                                        <div className="simplebar-scrollbar" style={ { width: "0px", display: "none" } }>
                                    </div>
                                </div>
                                <div className="simplebar-track simplebar-vertical" style={ { visibility: "visible" } }>
                                    <div className="simplebar-scrollbar" style={ { height: "489px", transform: "translate3d(0px, 0px, 0px)", display: "block" } } />
                                </div>
                            </div>
                            </div> 
                        </div>
                    </div> 
                </div>
            </div> 
        </div> 
    )
}

export default Chats