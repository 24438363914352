// @ts-nocheck
import { useState } from "react"
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { useAuth } from "contexts/AuthContext"
import { toast } from 'react-toastify';
import { humanFileSize } from 'utils/files'

const TaskTranslatorFiles = ({ task, setTask, isEditable, refetchActivity }) => {
    const [showFileUpload, setShowFileUpload] = useState(false);
    const authContext = useAuth()

    const deleteFile = async (i) => {
        if(task.status !== "in_progress") return

        try {
            const res = await authContext.authDeleteRequest(`/my/translator/translator-file/${task.id}/${i}`)

            if(!res.data.code && res.data) {
                setTask(res.data)
                refetchActivity()
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                    default:
                    case 500:
                        throw new Error('Beim Ändern des Status ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }    }

    const addFile = async (file) => {
        if(task.status !== "in_progress") return

        try {
            const res = await authContext.authPostRequest(`/my/translator/add-translator-file/${task.id}`, {
                file
            })

            if(!res.data.code && res.data) {
                setTask(res.data)
                refetchActivity()
            }
            else {
                switch(res.data.code) {
                    case 400:
                        throw new Error(res.data.message)
                    default:
                    case 500:
                        throw new Error('Beim Ändern des Status ist ein Fehler aufgetreten')
                }
            }
        }
        catch(e) {
            toast.error(e.message)
        }
    } 

    const getUploadParams = ({ meta }) => { 
        return { 
            url: `${process.env.REACT_APP_API_URL}/v1/admin/files/task-file`
        } 
    }

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file, xhr }, status) => { 
        if(status === 'done') {
            const { key } = JSON.parse(xhr.response)

            addFile({
                filename: meta.name,
                size: meta.size,
                real_filename: key,
                extension: '.' + meta.name.split('.').pop().toUpperCase(),
            })

            setShowFileUpload(false)
        }
    }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files) => { console.log(files.map(f => f.meta)) }


    return (
        <div className="card">
            <div className="card-body">
                <h5 className="mb-3 mt-2">
                    Vom Übersetzer hochgeladenen Dateien
                </h5>
                <div className="table-responsive">
                    <table className="table table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th>Datei</th>
                                { isEditable && (
                                    <th></th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {task.translator_files.map((translator_file, i) => (
                                <tr key={ i }>
                                    <td>
                                        <div className="row align-items-center" style={ { width: '250px' } }>
                                            <div className="col-auto">
                                                <div className="avatar-sm">
                                                    <span className="avatar-title rounded">
                                                        <a 
                                                            href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${translator_file.real_filename}` } 
                                                            target="_blank"
                                                            className="text-white"
                                                        >
                                                            { translator_file.extension }
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col ps-0 text-truncate">
                                                <div className="text-muted fw-bold">
                                                    <a 
                                                        href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${translator_file.real_filename}` } 
                                                        target="_blank"
                                                        className="text-muted text-ellipsis text-truncate d-inline-block w-100"
                                                    >
                                                        { translator_file.filename }
                                                    </a>
                                                </div>
                                                <p className="mb-0">{ humanFileSize(translator_file.size) }</p>
                                            </div>
                                        </div>
                                    </td>
                                    { isEditable && (
                                        <td>
                                            <button type="button" className="btn btn-link text-muted btn-sm" onClick={() => deleteFile(i)}>
                                                <i className="uil-times" />
                                            </button>
                                        </td>
                                    ) }
                                </tr>
                            ))}
                            {isEditable && (
                                <tr>
                                    <td colSpan={2}>
                                        {showFileUpload ? (
                                            <div className="row">
                                                <div className="col">
                                                    <Dropzone
                                                        getUploadParams={getUploadParams}
                                                        onChangeStatus={handleChangeStatus}
                                                        onSubmit={handleSubmit}
                                                        accept="*"
                                                        maxFiles={1}
                                                        multiple={false}
                                                        SubmitButtonComponent={null}
                                                        inputWithFilesContent="Datei hochladen"
                                                        inputContent="Dateien ziehen oder zum Durchsuchen klicken"
                                                        classNames={ {
                                                            inputLabel: 'dz-message needsclick font-18',
                                                            dropzone: 'dropzone text-center needsclick'
                                                        } }
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <button type="button" className="btn btn-link text-muted btn-sm" onClick={() => { setShowFileUpload(false) }}>
                                                        <i className="uil-times" />
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-light"
                                                onClick={ () => {
                                                    setShowFileUpload(true)
                                                } }
                                            >
                                                <i className="uil-cloud-upload" />{" "}
                                                Datei hochladen
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TaskTranslatorFiles