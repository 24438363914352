// @ts-nocheck
import { Fragment } from "react"
import { Link } from "react-router-dom"
import { apiToDisplayDate } from 'utils/datetime'

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import CustomerNewTranslationTaskController from 'controllers/CustomerNewTranslationTask.controller'
import GeneralError from 'screens/errors/GeneralError'

import { humanFileSize } from 'utils/files'

const CustomerNewTranslationTask = (props) => {
    
    const {
        task,
        error,
        handleSubmitForm,
        languages,
        taskTypes,
        showFileUpload, 
        setShowFileUpload,
        getUploadParams,
        handleChangeStatus,
        handleSubmit,
        errorFields,
        taskDispatch,
        updateByKeyIndex,
        defaultUser,
    } = CustomerNewTranslationTaskController(props)

    return (
        <div>
            { error && (
                <GeneralError
                    title="Auftrag / Anfrage erstellen"
                >{ error }</GeneralError>
            ) }
            { task && (
                <>
                    <div className="row">
                        <div className="col-6">
                            <div className="page-title-box">
                                { defaultUser ? (
                                    <h4 className="page-title">Auftrag / Anfrage für <Link to={ `/customer/translators/${defaultUser.id}` } className="text-info">{defaultUser.name}</Link> erstellen</h4>
                                ) : (
                                    <h4 className="page-title">Auftrag / Anfrage erstellen</h4>
                                ) }
                            </div>
                        </div>
                    </div>
                    {/* end page title */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <form action="#" onSubmit={handleSubmitForm}>
                                        <div className="row">
                                            <div className="col-8">

                                                <div className='col-6'>
                                                    <label htmlFor="task_id" className="form-label" >Dienstleistung</label>
                                                    <select className='form-select' value={task.task_type}  onChange={({ target }) => taskDispatch({ type: 'UPDATE', payload: { task_type: target.value } })}>
                                                        <option value='translation'>Übersetzung</option>
                                                        <option value='interpretation'>Dolmetschen</option>
                                                        <option value='lesson'>Sprachunterricht</option>
                                                    </select>
                                                </div>
                                                <div className="card bg-light mb-3 shadow-none border mt-3">
                                                    <div className="p-3">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="task_id"
                                                                        className="form-label"
                                                                    >
                                                                        Betreff* {errorFields.includes('title') && <span style={{ color: 'var(--bs-red)' }}>(Pflichtfeld)</span>}
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="title"
                                                                        className="form-control"
                                                                        value={task.title}
                                                                        onChange={({ target }) => taskDispatch({ type: 'UPDATE', payload: { title: target.value } }) }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>
                                                                <label
                                                                    htmlFor="task_date"
                                                                    className="form-label"
                                                                >
                                                                    Auftragsdatum
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    id="entry_date"
                                                                    className="form-control"
                                                                    value={apiToDisplayDate(task.task_date)}
                                                                    onChange={({ target }) => taskDispatch({ type: 'UPDATE', payload: { task_date: target.value } })}
                                                                />

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div>
                                                                    <label
                                                                        htmlFor="description"
                                                                        className="form-label"
                                                                    >
                                                                        Beschreibung* {errorFields.includes('description') && <span style={{ color: 'var(--bs-red)' }}>(Pflichtfeld)</span>}
                                                                    </label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        id="description"
                                                                        rows={5}
                                                                        value={ task.description }
                                                                        onChange={ ({ target }) => taskDispatch({ type: 'UPDATE', payload: { description: target.value } }) }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {["interpretation", "lesson"].includes(task.task_type) && (
                                            <>
                                                <h5 className="mb-3 mt-2">
                                                    { task.task_type === "interpretation" && "Dolmetschereinsatz" }
                                                    { task.task_type === "lesson" && "Sprachunterricht" }
                                                    {" "}{errorFields.includes('interpretations') && <span style={{ color: 'var(--bs-red)' }}>(Pflichtfeld)</span>}
                                                </h5>
                                                <div className="table-responsive">
                                                    <table className="table table-condensed table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Sprachen</th>
                                                                {task.interpretations[0]?.translator && (
                                                                    <th>Zugewiesen an</th>
                                                                )}
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { task.interpretations.map((interpretation, i) => (
                                                                <Fragment key={ i }>
                                                                    <tr>
                                                                        <td className="pb-1">
                                                                            <div className="input-group flex-nowrap">
                                                                                <select
                                                                                    id={`interpretations_${i}_source_language`}
                                                                                    className="form-select"
                                                                                    value={ interpretation.source_language }
                                                                                    name='source_language'
                                                                                    onChange={(e) => updateByKeyIndex(e, 'UPDATE_INTERPRETATION', i)}
                                                                                >
                                                                                    <option>-Bitte auswählen-</option>
                                                                                    { languages.map((language) => (
                                                                                        <option 
                                                                                            key={ language.key }
                                                                                            value={ language.key }
                                                                                        >{ language.value }</option>
                                                                                    )) }
                                                                                </select>
                                                                                <span className="input-group-text">
                                                                                    <i className="uil-arrow-right" />
                                                                                </span>
                                                                                <select
                                                                                    id={`interpretations_${i}_target_language`}
                                                                                    className="form-select"
                                                                                    value={ interpretation.target_language }
                                                                                    name='target_language'
                                                                                    onChange={(e) => updateByKeyIndex(e, 'UPDATE_INTERPRETATION', i)}
                                                                                >
                                                                                    <option>-Bitte auswählen-</option>
                                                                                    { languages.map((language) => (
                                                                                        <option 
                                                                                            key={ language.key }
                                                                                            value={ language.key }
                                                                                        >{ language.value }</option>
                                                                                    )) }
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        {interpretation.translator && (
                                                                            <td>{ interpretation.translator.name }</td>
                                                                        )}
                                                                        <td className="pb-1" style={ { borderBottom: "1px solid white" } }>
                                                                            <button 
                                                                                type="button"
                                                                                className="btn btn-link text-muted btn-sm"
                                                                                onClick={() => taskDispatch({ type: 'REMOVE_INTERPRETATION', payload: i })}
                                                                            >
                                                                                <i className="uil-times" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )) }
                                                            <tr>
                                                                <td colSpan={task.interpretations[0]?.translator ? 4 : 3}>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-light"
                                                                        onClick={() => taskDispatch({ type: 'ADD_INTERPRETATION', payload: { translator: defaultUser } })}
                                                                    >
                                                                        <i className="uil-plus" />{" "}
                                                                        { task.task_type === "interpretation" && "Dolmetschereinsatz hinzufügen"}
                                                                        { task.task_type === "lesson" && "Sprachunterricht hinzufügen"}
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </>
                                        )}

                                        {task.task_type == 'translation' && (
                                            <>
                                                <h5 className="mb-3 mt-2">
                                                    Dokumente* {errorFields.includes('files') && <span style={{ color: 'var(--bs-red)' }}>(Pflichtfeld)</span>}
                                                </h5>
                                                <div className="table-responsive">
                                                    <table className="table table-condensed table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Datei</th>
                                                                <th>Typ</th>
                                                                <th>Sprachen</th>
                                                                {task.files[0]?.translator && (
                                                                    <th>Zugewiesen an</th>
                                                                )}
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {task.files.map((file, i) => (
                                                                <tr key={ i }>
                                                                    <td>
                                                                        <div className="row align-items-center" style={ { width: '250px' } }>
                                                                            <div className="col-auto">
                                                                                <div className="avatar-sm">
                                                                                    <span className="avatar-title rounded">
                                                                                        <a 
                                                                                            href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.real_filename}` } 
                                                                                            target="_blank"
                                                                                            className="text-white"
                                                                                        >
                                                                                            { file.extension }
                                                                                        </a>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col ps-0 text-truncate">
                                                                                <div className="text-muted fw-bold">
                                                                                    <a 
                                                                                        href={ `${process.env.REACT_APP_AWS_S3_SERVER}/${file.real_filename}` } 
                                                                                        target="_blank"
                                                                                        className="text-muted text-ellipsis text-truncate d-inline-block w-100"
                                                                                    >
                                                                                        { file.filename }
                                                                                    </a>
                                                                                </div>
                                                                                <p className="mb-0">{ humanFileSize(file.size) }</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            id="task_type"
                                                                            className="form-select"
                                                                            value={ file.doc_type }
                                                                            name='doc_type'
                                                                            onChange={(e) => updateByKeyIndex(e, 'UPDATE_FILE', i)} 
                                                                        >
                                                                            <option>-Bitte auswählen-</option>
                                                                            { taskTypes.map((language) => (
                                                                                <option 
                                                                                    key={ language.key }
                                                                                    value={ language.key }
                                                                                >{ language.value }</option>
                                                                            )) }
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <div className="input-group flex-nowrap">
                                                                            <select
                                                                                id={`files_${i}_source_language`}
                                                                                className="form-select"
                                                                                name='source_language'
                                                                                value={ file.source_language }
                                                                                onChange={(e) => updateByKeyIndex(e, 'UPDATE_FILE', i)} 
                                                                            >
                                                                                <option>-Bitte auswählen-</option>
                                                                                { languages.map((language) => (
                                                                                    <option 
                                                                                        key={ language.key }
                                                                                        value={ language.key }
                                                                                    >{ language.value }</option>
                                                                                )) }
                                                                            </select>
                                                                            <span className="input-group-text">
                                                                                <i className="uil-arrow-right" />
                                                                            </span>
                                                                            <select
                                                                                id={`files_${i}_target_language`}
                                                                                className="form-select"
                                                                                value={ file.target_language }
                                                                                name='target_language'
                                                                                onChange={(e) => updateByKeyIndex(e, 'UPDATE_FILE', i)} 
                                                                            >
                                                                                <option>-Bitte auswählen-</option>
                                                                                { languages.map((language) => (
                                                                                    <option 
                                                                                        key={ language.key }
                                                                                        value={ language.key }
                                                                                    >{ language.value }</option>
                                                                                )) }
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                    {file.translator && (
                                                                        <td>{ file.translator.name }</td>
                                                                    )}
                                                                    <td>
                                                                        <button type="button" className="btn btn-link text-muted btn-sm" onClick={() => taskDispatch({ type: 'REMOVE_FILE', payload: i })}>
                                                                            <i className="uil-times" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            <tr>
                                                                <td colSpan={task.files[0]?.translator ? 7 : 6}>
                                                                    {showFileUpload ? (
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <Dropzone
                                                                                    getUploadParams={getUploadParams}
                                                                                    onChangeStatus={handleChangeStatus}
                                                                                    onSubmit={handleSubmit}
                                                                                    accept="*"
                                                                                    maxFiles={1}
                                                                                    multiple={false}
                                                                                    SubmitButtonComponent={null}
                                                                                    inputWithFilesContent="Datei hochladen"
                                                                                    inputContent="Dateien ziehen oder zum Durchsuchen klicken"
                                                                                    classNames={ {
                                                                                        inputLabel: 'dz-message needsclick font-18',
                                                                                        dropzone: 'dropzone text-center needsclick'
                                                                                    } }
                                                                                />
                                                                            </div>
                                                                            <div className="col-auto">
                                                                                <button type="button" className="btn btn-link text-muted btn-sm" onClick={() => { setShowFileUpload(false) }}>
                                                                                    <i className="uil-times" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-light"
                                                                            onClick={ () => {
                                                                                setShowFileUpload(true)
                                                                            } }
                                                                        >
                                                                            <i className="uil-cloud-upload" />{" "}
                                                                            Datei hochladen
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        
                                                    </table>
                                                </div>
                                            </>
                                        )}
                                        
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button 
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >Auftrag / Anfrage erstellen</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>{" "}
                                {/* end card-body */}
                            </div>{" "}
                            {/* end card*/}
                        </div>{" "}
                        {/* end col*/}
                    </div>
                    {/* end row*/}
                </>
            ) }
        </div>
    )
}

export default CustomerNewTranslationTask
