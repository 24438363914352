// @ts-nocheck
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

import { useAuth } from "contexts/AuthContext"
import { useAppSettings } from "contexts/AppSettingsContext"
import {
    processAppSettingsKeyValueArray,
    processStaticSettingsObject,
    processAppSettingsLanguages,
} from 'utils/settings'

import { getRequest } from "clients/ApiClient"

const SHOW_COLUMNS_KEY = "admin-tasks-show-columns"

const getShowColumnsFromLocalStorage = () => {
    try {
        const json = localStorage.getItem(SHOW_COLUMNS_KEY)
        return JSON.parse(json) || [
            'task_id',
            'customer',
            'entry_date',
            'task_date',
            'task_type',
            'source_language',
            'target_languages',
            'status',
            'translators',
        ]
    } catch (e) {
        return []
    }
}

const setShowColumnsFromLocalStorage = (columns) => {
    try {
        localStorage.setItem(SHOW_COLUMNS_KEY, JSON.stringify(columns))
    } catch (e) {}
}

const fetchTasks = async (setTasks, { sortBy, limit, page, q, taskType, status, lang1, lang2, dateFrom, dateTo }, setIsLoading, authContext) => {
    const { authGetRequest } = authContext
    setIsLoading(true)
    setTasks(undefined)
    const task = await authGetRequest(`/admin/tasks?sortBy=${sortBy}&limit=${limit}&page=${page}&q=${q}&taskType=${taskType}&status=${status}&lang1=${lang1}&lang2=${lang2}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
    setTasks(task.data)
    setIsLoading(false)
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function hasParentClass(child, classname){
    if(child.className.split(' ').indexOf(classname) >= 0) return true;
    try {
        return child.parentNode && hasParentClass(child.parentNode, classname);
    } catch(TypeError){
        return false;
    }
}

const getDefaultSearchCriteria = (query) => {
    return {
        q: query.get('q'),
    }
}

const availableColumns = {
    task_id: 'Auftragsnummer',
    customer: 'Auftraggeber',
    entry_date: 'Eingangsdatum',
    task_date: 'Auftragsdatum',
    task_type: 'Typ',
    title: 'Betreff',
    source_language: 'Ausgangssprachen',
    target_languages: 'Zielsprachen',
    status: 'Status',
    translators: 'Auftragnehmer',
}

const TasksController = (props) => {
    const authContext = useAuth()
    const appSettings = useAppSettings()

    const [tasks, setTasks] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [selectedDeleteDropdown, setSelectedDeleteDropdown] = useState(undefined)
    const [showColumnDropdown, setShowColumnDropdown] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState([])

    const languages = processAppSettingsLanguages(appSettings.appSettings.languages)
    const taskTypes = processAppSettingsKeyValueArray(appSettings.appSettings.task_types)
    const statuses = processStaticSettingsObject(appSettings.staticSettings.statuses)

    const history = useHistory()
    const query = useQuery()

    const [searchCriteria, setSearchCriteria] = useState(getDefaultSearchCriteria(query))

    const sortBy = query.get('sortBy') || 'entry_date:desc'
    const limit = query.get('limit') || 10
    const page = query.get('page') || 1
    const q = query.get('q') || ''
    const taskType = query.get('taskType')  || 'all';
    const status = query.get('status') || 'all';
    const lang1 = query.get('lang1') || 'all';
    const lang2 = query.get('lang2') || 'all';
    const dateFrom = query.get('dateFrom') || '';
    const dateTo = query.get('dateTo') || '';

    const closeShowColumnDropdown = (e) => {
        if(!hasParentClass(e.target, 'show-column-dropdown') && !hasParentClass(e.target, 'show-column-dropdown-trigger')) {
            setShowColumnDropdown(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', closeShowColumnDropdown)
        setSelectedColumns(getShowColumnsFromLocalStorage())

        return () => {
            window.removeEventListener('click', closeShowColumnDropdown)
        }
    }, [])


    useEffect(() => {
        fetchTasks(setTasks, { sortBy, limit, page, q, taskType, status, lang1, lang2, dateFrom, dateTo }, setIsLoading, authContext)
    }, [sortBy, limit, page, q, taskType, status, lang1, lang2, dateFrom, dateTo])

    const handleUpdateSearch = (e) => {
        e.preventDefault()
        const {
            q,
        } = searchCriteria

        history.push(buildLink({
            q,
            taskType,
            lang1,
            lang2,
            dateFrom,
            dateTo,
        }))
    }

    const deleteTask = async (task) => {
        try {
            const res = await authContext.authDeleteRequest(`/admin/tasks/${task.id}`)
            switch(res.status) {
                case 204:
                    toast.success("Der Auftrag wurde erfolgreich gelöscht")
                    setTasks({
                        ...tasks,
                        results: tasks.results.filter(t => t.id !== task.id)
                    })
                    break
                default:
                    throw new Error(res.data)
            }
        }
        catch (e) {
            toast.error(e.message)
        }
    }

    const buildLink = ({ 
        page: newPage, 
        limit: newLimit, 
        sortBy: newSortBy,
        q: newQ,
        taskType: newTaskType,
        status: newStatus,
        lang1: newLang1,
        lang2: newLang2,
        dateFrom: newDateFrom,
        dateTo: newDateTo,
    }) => {
        return `/admin/tasks?page=${newPage || page}&limit=${newLimit || limit}&sortBy=${newSortBy || sortBy}` + 
                `&q=${newQ !== undefined ? newQ : q}&taskType=${newTaskType !== undefined ? newTaskType : taskType}&status=${newStatus !== undefined ? newStatus : status}&lang1=${newLang1 !== undefined ? newLang1 : lang1}&lang2=${newLang2 !== undefined ?  newLang2 : lang2}&dateFrom=${newDateFrom !== undefined ? newDateFrom : dateFrom}&dateTo=${newDateTo !== undefined ?  newDateTo : dateTo}`;
    }

    const updateSelectedColumns = (newSelectedColumns) => {
        setSelectedColumns(newSelectedColumns)
        setShowColumnsFromLocalStorage(newSelectedColumns)
    }

    return {
        tasks,
        setTasks,
        isLoading,
        error,
        selectedDeleteDropdown, 
        setSelectedDeleteDropdown,
        deleteTask,
        buildLink,
        setShowColumnDropdown,
        showColumnDropdown,
        selectedColumns,
        updateSelectedColumns,
        availableColumns,
        languages,
        statuses,
        taskTypes,

        taskType,
        status,
        lang1,
        lang2,
        dateFrom,
        dateTo,
        searchCriteria,
        setSearchCriteria,
        handleUpdateSearch,
    }
}

export default TasksController