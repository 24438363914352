// @ts-nocheck
import ProfileController from 'controllers/components/Messenger/Profile.controller'

const Profile = (props) => {
    const { user } = props

    const {
        callUser,
        isLoading,
    } = ProfileController(props)

    return (
        <div className="card">
            <div className="card-body">
                <div className="mt-3 text-center">
                    <img src="/assets/images/users/avatar-0.png" alt="shreyu" className="img-thumbnail avatar-lg rounded-circle" />
                    <h4>{ user.is_company ? user.company_name : `${user.first_name} ${user.last_name}` }</h4>
                    <button
                        className={ `btn btn-info btn-sm mt-1 ${isLoading ? 'disabled' : ''}` }
                        onClick={ callUser }
                        disabled={ isLoading }
                    >Anrufen</button>
                    <p className="text-muted mt-2 font-14">Zuletzt online: <strong>Gerade eben</strong></p>
                </div>

                {/* <div className="mt-3">
                    <hr className="" />

                    <p className="mt-4 mb-1"><strong><i className="uil uil-at"></i> Email:</strong></p>
                    <p>martin.altavista@gmail.com</p>

                    <p className="mt-3 mb-1"><strong><i className="uil uil-phone"></i> Telefonnummer:</strong></p>
                    <p>0178 555 5555</p>

                    <p className="mt-3 mb-1"><strong><i className="uil uil-location"></i> Standort:</strong></p>
                    <p>Hamburg, Deutschland</p>

                    <p className="mt-3 mb-1"><strong><i className="uil uil-globe"></i> Sprachen:</strong></p>
                    <p>Deutsch, Englisch, Spanisch</p>

                    <p className="mt-3 mb-2"><strong><i className="uil uil-users-alt"></i> Typ:</strong></p>
                    <p>
                        <span className="badge badge-success-lighten p-1 font-14">Dollmetscher</span>
                        <span className="badge badge-primary-lighten p-1 font-14">Übersetzer</span>
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default Profile